import React from 'react';
import { IonModal, IonButton } from '@ionic/react';
import AddNewUser from '../../pages/AddNewUser/AddNewUser';
import './AddMemberToStand.css';

export default function ModalExample({showModal, setShowModal}) {

  return (
    <IonModal isOpen={showModal} cssClass='add-member-to-stand'>
        <AddNewUser setShowModal={setShowModal} />
        <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
    </IonModal>
  );
};