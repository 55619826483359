    import React, { useState, useEffect } from "react";
    import {
    IonModal,
    IonList,
    IonItem,
    IonIcon,
    } from "@ionic/react";
    import Fuse from "fuse.js";
    import { close } from "ionicons/icons";
    import Search from "../Search/Search";

    const SearchUsersModal = ({
    users,
    showModal,
    setShowModal,
    setUser,
    title
    }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        const usersFuse = new Fuse(users, {
            keys: ["firstname", "lastname"],
        });
        let results = usersFuse.search(searchQuery);

        setSearchResults(results);
    }, [searchQuery, users]);

    function handleUserClick(value) {
        setUser(value);
        setShowModal(false);
    }

    return (
        <IonModal isOpen={showModal} cssClass="my-custom-class">
        <div
            style={{
            width: "100%",
            position: "relative",
            height: "20px",
            paddingBottom: "2em",
            paddingTop: "1em",
            }}
        >
            <IonIcon
            onClick={() => setShowModal(false)}
            size="large"
            style={{ position: "absolute", right: "0.5em" }}
            icon={close}
            ></IonIcon>
        </div>
        <div style={{ position: "relative", height: "100%" }}>
            <div style={{ position: "absolute", top: "0", width: "100%" }}>
            <h1
                style={{ margin: "0 auto" }}
                className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold"
            >
                {!title ? "Search Users" : title}
            </h1>
            <Search
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                style={{ marginBottom: 0 }}
            />
            <div style={{ textAlign: "center" }}>
                <IonList style={{ margin: "auto" }}>
                {searchResults.map((result, i) => (
                    <IonItem
                    onClick={() => handleUserClick(result.item)}
                    style={{ cursor: "pointer" }}
                    key={i}
                    >
                        {result.item.firstname} {result.item.lastname}
                    </IonItem>
                ))}
                </IonList>
            </div>
            </div>
        </div>
        </IonModal>
    );
    };

    export default SearchUsersModal;
