import React, { createContext, useContext, useState } from 'react';
import { inovioApi } from '../apis/inovio';
import { uid as getUid } from 'uid';
import jwtDecode from 'jwt-decode';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({children}) {
    const [currentUser, setCurrentUser] = useState({})
    const [loading, setLoading] = useState(true)

    function signup(email, password) {

    }

    async function login(email, password) {
        const res = await inovioApi.post('/auth/login', {
            email, 
            password,
            deviceInfo: navigator.userAgent
        });

        console.log(res.data);
        setCurrentUser({
            email
        });
        localStorage.clear();
        //returns token
        const accessToken = res.data.accessToken
        const refreshToken = res.data.refreshToken
        const decodedToken = jwtDecode(accessToken)
        console.log(decodedToken);
        localStorage.setItem('inovio-access-token', accessToken)
        localStorage.setItem('inovio-refresh-token', refreshToken)
        localStorage.setItem('inovio-current-user', JSON.stringify({ email, id: decodedToken.id }))
        return res;
    }

    async function logout() {
        setCurrentUser(null)
        await inovioApi.delete('/auth/logout', {
            token: localStorage.getItem('inovio-refresh-token')
        });
        localStorage.removeItem('inovio-access-token')
        localStorage.removeItem('inovio-refresh-token')
        localStorage.removeItem('inovio-current-user')
        localStorage.removeItem('inovio-units')
        localStorage.removeItem('inovio-users')
        return true
    }

    function resetPassword(email) {

    }

    function updateEmail(email) {

    }

    function updatePassword(password) {

    }

    const value = {
        currentUser,
        signup,
        login,
        logout,
        resetPassword,
        updateEmail,
        updatePassword
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}
