import React, { useState, useEffect, useRef } from 'react';
import DayPicker from 'react-day-picker';

import {IonGrid, IonRow, IonCol, IonSelect, IonSelectOption, IonDatetime, IonInput, IonItem, IonLabel, IonButton, IonIcon, IonPopover, IonChip} from '@ionic/react';
import { addCircleOutline, checkmarkOutline, closeOutline } from 'ionicons/icons'; 
import {format} from 'date-fns';

import {addBooking, getBookingConfig, getBookings, getBooking, updateBooking} from '../../../utils';

import Modal from '../../../components/Modal';

import BaseTemplate from '../../../components/base/BaseTemplate';

import './Bookings.css';
import 'react-day-picker/lib/style.css';

export default function Bookings() {
    const [facilities, setFacilities] = useState([]);
    const [facility, setFacility] = useState(-1);
    const [bookingDateTime, setBookingDateTime] = useState('');
    const [duration, setDuration] = useState('');
    const [numberOfPeople, setNumberOfPeople] = useState('');
    const [bookings, setBookings] = useState(null);
    const [allBookings, setAllBookings] = useState(null);
    const [showNew, setShowNew] = useState(false);
    const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDays, setSelectedDays] = useState(null);
    const clickedDay = useRef(null);
    const [showModal, setShowModal] = useState(false);
    let aYearFromNow = new Date(2022, 0, 1);
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);

    useEffect(() => {
        fetchBookings();

        getBookingConfig().then(res => {
            if(res && Array.isArray(res)) {
                const formattedFacilities = res.map(f => {
                    let temp = f;
                    let config = JSON.parse(f.config);

                    delete temp.config;

                    return {...f, ...config};
                });

                setFacilities(formattedFacilities);
            }
        });

        const selectedDaysEls = document.querySelectorAll('.DayPicker-Day--selected');

        const daysEls = document.querySelectorAll('.DayPicker-Day:not(.DayPicker-Day--selected)');

        console.log(daysEls);

        if(selectedDaysEls) {
            selectedDaysEls.forEach((n) => {
                n.addEventListener('click', _ => {
                    getBooking(0).then(res => console.log(res));
                });
            });
        }

        if(daysEls) {
            daysEls.forEach((n, i) => {
                n.addEventListener('click', (e) => {
                    clickedDay.current = e.target;
                    const day  = n.innerText;
                    daysEls.forEach(el => el.style.border = 'none');
                    n.style.border = '1px solid #7fba50';
                    setShowModal(true);
                    setBookingDateTime(new Date(2021, 10, day).toISOString());
                });
            });
        }
    }, []);

    function fetchBookings() {
        getBookings(new Date(2021, 0, 1), aYearFromNow).then(res => {
            if(res && Array.isArray(res)) {
                const sd = res
                .map(ub => new Date(ub.date));

                console.log('--------------->SD', sd);

                setBookings(res);
                setAllBookings(res);
                setSelectedDays(sd);
            }
        });
    }

    async function onAddBookingClick() {
        const estateId = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
        const user = JSON.parse(localStorage.getItem('inovio-current-user'));

        const body = {
            estateId,
            bookingVenueId: facility.id,
            date: bookingDateTime,
            duration: Number(duration),
            contactName: 'Chad',
            contactNumber: '123456789',
            contactEmail: user.email,
            bookedOut: false,
            groupSize: Number(numberOfPeople)
        };

        if(showModal && clickedDay && clickedDay.current) clickedDay.current.classList.add('DayPicker-Day--selected');

        setSelectedDays([...selectedDays, selectedDate]);
        await addBooking(body);
        setShowNew(false);
        setShowModal(false);
    }

    function filter(facility) {
        if(facility === -1) {
            setBookings(allBookings);
            return;
        }
        const {id} = facility;
        const filtered = allBookings.filter(b => b.booking_id === id);

        setBookings(filtered);
    }

    function renderAddNewForm() {
        return (
        <>
            <IonRow>
                <IonCol offset="1" size={showModal ? "10" : "5"}>
                    <IonItem className="shadow-xl">
                        <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                            Facility
                        </IonLabel>
                        <IonSelect value={facility} onIonChange={e => {setFacility(e.target.value);}}>
                            {facilities.map(f => (
                                <IonSelectOption key={f.id} value={f}>
                                    {f.name}
                                </IonSelectOption>
                            ))}
                        </IonSelect>
                    </IonItem>
                </IonCol>
                <IonCol offset={showModal ? "1" : "0"} size={showModal ? "10" : "5"}>
                    <IonItem className="shadow-xl">
                        <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">Date & Time of booking</IonLabel>
                        <IonDatetime displayFormat="D MMM YYYY HH:mm" value={bookingDateTime} onIonChange={e => setBookingDateTime(e.detail.value)}></IonDatetime>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol offset="1" size={showModal ? "10" : "5"}>
                    <IonItem className="shadow-xl">
                        <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                            Number of People
                        </IonLabel>
                        <IonInput placeholder="eg. 1" type="number" max={facility ? facility.limit : 10} value={numberOfPeople} onIonChange={e => setNumberOfPeople(e.detail.value)} />
                    </IonItem>
                </IonCol>
                <IonCol offset={showModal ? "1" : "0"} size={showModal ? "10" : "5"}>
                    <IonItem className="shadow-xl">
                        <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                            Duration (minutes)
                        </IonLabel>
                    <IonInput placeholder="eg. 60" type="number" value={duration} onIonChange={e => setDuration(e.detail.value)} />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow style={{marginTop: '2em'}}>
                <IonCol offset="1" size="10">
                    <IonButton expand="full" onClick={onAddBookingClick}><IonIcon icon={addCircleOutline} slot="end" />Add booking</IonButton>
                </IonCol>
            </IonRow>
            <div style={{height: 20}} />
        </>);
    }

    function rejectBooking(booking) {
        const estateId = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
        const user = JSON.parse(localStorage.getItem('inovio-current-user'));

        const body = {
            estateId,
            id: booking.id,
            bookingStatus: 2
        };

        updateBooking(body).then(() => fetchBookings());
    }

    function approveBooking(booking) {
        const estateId = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
        const user = JSON.parse(localStorage.getItem('inovio-current-user'));

        const body = {
            estateId,
            id: booking.id,
            bookingStatus: 1
        };

        updateBooking(body).then(() => fetchBookings());
        
    }


    return (
        <BaseTemplate>
            <IonGrid>
                <IonRow>
                    <IonCol style={{textAlign: 'right'}} size="10" offset="1">
                    <h1
                        className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold"
                    >
                        Manage Bookings
                    </h1>
                    </IonCol>
                </IonRow>
                {!showNew && 
                    <>  
                        <IonRow>
                            <IonCol size="8" offset="2">
                                <IonItem className="shadow-xl">
                                    <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                                        Facility
                                    </IonLabel>
                                    <IonSelect value={facility} onIonChange={e => {setFacility(e.target.value); filter(e.target.value);}}>
                                        <IonSelectOption value={-1}>
                                                All
                                        </IonSelectOption>
                                        {facilities.map(f => (
                                            <IonSelectOption key={f.id} value={f}>
                                                {f.name}
                                            </IonSelectOption>
                                        ))}
                                    </IonSelect>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol style={{textAlign: 'right'}} size="8" offset="2">
                                <IonButton expand="full" onClick={() => setShowNew(true)}><IonIcon icon={addCircleOutline} slot="end" />New booking</IonButton>
                            </IonCol>
                        </IonRow>
                    </>}
                
                {showNew && renderAddNewForm()}
                {/* <IonRow style={{marginTop: '1em'}}>
                    <IonCol size="10" offset="1">
                        {selectedDays && <DayPicker 
                            className="shadow-xl"
                            canChangeMonth={true} 
                            initialMonth={new Date(2021, 10)}
                            selectedDays={selectedDays}
                            // onDayClick={(day) => { setSelectedStartDate(day.toString()); setShowStartDate({ showPopover: false, event: undefined }); }} 
                        />}
                    </IonCol>
                </IonRow> */}
                <IonRow>
                    <IonCol style={{textAlign: 'right', margin: '2em 0'}} size="10" offset="1">
                        <h1
                            className="text-gray-600 align-middle text-l uppercase whitespace-no-wrap font-semibold"
                        >
                            {facility === -1 ? `All Bookings for ${JSON.parse(localStorage.getItem('inovio-current-estate')).estates_name}` :
                            `Bookings for ${JSON.parse(localStorage.getItem('inovio-current-estate')).estates_name} - ${facility.name}`}
                        </h1>
                    </IonCol>
                </IonRow>
                <IonRow>
                    {facilities && facilities.length > 0 && bookings && Array.isArray(bookings) && bookings.map((b, i) => (
                        <IonCol size="8" offset="2" key={i}>
                            <IonItem className="shadow-xl">
                                <IonGrid>
                                    <IonRow>
                                        <IonCol className="flex-align-center">
                                            <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                                                {facilities[b.booking_id - 1].name}
                                            </IonLabel>
                                        </IonCol>
                                        <IonCol className="flex-align-center">
                                            {b.contact_email}
                                        </IonCol>
                                        <IonCol className="flex-align-center">
                                            {format(b.date, 'd/M/YYYY HH:mm')}
                                        </IonCol>
                                        <IonCol className="flex-align-center">
                                            {b.duration} minutes
                                        </IonCol>
                                        <IonCol size="1.5">
                                            <IonChip color={b.booking_status === 1 ? "success" : b.booking_status === 0 ? "warning" : "danger"}>
                                                {b.booking_status === 1 ? "approved" : b.booking_status === 0 ? "pending" : "rejected"}
                                            </IonChip>
                                        </IonCol>
                                        <IonCol className="flex-align-center" size="0.75">
                                            {b.booking_status === 0 && <IonButton onClick={() => approveBooking(b)}>
                                                <IonIcon icon={checkmarkOutline} />
                                            </IonButton>}
                                        </IonCol>
                                        <IonCol className="flex-align-center" size="0.75" offset="0.1">
                                            {b.booking_status === 0 && <IonButton color="danger" onClick={()=> rejectBooking(b)}>
                                                <IonIcon icon={closeOutline} />
                                            </IonButton>}
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonItem>
                        </IonCol>
                    ))}
                </IonRow>
            </IonGrid>
            <Modal showModal={showModal} className="not-fullscreen" setShowModal={setShowModal}>
                <div className="add-new-booking-form">
                    <div style={{width: '100%'}}>
                        <h1 style={{marginBottom: '1em'}} className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">New booking</h1>
                        {renderAddNewForm()}
                    </div>
                </div>
            </Modal>
            <IonPopover
                cssClass='my-custom-class'
                event={popoverState.event}
                isOpen={popoverState.showPopover}
                onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
            >
                <p>{selectedDate}</p>
            </IonPopover>
        </BaseTemplate>
    )
}
