import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { checkIfTokenExpired, getInfo } from '../utils/';

export default function PrivateRoute({ component: Component, ...rest }) {

    useEffect(() => {
        if(localStorage.getItem('inovio-access-token')) {
            checkIfTokenExpired(localStorage.getItem('inovio-access-token'))
        } else {
            window.location.href = '/login';
        }
        if(!localStorage.getItem('inovio-estate-info')) {
            getInfo().then((res) => {
                console.log(res);
            });
        }
    }, [])
    
    return (
        <Route
            {...rest}
            render={props => {
                return localStorage.getItem('inovio-current-user') ? <Component {...props} /> : <Redirect to="/login" />
            }}
        >  
        </Route>
    )
}
