import React from 'react';

import './Documents.css';

import { IonGrid, IonCol, IonRow } from '@ionic/react';

function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = Number(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

const Documents = ({documents}) => {
    return (
        <IonGrid className="documents" style={{marginTop: '1em'}}>
            {documents.map(({file, name, size, extension}, i) => (
                <IonRow key={i} className={"document-item"}>
                    <IonCol size-md="1">
                        <div className="flex-wrapper">
                            <img src="/assets/icons/document.svg" />
                        </div>
                    </IonCol>
                    <IonCol>
                        <div className="flex-wrapper-left text-left">
                            <div className="text-container">
                                <p className="bold document-name">{name}</p>
                                <p className="document-info">Size: {bytesToSize(size)}</p>
                            </div>
                        </div>
                    </IonCol>
                    <IonCol size-md="1" className="flex-align-center">
                        <div className="flex-wrapper h-auto">
                            <a style={{color: 'var(--ion-color-primary)', cursor: 'pointer'}} target="_blank" href={`https://api.inovio.co.za${file}`} download={`${name}.${extension}`}>View</a>
                        </div>
                    </IonCol>
                </IonRow>
            ))}
        </IonGrid>
    );
}

export default Documents;