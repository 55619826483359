import React, { useState } from 'react'
import { IonItem, IonSpinner, IonList } from '@ionic/react'
import MorphoSelectFingers from './MorphoSelectFingers'

export default function MorphoEnrollmentForm({user}) {
    const [selectedReader, setSelectedReader] = useState();
    const [isEnrolling, setIsEnrolling] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [error, setError] = useState('');

    function handleReaderSubmit(e) {
        e.preventDefault()
    }

    // {
    //     "sessionId": "f3253f2cd160aa14993a27a06e6c6156",
    //     "readers": [
    //         {
    //             "id": 3,
    //             "mac": "00:24:ae:06:c8:be",
    //             "name": "Office",
    //             "estate_id": 6,
    //             "pi_id": 5
    //         }
    //     ]
    // }

    return (
        <div
            className="px-10 pt-0 pb-2"
            style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div style={{ width: '60%', margin: '4em auto' }} className="shadow-xl">
                {!isEnrolling && <h1
                    style={{ textAlign: 'center', margin: '0 auto' }}
                    className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold"
                >
                    {(!selectedReader && !isEnrolling) ? 'Select Reader' : 'Select fingers to enroll'}
                </h1>}
                <form
                    onSubmit={handleReaderSubmit}
                    style={{ width: '100%', textAlign: 'center' }}
                >
                    {!selectedReader && !isEnrolling && !isComplete && !error && (
                        <IonList style={{ margin: '1em auto 2em auto' }}>
                            {JSON.parse(
                                localStorage.getItem('inovio-morpho-session'),
                            ).readers.map((reader) => (
                                <IonItem
                                    key={reader.id}
                                    onClick={() => setSelectedReader(reader)}
                                    style={{ cursor: 'pointer' }}
                                    button={true}
                                >
                                    {reader.name}
                                </IonItem>
                            ))}
                        </IonList>
                    )}
                    {selectedReader && !isEnrolling && !error && !isComplete &&  (
                        <MorphoSelectFingers 
                            reader={selectedReader} 
                            user={user} 
                            setIsEnrolling={setIsEnrolling}
                            setIsComplete={setIsComplete}
                            setError={setError}
                        />
                    )}
                    {isEnrolling && !isComplete && !error && <div style={{height: '50vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <IonSpinner />
                        <p style={{marginLeft: '1em'}}>Enrolling in progress...</p>
                    </div>}
                    {isComplete && !error && <div style={{height: '50vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <h1 className="text-gray-600 align-middle text-xl whitespace-no-wrap font-semibold" style={{marginLeft: '1em', color: 'var(--ion-color-success)'}}>Enrollment for {user.firstname} {user.lastname} complete</h1>
                    </div>}
                    {error && <div style={{height: '50vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <h1 className="text-gray-600 align-middle text-xl whitespace-no-wrap font-semibold" style={{marginLeft: '1em', color: 'var(--ion-color-danger)'}}>Enrollment for {user.firstname} {user.lastname} failed: ({error})</h1>
                    </div>}
                </form>
            </div>
        </div>
    )
}
