import React, { useEffect } from "react";
import { downloadOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import Chart from 'chart.js';

export default function PieChart({ data, colors }) {

  useEffect(() => {
    const ut = JSON.parse(localStorage.getItem('inovio-estate-info')).usertypes;
    ut.unshift({
      id: 0,
      name: "Visitor"
    });
    const labels = ut.filter((type) => data.totals[type.id]).map(type => type.name);
    const usedUserTypes = ut.filter((type) => data.totals[type.id]).map(type => type);
    const userTypes = ut;
    
    const sortedColors = [];

    for(let i = 0; i < userTypes.length; i++) {
      for(let j = 0; j < usedUserTypes.length; j++) {
        if(userTypes[i].id === usedUserTypes[j].id) {
          sortedColors.push(colors[i - 1]);
        }
      }
    };

    if(data.totals[1]) {
      sortedColors[0] = colors[7];
    }
    
    var config = {
      type: "pie",
      data: {
        datasets: [
          {
            data: Object.values(data.totals),
            backgroundColor: sortedColors,
            label: "Member Types Access",
          },
        ],
        labels,
      },
      options: {
        responsive: true,
        legend: {
          display: window.innerWidth < 600 ? false : true
        },
      },
    };

    var ctx = document.getElementById("pie-chart").getContext("2d");

    var myPieChart = new Chart(ctx, config);

    //Download Chart Image
    document
      .getElementById("download-pie-chart")
      .addEventListener("click", function () {
        /*Get image of canvas element*/
        var url_base64jp = document
          .getElementById("pie-chart")
          .toDataURL("image/jpg");
        /*get download button (tag: <a></a>) */
        var a = document.getElementById("download-pie-chart");
        /*insert chart image url to download button (tag: <a></a>) */
        a.href = url_base64jp;
      });

      // if(window.innerWidth < 600) {
      //   myPieChart.canvas.parentNode.style.height = '300px';
      //   myPieChart.canvas.parentNode.style.width = '300px';
      // }
  }, []);

  return (
    <div>
      <div
        className="px-4 md:px-10 mx-auto w-full -m-24"
        style={{
          marginTop: "1em",
          width: window.innerWidth > 990 ? "147%" : "100%",
        }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
            <div className="flex flex-col min-w-0 break-words w-full mb-6 shadow-xl rounded">
              <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h6 className="uppercase text-gray-600 mb-1 text-xs font-semibold">
                      {new Date().toLocaleDateString()} {new Date().toLocaleTimeString('en-gb')}
                    </h6>
                    <a
                      id="download-pie-chart"
                      download={`member-types-currently-inside-${new Date().toLocaleDateString()}${new Date().toLocaleTimeString()}-chart.jpg`}
                      className="float-right"
                      href=""
                    >
                      <IonIcon
                        icon={downloadOutline}
                        style={{ cursor: "pointer", margin: "0 0.5em", fontSize: 24 }}
                        className="text-gray-600"
                      ></IonIcon>
                    </a>
                    <h2 className=" text-xl font-semibold">
                      Member Types Currently Inside
                    </h2>
                  </div>
                </div>
              </div>
              <div
                className="relative"
                style={{ height: "auto", paddingBottom: "1em" }}
              >
                <canvas id="pie-chart"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
