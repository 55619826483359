import React from 'react';
import BaseTemplate from "../../components/base/BaseTemplate";

const sections = [
    {
        name: "Test User",
        status: "departed",
        code: "7890",
        issued: new Date().toLocaleString()
    },
    {
        name: "Best User",
        status: "arrived",
        code: "1234",
        issued: new Date().toLocaleString()
    },
    {
        name: "Vest User",
        status: "unused",
        code: "5678",
        issued: new Date().toLocaleString()
    },
    {
        name: "Chest User",
        status: "expired",
        code: "9012",
        issued: new Date().toLocaleString()
    },
];


export default function MyCodes() {

    function getColor(status) {
        switch(status) {
            case "departed": return "green";
            case "arrived": return "yellow";
            case "unused": return "blue";
            case "expired": return "red";
        }
    }

    return (
        <BaseTemplate>
            <div className="flex flex-wrap" style={{ marginTop: "3em" }}>
                {sections &&
                sections.map((codeObj, i) => (
                    <div className="w-full lg:w-6/12 xl:w-3/12 px-4" key={i}>
                    <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                        <div
                        className="flex-auto p-4"
                        style={{
                            borderLeft: `4px solid ${getColor(codeObj.status)}`,
                            cursor: "pointer",
                        }}
                        >
                        <div className="flex flex-wrap">
                            <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                                <h5 className="text-gray-500 uppercase font-bold text-xs pb-2">
                                    issued {codeObj.issued}
                                </h5>
                                <h5 className="text-gray-500 uppercase font-bold text-xs pb-2">
                                    valid until {codeObj.issued}
                                </h5>
                                <span className="font-semibold text-xl text-gray-800">
                                    {codeObj.code}
                                </span>
                                <br />
                                <span className="font-semibold">
                                    {codeObj.name}
                                </span>
                                <br />
                                <span className="font-semibold" style={{color: `${getColor(codeObj.status)}`}}>
                                    {codeObj.status}
                                </span>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                ))}
            </div>
        </BaseTemplate>
    )
}
