import React, { useState, useEffect, useRef } from 'react';
import DayPicker from 'react-day-picker';

import {IonGrid, IonRow, IonCol, IonSelect, IonSelectOption, IonDatetime, IonInput, IonItem, IonLabel, IonButton, IonIcon, IonPopover, IonChip, IonList} from '@ionic/react';
import { addCircleOutline } from 'ionicons/icons'; 
import {format} from 'date-fns';

import {addBooking, updateBooking, getBookingConfig, getBookings, checkBookingAvailability, parseQueryString} from '../../../utils';

import Modal from '../../../components/Modal';

import BaseTemplate from '../../../components/base/BaseTemplate';

import './Bookings.css';
import 'react-day-picker/lib/style.css';

export default function Bookings() {
    const [facilities, setFacilities] = useState([]);
    const [facility, setFacility] = useState('');
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [selectedBookingArr, setSelectedBookingArr] = useState(null);

    const [bookings, setBookings] = useState(null);
    const [bookingDateTime, setBookingDateTime] = useState('');
    const [duration, setDuration] = useState('');
    const [numberOfPeople, setNumberOfPeople] = useState('');
    const [showNew, setShowNew] = useState(false);
    const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDays, setSelectedDays] = useState(null);
    const clickedDay = useRef(null);
    const [showModal, setShowModal] = useState(false);
    let aYearFromNow = new Date(2021, 0, 1);
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);

    const [fromFacilities, setFromFacilities] = useState(false);

    useEffect(() => {
        const queries = parseQueryString();

        if(queries.facility) {
            setFromFacilities(true);
            setShowNew(true);
        }

        fetchBookings();

        getBookingConfig().then(res => {
            if(res && Array.isArray(res)) {
                const formattedFacilities = res.map(f => {
                    let temp = f;
                    let config = JSON.parse(f.config);

                    delete temp.config;

                    return {...f, ...config};
                });

                setFacilities(formattedFacilities);


                if(queries.facility) {
                    const selectedFacility = formattedFacilities.find(f => f.name === queries.facility);

                    setFacility(selectedFacility);
                }
            }
        });
    }, []);

    useEffect(() => {
        if(selectedDays) {
            setTimeout(() => {
                initEventListeners();
            }, 1000);
        }
    }, [selectedDays]);

    function fetchBookings() {
        const user = JSON.parse(localStorage.getItem('inovio-current-user'));
        getBookings(new Date(2021, 0, 1), aYearFromNow).then(res => {
            if(res && Array.isArray(res)) {
                const usersBookings = filterBookings(res, user.email);

                const sd = usersBookings
                .map(ub => new Date(ub.date));

                console.log('--------------->SD', sd);

                setBookings(usersBookings);
                console.log('--------------->BOOKINGS', usersBookings);
                setSelectedDays(sd);
            }
        });
    }

    async function onAddBookingClick() {
        const estateId = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
        const user = JSON.parse(localStorage.getItem('inovio-current-user'));

        let toDatePreAdd = new Date(bookingDateTime);

        const toDate = new Date(toDatePreAdd.getTime() + Number(duration)*60000);

        const isAvailable = await checkBookingAvailability(bookingDateTime, toDate, Number(numberOfPeople), facility.id);

        if(!isAvailable) return;

        const body = {
            estateId,
            bookingVenueId: facility.id,
            date: bookingDateTime,
            duration: Number(duration),
            contactName: 'Chad',
            contactNumber: '123456789',
            contactEmail: user.email,
            bookedOut: false,
            groupSize: Number(numberOfPeople)
        };

        if(showModal && clickedDay && clickedDay.current) clickedDay.current.classList.add('DayPicker-Day--selected');

        setSelectedDays([...selectedDays, selectedDate]);
        await addBooking(body);
        setShowNew(false);
        setShowModal(false);
        
        window.location.reload();
        // fetchBookings();
        // initEventListeners();
    }

    function onUpdateBookingClick() {
        const estateId = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
        const user = JSON.parse(localStorage.getItem('inovio-current-user'));

        const body = {
            estateId,
            bookingVenueId: facility.id,
            date: bookingDateTime,
            duration: Number(duration),
            contactName: 'Chad',
            contactNumber: '123456789',
            contactEmail: user.email,
            bookedOut: false,
            groupSize: Number(numberOfPeople),
            id: selectedBooking.id
        };

        updateBooking(body);
        setShowNew(false);
        setShowModal(false);
        fetchBookings();
        initEventListeners();
    }

    function filterBookings(bookings, email) {
        return bookings.filter(b => b.contact_email === email);
    }

    function setFields(sb) {

        if(sb && facilities) {
            const selectedFacility = facilities.find(f => f.id === sb.booking_id);
            setFacility(selectedFacility);
            setBookingDateTime(new Date(sb.date).toISOString());
            setNumberOfPeople(sb.group_size);
            setDuration(sb.duration); 
            return;
        }
        if(selectedBooking && facilities) {
            const selectedFacility = facilities.find(f => f.id === selectedBooking.booking_id);
            setFacility(selectedFacility);
            setBookingDateTime(new Date(selectedBooking.date).toISOString());
            setNumberOfPeople(selectedBooking.group_size);
            setDuration(selectedBooking.duration);
        }
    }

    function clearFields() {
        if(!fromFacilities) setFacility('');
        setBookingDateTime('');
        setNumberOfPeople('');
        setDuration('');
    }

    function initEventListeners() {
        const selectedDaysEls = document.querySelectorAll('.DayPicker-Day--selected');

        const daysEls = document.querySelectorAll('.DayPicker-Day:not(.DayPicker-Day--selected)');

        console.log(daysEls);

        if(selectedDaysEls) {
            selectedDaysEls.forEach((n, i) => {
                n.removeEventListener('click', (e) => {
                    const day  = n.innerText;
                    const elDate = new Date(2021, 10, day);

                    const bookingsOnDate = bookings.filter(b => {
                        return format(new Date(b.date), 'd/M/YYYY') === format(new Date(elDate), 'd/M/YYYY');
                    });

                    console.log(bookingsOnDate);

                    if(bookingsOnDate.length === 1) {
                        const b = bookings.find(b => {
                            return new Date(b.date).toISOString() === new Date(elDate).toISOString();
                        });

                        setShowPopover({ showPopover: true, event: e });

                        setSelectedDate(selectedDays[i].toISOString());

                        if(b) setSelectedBooking(b);
                    } else if(bookingsOnDate.length > 1) {
                        setShowPopover({ showPopover: true, event: e });

                        setSelectedBookingArr(bookingsOnDate);
                    }
                });
                n.addEventListener('click', (e) => {
                    const day  = n.innerText;
                    const elDate = new Date(2021, 10, day);

                    const bookingsOnDate = bookings.filter(b => {
                        return format(new Date(b.date), 'd/M/YYYY') === format(new Date(elDate), 'd/M/YYYY');
                    });

                    console.log(bookingsOnDate);

                    if(bookingsOnDate.length === 1) {
                        const b = bookings.find(b => {
                            return new Date(b.date).toISOString() === new Date(elDate).toISOString();
                        });

                        setShowPopover({ showPopover: true, event: e });

                        setSelectedDate(selectedDays[i].toISOString());

                        if(b) setSelectedBooking(b);
                    } else if(bookingsOnDate.length > 1) {
                        setShowPopover({ showPopover: true, event: e });

                        setSelectedBookingArr(bookingsOnDate);
                    }
                });
            });
        }

        if(daysEls) {
            daysEls.forEach((n, i) => {
                n.removeEventListener('click', (e) => {
                    clickedDay.current = e.target;
                    const day  = n.innerText;
                    daysEls.forEach(el => el.style.border = 'none');
                    n.style.border = '1px solid #7fba50';
                    setShowModal(true);
                    setBookingDateTime(new Date(2021, 10, day).toISOString());
                });
                n.addEventListener('click', (e) => {
                    clickedDay.current = e.target;
                    const day  = n.innerText;
                    daysEls.forEach(el => el.style.border = 'none');
                    n.style.border = '1px solid #7fba50';
                    setShowModal(true);
                    setBookingDateTime(new Date(2021, 10, day).toISOString());
                });
            });
        }
    }

    function renderAddNewForm() {
        return (
        <>
            <IonRow>
                <IonCol offset="1" size={showModal ? "10" : "5"}>
                    <IonItem className="shadow-xl">
                        <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                            Facility
                        </IonLabel>
                        <IonSelect value={facility} onIonChange={e => setFacility(e.target.value)}>
                            {facilities.map(f => (
                                <IonSelectOption key={f.id} value={f}>
                                    {f.name}
                                </IonSelectOption>
                            ))}
                        </IonSelect>
                    </IonItem>
                </IonCol>
                <IonCol offset={showModal ? "1" : "0"} size={showModal ? "10" : "5"}>
                    <IonItem className="shadow-xl">
                        <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">Date & Time of booking</IonLabel>
                        <IonDatetime displayFormat="D MMM YYYY HH:mm" value={bookingDateTime} onIonChange={e => setBookingDateTime(e.detail.value)}></IonDatetime>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol offset="1" size={showModal ? "10" : "5"}>
                    <IonItem className="shadow-xl">
                        <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                            Number of People
                        </IonLabel>
                        <IonInput placeholder="eg. 1" type="number" max={facility ? facility.limit : 10} value={numberOfPeople} onIonChange={e => setNumberOfPeople(e.detail.value)} />
                    </IonItem>
                </IonCol>
                <IonCol offset={showModal ? "1" : "0"} size={showModal ? "10" : "5"}>
                    <IonItem className="shadow-xl">
                        <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                            Duration (minutes)
                        </IonLabel>
                    <IonInput placeholder="eg. 60" type="number" value={duration} onIonChange={e => setDuration(e.detail.value)} />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow style={{marginTop: '2em'}}>
                <IonCol offset="1" size="10">
                    {!selectedBooking && <IonButton expand="full" onClick={onAddBookingClick}><IonIcon icon={addCircleOutline} slot="end" />Add booking</IonButton>}
                    {selectedBooking && <IonButton expand="full" onClick={onUpdateBookingClick}>
                        Update booking
                    </IonButton>}
                </IonCol>
            </IonRow>
            <div style={{height: 20}} />
        </>);
    }

    return (
        <BaseTemplate>
            <IonGrid>
                <IonRow>
                    <IonCol style={{textAlign: 'right'}} size="10" offset="1">
                    <h1
                        className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold"
                    >
                        {(fromFacilities && facility) ? `new booking for ${facility.name}` : "Bookings"}
                    </h1>
                    </IonCol>
                </IonRow>
                {!showNew && 
                    <>
                        <IonRow>
                            <IonCol style={{textAlign: 'right'}} size="8" offset="2">
                                <IonButton expand="full" onClick={() => setShowNew(true)}><IonIcon icon={addCircleOutline} slot="end" />New booking</IonButton>
                            </IonCol>
                        </IonRow>
                    </>}
                
                {showNew && renderAddNewForm()}
                <IonRow style={{marginTop: '1em'}}>
                    <IonCol size="10" offset="1">
                        {selectedDays && <DayPicker 
                            className="shadow-xl"
                            canChangeMonth={true} 
                            initialMonth={new Date(2021, 10)}
                            selectedDays={selectedDays}
                        />}

                    </IonCol>
                </IonRow>
            </IonGrid>
            <Modal showModal={showModal} className="not-fullscreen" setShowModal={setShowModal} onDidDismiss={() => {setShowModal(false); setSelectedBooking(null); clearFields();}}>
                <div className="add-new-booking-form">
                    <div style={{width: '100%'}}>
                        <h1 style={{marginBottom: '1em'}} className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">{selectedBooking ? 'Edit' : 'New'} booking</h1>
                        {renderAddNewForm()}
                    </div>
                </div>
            </Modal>
            <IonPopover
                cssClass='my-custom-class'
                event={popoverState.event}
                isOpen={popoverState.showPopover}
                onDidDismiss={() => {setShowPopover({ showPopover: false, event: undefined }); setSelectedBooking(null); setSelectedBookingArr(null);}}
            >   
                {selectedBooking && !selectedBookingArr && !Array.isArray(selectedBooking) && <div style={{padding: '2em'}}>
                    <h4 style={{marginBottom: '5px'}}>Pool @ {format(new Date(selectedBooking.date), 'HH:mm')}</h4>
                        <IonChip color={selectedBooking.booking_status === 1 ? "success" : selectedBooking.booking_status === 0 ? "warning" : "danger"}>
                            {selectedBooking.booking_status === 1 ? "approved" : selectedBooking.booking_status === 0 ? "pending" : "rejected"}
                        </IonChip>
                    <IonButton expand="full" onClick={() => {
                        setShowModal(true);
                        setFields();
                    }}>
                        Edit
                    </IonButton>
                </div>}
                {selectedBookingArr && Array.isArray(selectedBookingArr) && 
                    <IonList style={{width: '100%'}}>
                        {selectedBookingArr.map((sb, i) => (
                            <IonItem key={i}>
                                <h4 style={{marginBottom: '5px'}}>Pool @ {format(new Date(sb.date), 'HH:mm')}</h4>
                                    <IonChip color={sb.booking_status === 1 ? "success" : sb.booking_status === 0 ? "warning" : "danger"}>
                                        {sb.booking_status === 1 ? "approved" : sb.booking_status === 0 ? "pending" : "rejected"}
                                    </IonChip>
                                    <IonButton expand="full" slot="end" onClick={() => {
                                        setShowModal(true);
                                        setSelectedBooking(sb);
                                        setFields(sb);
                                    }}>
                                        Edit
                                    </IonButton>
                            </IonItem>
                        ))}
                    </IonList>}
            </IonPopover>
        </BaseTemplate>
    )
}
