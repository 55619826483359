import React, { useState, useEffect } from "react";
import { IonButton, IonAlert, IonIcon } from "@ionic/react";
import { downloadOutline } from "ionicons/icons";
import Modal from '../Modal';
import { Link } from "react-router-dom";
import UserLogs from '../UserLogs/UserLogs';

import {archiveOutline, arrowUndoCircleOutline, alertOutline, checkmarkOutline} from 'ionicons/icons';

import {disableUser, enableUser, getUserInfo, isValidDate} from '../../utils';

import {inovioApi} from '../../apis/inovio';

import "./UsersList.css";

export default function ItemsGrid({
  items,
  getUnitsForUser,
  setShowPopover,
  exportToExcel,
  getUnitsNamesForUser,
}) {
  const [showAlert, setShowAlert] = useState(false);
  const [showArchiveAlert, setShowArchiveAlert] = useState(false);
  const [showUnArchiveAlert, setShowUnArchiveAlert] = useState(false);

  const userTypes = JSON.parse(localStorage.getItem('inovio-estate-info')).usertypes;
  const [formattedUserTypes, setFormattedUserTypes] = useState();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showLogsModal, setShowLogsModal] = useState(false);

  const [userToArchive, setUserToArchive] = useState(null);

  useEffect(() => {
    let tempTypes = {};
    userTypes.map(type => {
      tempTypes[type.id] = type.name;
    });

    setFormattedUserTypes(tempTypes);
  }, []);

  function handleUnitsClick(e, id) {
    getUnitsForUser(id);
    e.persist();
    setShowPopover({ showPopover: true, event: e });
  }
  function openLogsModal(userId) {
    setSelectedUserId(userId);
    setShowLogsModal(true);
  }

  function onArchiveClick(user, isArchived) {
    // alert(userId);

    const {user_id} = user;

    getUserInfo(user_id).then((res) => {
      const fullUser = {...user, codes: res.codes };

      const formattedUser = {
        id: fullUser.user_id,
        firstname: fullUser.firstname,
        lastname: fullUser.lastname,
        email: fullUser.email,
        cellphone: fullUser.cell,
        company: fullUser.company,
        idnumber: fullUser.idnumber,
        right_access: fullUser.grantAccessChecked ? 1 : 0,
        right_calls: fullUser.receiveCallsChecked ? 1 : 0,
        right_user: fullUser.manageUsersChecked ? 1 : 0,
        usertype_id: fullUser.usertype_id,
        day_mon_id: fullUser.day_mon_id,
        day_tue_id: fullUser.day_tue_id,
        day_wed_id: fullUser.day_wed_id,
        day_thu_id: fullUser.day_thu_id,
        day_fri_id: fullUser.day_fri_id,
        day_sat_id: fullUser.day_sat_id,
        day_sun_id: fullUser.day_sun_id,
        starts_at: fullUser.starts_at,
        expires_at: fullUser.expires_at,
        codes: fullUser.codes,
        units: fullUser.unit_id_arr,
        is_archived: isArchived
      };

      setUserToArchive({...formattedUser});
      // setUserCodes(res.codes);

      if(isArchived) setShowArchiveAlert(true);

      if(!isArchived) setShowUnArchiveAlert(true);
    });
  }

  function onArchiveConfirmClick() {
    if(userToArchive && (typeof userToArchive === 'object') && userToArchive.id) {
      const token = localStorage.getItem("inovio-access-token");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
  
      const ESTATE_ID = JSON.parse(localStorage.getItem("inovio-current-estate"))
        .estates_id;
  
      inovioApi
        .post(`/estate/${ESTATE_ID}/user`, {...userToArchive}, config)
        .then((res) => { 
          console.log(res);
          window.location.reload();
        })
        .catch(err => console.log(err));
    }
  }

  function onUnArchiveClick() {
    
  }

  function hasExpired(user) {
    if(!user || !user.expires_at) return 'No expiry';

    if(isValidDate(new Date(user.expires_at)) && (new Date(user.expires_at) < new Date())) return true;

    return true;
  }

  function onEnableClick(id) {

    enableUser(id).then(res => 
      {
        window.location.reload()
      }
      );

  }

  function onDisableClick(id, user) {
    console.log(user);

    disableUser(id).then(res => 
      {
        window.location.reload()
      }
      );

  }
  
  function renderList(collection, title) {
    return (
      <div className="table-container">
        <p className="mb-5" style={{color: 'var(--ion-color-danger)', textAlign: 'left'}}>* if a users details are in red, they have been archived</p>
        {collection && (
        <table
          className="items-center w-full bg-transparent border-collapse"
          id="users-table"
        >
          <thead>
            <tr>
              <th className="px-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                Name
              </th>
              <th className="px-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                Last Name
              </th>
              <th className="px-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                Email
              </th>
              <th className="px-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                Type
              </th>
              <th className="px-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                Unit
              </th>
              <th className="px-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
              </th>
              <th className="px-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
              </th>
              {/* <th className="px-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
              </th> */}
              <th className="px-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                <IonIcon
                  icon={downloadOutline}
                  style={{ cursor: "pointer", fontSize: 24 }}
                  className="text-gray-600 float-right"
                  onClick={() => exportToExcel()}
                ></IonIcon>
              </th>
            </tr>
          </thead>
          <tbody>
            {collection.map((item, i) => {
              return (
                <tr key={i}>
                  <th className="border-t-0 px-2 pl-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left">
                    <Link
                      to={`/users/edit/${item.user_id}`}
                      style={{ color: item.is_archived ? "var(--ion-color-danger)" : "black" }}
                    >
                      {item.firstname}
                    </Link>
                  </th>
                  <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4" style={{ color: item.is_archived ? "var(--ion-color-danger)" : "black" }}>
                    {item.lastname}
                  </td>
                  <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4" style={{ color: item.is_archived ? "var(--ion-color-danger)" : "black" }}>
                    {item.email}
                  </td>
                  <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4" style={{ color: item.is_archived ? "var(--ion-color-danger)" : "black" }}>
                    {formattedUserTypes && `${formattedUserTypes[item.usertype_id]}${item.company ? (", " + item.company) : ''}`}
                  </td>
                  <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap px-1 py-4">
                    {getUnitsNamesForUser(item.id)[0] && (
                      <IonButton
                        expand="block"
                        style={{ width: "100%" }}
                        color="primary"
                        routerLink={`/units/edit/${
                          getUnitsNamesForUser(item.id)[0].id
                        }`}
                        fill="outline"
                        // onClick={(e) => handleUnitsClick(e, item.id)}
                      >
                        {getUnitsNamesForUser(item.id)[0] && getUnitsNamesForUser(item.id)[0].name}
                        {getUnitsNamesForUser(item.id)[1] ? console.log(true) : ''}
                      </IonButton>
                    )}
                  </td>
                  <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap px-1 py-4">
                    <IonButton
                      onClick={() => openLogsModal(item.user_id)}
                      expand="full"
                      style={{ width: "100%" }}
                      color="secondary"
                    >
                      Logs
                    </IonButton>
                  </td>
                  <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap px-1 py-4">
                    <IonButton
                      routerLink={`/users/edit/${item.user_id}`}
                      expand="full"
                      style={{ width: "100%" }}
                      color="warning"
                    >
                      Edit
                    </IonButton>
                  </td>
                  <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap px-1 py-4">
                    {item && !item.is_archived ? (
                      <IonButton
                        onClick={() => onArchiveClick(item, true)}
                        expand="full"
                        style={{ width: "60px" }}
                        color="danger"
                      >
                        <div>
                          <IonIcon icon={archiveOutline} />
                          <p style={{fontSize: 8}}>archive</p>
                        </div>
                      </IonButton>
                    ) : (
                      <IonButton
                        onClick={() => onArchiveClick(item, false)}
                        expand="full"
                        style={{ width: "60px" }}
                        color="warning"
                      >
                        <div>
                          <IonIcon icon={arrowUndoCircleOutline} />
                          <p style={{fontSize: 8}}>unarchive</p>
                        </div>
                      </IonButton>
                    )}
                  </td>
                  {/* <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap px-1 py-4">
                    {hasExpired(item) === 'No expiry' ? (
                      'No expiry'
                    ) : item && !hasExpired(item) ? (
                      <IonButton
                        onClick={() => onDisableClick(item && item.user_id, item)}
                        expand="full"
                        style={{ width: "60px" }}
                        color="danger"
                      >
                        <div>
                          <IonIcon icon={alertOutline} />
                          <p style={{fontSize: 8}}>disable</p>
                        </div>
                      </IonButton>
                    ) : (
                      <IonButton
                        onClick={() => onEnableClick(item && item.user_id)}
                        expand="full"
                        style={{ width: "60px" }}
                        color="success"
                      >
                        <div>
                          <IonIcon icon={checkmarkOutline} />
                          <p style={{fontSize: 8}}>enable</p>
                        </div>
                      </IonButton>
                    )}
                  </td> */}
                  {/* <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap px-1 py-4">
                    <IonButton
                      expand="full"
                      style={{ width: "100%" }}
                      color="danger"
                      onClick={() => setShowAlert(true)}
                    >
                      Delete
                    </IonButton>
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>)}
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass="my-custom-class"
          header={"Alert"}
          message={"Are you sure you want to delete this user?"}
          buttons={["Cancel", "Delete"]}
        />
        <IonAlert
          isOpen={showArchiveAlert}
          onDidDismiss={() => setShowArchiveAlert(false)}
          cssClass="my-custom-class"
          header={"Alert"}
          message={"Are you sure you want to archive this user?"}
          buttons={["Cancel", {text: "Archive", handler: onArchiveConfirmClick}]}
        />

        <IonAlert
          isOpen={showUnArchiveAlert}
          onDidDismiss={() => setShowUnArchiveAlert(false)}
          cssClass="my-custom-class"
          header={"Alert"}
          message={"Are you sure you want to un-archive this user?"}
          buttons={["Cancel", {text: "Confirm", handler: onArchiveConfirmClick}]}
        />
        <Modal showModal={showLogsModal} setShowModal={setShowLogsModal}>
            <UserLogs userId={selectedUserId} />
        </Modal>
      </div>
    );
  }

  return (
    <div className="items-list" style={{ textAlign: "center" }}>
      {renderList(items, "Users")}
    </div>
  );
}
