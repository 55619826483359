import React, { useState, useEffect } from 'react';
import { IonItem, IonInput, IonSelect, IonLabel, IonDatetime, IonButton, IonGrid, IonRow, IonCol, IonSelectOption } from '@ionic/react';
import jwtDecode from 'jwt-decode';
import SearchUsersModal from './SearchUsersModal/SearchUsersModal';
import { getUsers } from '../utils';
import { inovioApi } from "../apis/inovio";

export default function AddManualLogEntry({acrList}) {
    const [eventAt, setEventAt] = useState();
    const [granted, setGranted] = useState();
    const [status, setStatus] = useState(null);
    const [code, setCode] = useState();
    const [userId, setUserId] = useState();
    const [usersUnitId, setUsersUnitId] = useState();
    const [acr, setAcr] = useState();
    const [acrName, setAcrName] = useState();
    const [direction, setDirection] = useState();
    const [users, setUsers] = useState();
    const [user, setUser] = useState();
    const [showModal, setShowModal] = useState(false);

    function handleSetAcr(val) {
        if(val) {
            console.log(val);
            setAcr(val);
        }
    }

    useEffect(() => {
        getUsers().then(res => {
            setUsers(res);
        });
    }, []);

    useEffect(() => {
        if(user) {
            setUserId(user.user_id);
            setUsersUnitId(user.unit_id_arr[0] ? user.unit_id_arr[0] : null);
        }
    }, [user]);

    async function handleSubmit(e) {
        const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
        e.preventDefault();

        const manualLog = {
            event_at: eventAt,
            granted,
            status,
            code, 
            user_id: userId,
            users_unit_id: usersUnitId,
            estate_id: ESTATE_ID,
            acr_loc: acr.acr,
            acr_name: acr.name,
            direction,
            initiator_user_id: decodedToken.id
        };

        const token = localStorage.getItem("inovio-access-token");
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
    
        const decodedToken = jwtDecode(token);
        inovioApi
            .post(`/log/accessadd`, manualLog, config)
            .then((res) => { 
                console.log(res);
                window.location.reload();
            })
            .catch(err => console.log(err));

    }

    return (
        <div style={{ marginTop: '6em' }}>
            <IonGrid>
                <IonRow>
                    <IonCol size="8" offset="2">
                        <form onSubmit={handleSubmit}>
                            {!user ?

                                <IonButton onClick={() => setShowModal(true)} expand="block" style={{marginBottom: '2em'}}>
                                    Search For User
                                </IonButton>

                            :
                            <IonItem>
                                <IonLabel><h2>{user.firstname} {user.lastname}</h2></IonLabel>
                                <IonButton onClick={() => setShowModal(true)}>
                                    Change User
                                </IonButton>
                            </IonItem>
                            }
                            {users && <SearchUsersModal showModal={showModal} setShowModal={setShowModal} users={users} setUser={setUser} />}
                            <IonItem>
                                <IonLabel>Acr Location</IonLabel>
                                <IonSelect value={acr} okText="Okay" cancelText="Dismiss" onIonChange={e => handleSetAcr(e.detail.value)}>
                                    {acrList.map(acr => (
                                        <IonSelectOption value={acr} key={acr.id}>{acr.name}</IonSelectOption>
                                    ))}
                                </IonSelect>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Access Granted</IonLabel>
                                <IonSelect value={granted} okText="Okay" cancelText="Dismiss" onIonChange={e => setGranted(e.detail.value)}>
                                    <IonSelectOption value={1}>True</IonSelectOption>
                                    <IonSelectOption value={0}>False</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Direction</IonLabel>
                                <IonSelect value={direction} okText="Okay" cancelText="Dismiss" onIonChange={e => setDirection(e.detail.value)}>
                                    <IonSelectOption value={1}>In</IonSelectOption>
                                    <IonSelectOption value={2}>Out</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Time of Entry</IonLabel>
                                <IonDatetime
                                    displayFormat="MMM DD, YYYY HH:mm"
                                    placeholder="MMM DD, YYYY HH:mm"
                                    min="2020-01-01" 
                                    max="2030-12-31"
                                    value={eventAt}
                                    onIonChange={(e) => setEventAt(e.detail.value)}
                                ></IonDatetime>
                            </IonItem>
                            <IonItem>
                            <IonLabel position="floating">Code</IonLabel>
                                <IonInput
                                    value={code}
                                    autocomplete="off"
                                    onIonChange={(e) => setCode(e.detail.value)}
                                ></IonInput>
                            </IonItem>
                            <IonButton
                                type="submit"
                                expand="block"
                                style={{ marginTop: "7em", marginBottom: "3em" }}
                            >
                                Submit
                            </IonButton>
                        </form>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}
