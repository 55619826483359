import React, { useRef, useState, useEffect } from "react";
import {
  IonText,
  IonButton,
  IonItem,
  IonSelectOption,
  IonSelect,
  IonInput,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonFab,
  IonFabButton,
  IonIcon,
  IonToggle,
  // IonDatetime,
  IonSegment,
  IonSegmentButton,
  IonAlert,
  IonPopover
} from "@ionic/react";
import BaseTemplate from "../../components/base/BaseTemplate";
import { arrowBackOutline } from "ionicons/icons";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { closeOutline } from "ionicons/icons";
import { inovioApi } from "../../apis/inovio";
import SearchUnitsModal from "../../components/SearchUnitsModal/SearchUnitsModal";
import DuplicateUsersModal from "../../components/DuplicateUsersModal";

import Enrollment from "../../components/Enrollment/Enrollment";

import DayPicker from 'react-day-picker';

import {format} from 'date-fns';

import {isValidJson} from '../../utils';

import "./AddNewUser.css";

function AddNewUser() {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cell, setCell] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [company, setCompany] = useState("");
  const [mondayAccess, setMondayAccess] = useState();
  const [tuesdayAccess, setTuesdayAccess] = useState();
  const [wednesdayAccess, setWednesdayAccess] = useState();
  const [thursdayAccess, setThursdayAccess] = useState();
  const [fridayAccess, setFridayAccess] = useState();
  const [saturdayAccess, setSaturdayAccess] = useState();
  const [sundayAccess, setSundayAccess] = useState();
  const [allAccess, setAllAccess] = useState();
  const [userType, setUserType] = useState();
  const history = useHistory();
  const [grantAccessChecked, setGrantAccessChecked] = useState(false);
  const [receiveCallsChecked, setReceiveCallsChecked] = useState(false);
  const [manageUsersChecked, setManageUsersChecked] = useState(false);
  const [expiryStart, setExpiryStart] = useState();
  const [expiryEnd, setExpiryEnd] = useState();
  const [showExpiryStart, setShowExpiryStart] = useState({ showPopover: false, event: undefined });
  const [showExpiryEnd, setShowExpiryEnd] = useState({ showPopover: false, event: undefined });

  const [selectedSegment, setSelectedSegment] = useState("info");
  const [userCodes, setUserCodes] = useState([""]);
  const [usersUnits, setUsersUnits] = useState([]);
  const [refreshed, setRefreshed] = useState(false);
  const unitsSelectRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showSelectUserAlert, setShowSelectUserAlert] = useState(false);
  const [weekdayAccess, setWeekdayAccess] = useState();
  const [showDuplicatesModal, setShowDuplicatesModal] = useState(false);
  const [duplicateUsers, setDuplicateUsers] = useState([]);

  const userTypes = JSON.parse(localStorage.getItem("inovio-estate-info"))
    .usertypes;
  const dayTypes = JSON.parse(localStorage.getItem("inovio-estate-info"))
    .daytypes;
  const units = JSON.parse(localStorage.getItem("inovio-units"));

  dayTypes.unshift({
    id: null,
    name: "None",
  });

  async function handleSubmit(e) {
    e.preventDefault();

    // console.log(duplicateUsersTemp);
    // if (
    //   firstName === "" ||
    //   lastName === "" ||
    //   email === "" ||
    //   cell === "" ||
    //   password === "" ||
    //   company === "" ||
    //   idNumber === "" ||
    //   !mondayAccess ||
    //   !tuesdayAccess ||
    //   !wednesdayAccess ||
    //   !thursdayAccess ||
    //   !fridayAccess ||
    //   !saturdayAccess ||
    //   !sundayAccess ||
    //   !userType ||
    //   userCodes[0] === ""
    // ) {
    //   setShowAlert(true);
    //   return;
    // }

    if(!userType) {
      setShowSelectUserAlert(true);
      setButtonDisabled(false);
      return;
    }

    const duplicateUsersTemp = checkDuplicates();

    if(duplicateUsersTemp) {
      setDuplicateUsers([...duplicateUsersTemp]);

      setShowDuplicatesModal(true);

      return;
    } else { setDuplicateUsers([]); }


    setButtonDisabled(true);

    const addUser = {
      firstname: firstName,
      lastname: lastName,
      email: email === "" ? generateRandomEmail() : email,
      cellphone: cell,
      password: password,
      company: company,
      idnumber: idNumber,
      right_access: grantAccessChecked ? 1 : 0,
      right_calls: receiveCallsChecked ? 1 : 0,
      right_user: manageUsersChecked ? 1 : 0,
      usertype_id: parseInt(userType),
      day_mon_id: parseInt(mondayAccess),
      day_tue_id: parseInt(tuesdayAccess),
      day_wed_id: parseInt(wednesdayAccess),
      day_thu_id: parseInt(thursdayAccess),
      day_fri_id: parseInt(fridayAccess),
      day_sat_id: parseInt(saturdayAccess),
      day_sun_id: parseInt(sundayAccess),
      starts_at: expiryStart,
      expires_at: expiryEnd,
      units: usersUnits.map((unit) => unit.id),
    };

    const token = localStorage.getItem("inovio-access-token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const ESTATE_ID = JSON.parse(localStorage.getItem("inovio-current-estate"))
      .estates_id;
    const res = await inovioApi.post(`/estate/${ESTATE_ID}/user`, addUser, config);
    console.log(res);
    const id = res.data[0].replace("User Created id:", "");
    history.push(`/users/edit/${id}?enrollment`);

  }



  function generateRandomEmail() {
    const date = new Date();
    return `${date.getFullYear()}${date.getTime()}@inoviomail.co.za`;
  }

  function removeCode(index) {
    const newCodes = userCodes.filter((code, i) => i !== index);

    setUserCodes(newCodes);
  }

  function removeUnit(index) {
    const newUnits = usersUnits.filter((unit, i) => i !== index);

    setUsersUnits(newUnits);
  }

  function handleCodesChange(value, index) {
    const codes = userCodes;
    codes[index] = value;
    setUserCodes(codes);
  }

  function handleUnitsChange(value) {
    const updatedUnits = usersUnits;
    updatedUnits[updatedUnits.length] = units.find(
      (unit) => unit.id === parseInt(value)
    );
    setUsersUnits(updatedUnits);
    setRefreshed(!refreshed);
    setShowModal(false);
  }

  function openUnitsSelectRef() {
    unitsSelectRef.current.open();
  }

  function setAllAccessValue(value) {
    setAllAccess(value);
    setMondayAccess(value);
    setTuesdayAccess(value);
    setWednesdayAccess(value);
    setThursdayAccess(value);
    setFridayAccess(value);
    setSaturdayAccess(value);
    setSundayAccess(value);
  }

  function setWeekdayAccessValue(value) {
    setWeekdayAccess(value);
    setMondayAccess(value);
    setTuesdayAccess(value);
    setWednesdayAccess(value);
    setThursdayAccess(value);
    setFridayAccess(value);
    // setSaturdayAccess(value);
    // setSundayAccess(value);
  }

  function checkDuplicates() {
    const usersJsonString = localStorage.getItem('inovio-users');
    const parsedUsers = usersJsonString && isValidJson(usersJsonString) && JSON.parse(usersJsonString);

    const foundDuplicates = parsedUsers.filter(u => (u.firstname && (u.firstname.toLowerCase() === firstName)) && (u.lastname && (u.lastname.toLowerCase() === lastName)));

    if(foundDuplicates && Array.isArray(foundDuplicates) && (foundDuplicates.length > 0)) {
      return foundDuplicates;
    }

    return false;
  }

  // useEffect(() => {
  //   console.log(showExpiryStart);
  // }, [showExpiryStart]);

  async function onDuplicateProceedClick() {
    const addUser = {
      firstname: firstName,
      lastname: lastName,
      email: email === "" ? generateRandomEmail() : email,
      cellphone: cell,
      password: password,
      company: company,
      idnumber: idNumber,
      right_access: grantAccessChecked ? 1 : 0,
      right_calls: receiveCallsChecked ? 1 : 0,
      right_user: manageUsersChecked ? 1 : 0,
      usertype_id: parseInt(userType),
      day_mon_id: parseInt(mondayAccess),
      day_tue_id: parseInt(tuesdayAccess),
      day_wed_id: parseInt(wednesdayAccess),
      day_thu_id: parseInt(thursdayAccess),
      day_fri_id: parseInt(fridayAccess),
      day_sat_id: parseInt(saturdayAccess),
      day_sun_id: parseInt(sundayAccess),
      starts_at: expiryStart,
      expires_at: expiryEnd,
      units: usersUnits.map((unit) => unit.id),
    };

    const token = localStorage.getItem("inovio-access-token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const ESTATE_ID = JSON.parse(localStorage.getItem("inovio-current-estate"))
      .estates_id;
    const res = await inovioApi.post(`/estate/${ESTATE_ID}/user`, addUser, config);
    console.log(res);
    const id = res.data[0].replace("User Created id:", "");
    history.push(`/users/edit/${id}?enrollment`);
  }

  return (
    <BaseTemplate>
      <div className="edit-user">
        <IonGrid>
          <IonRow style={{ boxShadow: "none" }}>
            <IonCol>
              <div className="shadow-xl px-10 py-2">
                <h1
                  style={{ margin: "0 auto 1em auto" }}
                  className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold"
                >
                  Add User
                </h1>
                <IonSegment
                  value={selectedSegment}
                  onIonChange={(e) => setSelectedSegment(e.detail.value)}
                >
                  <IonSegmentButton value="info">
                    <IonLabel>Information</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="access">
                    <IonLabel>Access</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
                <form onSubmit={handleSubmit} style={{ marginTop: "1em" }}>
                  {selectedSegment === "info" && (
                    <>
                      <IonItem>
                        <IonLabel position="floating">First Name</IonLabel>
                        <IonInput
                          required
                          value={firstName}
                          autocomplete="off"
                          onIonChange={(e) => setFirstName(e.detail.value)}
                        ></IonInput>
                      </IonItem>
                      <IonItem>
                        <IonLabel position="floating">Last Name</IonLabel>
                        <IonInput
                          required
                          value={lastName}
                          autocomplete="off"
                          onIonChange={(e) => setLastName(e.detail.value)}
                        ></IonInput>
                      </IonItem>
                      <IonItem>
                        <IonLabel position="floating">ID Number</IonLabel>
                        <IonInput
                          required
                          value={idNumber}
                          autocomplete="off"
                          onIonChange={(e) => setIdNumber(e.detail.value)}
                        ></IonInput>
                      </IonItem>
                      <IonItem>
                        <IonLabel position="floating">Email</IonLabel>
                        <IonInput
                          required
                          type="email"
                          value={email}
                          autocomplete="off"
                          onIonChange={(e) => setEmail(e.detail.value)}
                        ></IonInput>
                      </IonItem>
                      <IonItem>
                        <IonLabel position="floating">Cell</IonLabel>
                        <IonInput
                          required
                          type="tel"
                          value={cell}
                          autocomplete="new-password"
                          onIonChange={(e) => setCell(e.detail.value)}
                        ></IonInput>
                      </IonItem>
                      <IonItem>
                        <IonLabel position="floating">Password</IonLabel>
                        <IonInput
                          required
                          type="password"
                          value={password}
                          autocomplete="new-password"
                          onIonChange={(e) => setPassword(e.detail.value)}
                        ></IonInput>
                      </IonItem>
                      <IonItem>
                        <IonLabel position="floating">Company</IonLabel>
                        <IonInput
                          required
                          value={company}
                          autocomplete="off"
                          onIonChange={(e) => setCompany(e.detail.value)}
                        ></IonInput>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Start Date</IonLabel>
                        {/* <IonDatetime
                          displayFormat="MM DD YYYY"
                          placeholder="MM DD YYYY"
                          min="2000-01-01" 
                          max="2030-12-31"
                          value={expiryStart}
                          onIonChange={(e) => setExpiryStart(e.detail.value)}
                        ></IonDatetime> */}
                        <IonInput style={{textAlign: 'right'}} onClick={(e) => {e.persist(); setShowExpiryStart({ showPopover: true, event: e })}} placeholder="Expiry Start Date" value={expiryStart ? format(new Date(expiryStart), 'MM DD YYYY') : ''} />

                      </IonItem>
                      <IonItem>
                        <IonLabel>Expiry Date</IonLabel>
                        {/* <IonDatetime
                          displayFormat="MM DD YYYY"
                          placeholder="MM DD YYYY"
                          min="2000-01-01" 
                          max="2030-12-31"
                          value={expiryEnd}
                          onIonChange={(e) => setExpiryEnd(e.detail.value)}
                        ></IonDatetime> */}
                        <IonInput style={{textAlign: 'right'}} onClick={(e) => {e.persist(); setShowExpiryEnd({ showPopover: true, event: e })}} placeholder="Expiry End Date" value={expiryEnd ? format(new Date(expiryEnd), 'MM DD YYYY') : ''} />

                      </IonItem>
                      <IonItem>
                        <IonLabel>Grant Access</IonLabel>
                        <IonToggle
                          checked={grantAccessChecked}
                          onIonChange={(e) =>
                            setGrantAccessChecked(e.detail.checked)
                          }
                        />
                      </IonItem>
                      <IonItem>
                        <IonLabel>Receive Calls</IonLabel>
                        <IonToggle
                          checked={receiveCallsChecked}
                          onIonChange={(e) =>
                            setReceiveCallsChecked(e.detail.checked)
                          }
                        />
                      </IonItem>
                      <IonItem>
                        <IonLabel>Manage Users</IonLabel>
                        <IonToggle
                          checked={manageUsersChecked}
                          onIonChange={(e) =>
                            setManageUsersChecked(e.detail.checked)
                          }
                        />
                      </IonItem>
                      <IonItem>
                        <IonLabel>User Type</IonLabel>
                        <IonSelect
                          okText="Okay"
                          cancelText="Dismiss"
                          value={userType}
                          onIonChange={(e) => setUserType(e.detail.value)}
                        >
                          {userTypes.map((type) => (
                            <IonSelectOption
                              value={String(type.id)}
                              key={type.id}
                            >
                              {type.name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                      {usersUnits.map((unit, i) => (
                        <IonItem>
                          <IonLabel>{unit.name}</IonLabel>
                          {/* <IonInput required value={unit.name} onIonChange={e => handleUnitsChange(e.detail.value, i)}></IonInput> */}
                          <IonIcon
                            icon={closeOutline}
                            slot="end"
                            color="danger"
                            style={{ paddingTop: "0.25em" }}
                            onClick={() => removeUnit(i)}
                          ></IonIcon>
                        </IonItem>
                      ))}
                      <IonItem>
                        <IonButton onClick={() => setShowModal(true)}>
                          {usersUnits.length > 0
                            ? "Link Additional Unit"
                            : "Link Unit"}
                        </IonButton>
                        <IonSelect
                          id="units-select"
                          ref={unitsSelectRef}
                          onIonChange={(e) => handleUnitsChange(e.detail.value)}
                        >
                          {units.map((unit) => (
                            <IonSelectOption value={unit.id} key={unit.id}>
                              {unit.name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                    </>
                  )}
                  {/* <IonItem>
                      <h1 style={{margin: '1em auto'}} className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">Access</h1>
                    </IonItem> */}
                  {selectedSegment === "access" && (
                    <>
                      <div style={{margin: '2em 1em'}}>
                        <h1 style={{textAlign: 'left'}} className="uppercase my-3">Access Times</h1>
                        {dayTypes.map((type, i) => (
                          <div style={{textAlign: 'left'}} key={i}>
                            <p>{type.name !== "None" && `${type.name}: ${type.time_start} - ${type.time_end}`}</p>
                          </div>
                        ))}
                      </div>
                      <IonItem>
                        <IonLabel>Set All</IonLabel>
                        <IonSelect
                          okText="Okay"
                          interface="popover"
                          cancelText="Dismiss"
                          value={allAccess}
                          onIonChange={(e) => setAllAccessValue(e.detail.value)}
                        >
                          {dayTypes.map((type) => (
                            <IonSelectOption value={String(type.id)}>
                              {type.name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Set Weekdays</IonLabel>
                        <IonSelect
                          okText="Okay"
                          interface="popover"
                          cancelText="Dismiss"
                          value={weekdayAccess}
                          onIonChange={(e) => setWeekdayAccessValue(e.detail.value)}
                        >
                          {dayTypes.map((type) => (
                            <IonSelectOption value={String(type.id)}>
                              {type.name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Monday</IonLabel>
                        <IonSelect
                          okText="Okay"
                          interface="popover"
                          cancelText="Dismiss"
                          value={mondayAccess}
                          onIonChange={(e) => setMondayAccess(e.detail.value)}
                        >
                          {dayTypes.map((type) => (
                            <IonSelectOption value={String(type.id)}>
                              {type.name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Tuesday</IonLabel>
                        <IonSelect
                          okText="Okay"
                          interface="popover"
                          cancelText="Dismiss"
                          value={tuesdayAccess}
                          onIonChange={(e) => setTuesdayAccess(e.detail.value)}
                        >
                          {dayTypes.map((type) => (
                            <IonSelectOption value={String(type.id)}>
                              {type.name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Wednesday</IonLabel>
                        <IonSelect
                          okText="Okay"
                          interface="popover"
                          cancelText="Dismiss"
                          value={wednesdayAccess}
                          onIonChange={(e) =>
                            setWednesdayAccess(e.detail.value)
                          }
                        >
                          {dayTypes.map((type) => (
                            <IonSelectOption value={String(type.id)}>
                              {type.name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Thursday</IonLabel>
                        <IonSelect
                          okText="Okay"
                          interface="popover"
                          cancelText="Dismiss"
                          value={thursdayAccess}
                          onIonChange={(e) => setThursdayAccess(e.detail.value)}
                        >
                          {dayTypes.map((type) => (
                            <IonSelectOption value={String(type.id)}>
                              {type.name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Friday</IonLabel>
                        <IonSelect
                          okText="Okay"
                          interface="popover"
                          cancelText="Dismiss"
                          value={fridayAccess}
                          onIonChange={(e) => setFridayAccess(e.detail.value)}
                        >
                          {dayTypes.map((type) => (
                            <IonSelectOption value={String(type.id)}>
                              {type.name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Saturday</IonLabel>
                        <IonSelect
                          okText="Okay"
                          interface="popover"
                          cancelText="Dismiss"
                          value={saturdayAccess}
                          onIonChange={(e) => setSaturdayAccess(e.detail.value)}
                        >
                          {dayTypes.map((type) => (
                            <IonSelectOption value={String(type.id)}>
                              {type.name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Sunday</IonLabel>
                        <IonSelect
                          okText="Okay"
                          interface="popover"
                          cancelText="Dismiss"
                          value={sundayAccess}
                          onIonChange={(e) => setSundayAccess(e.detail.value)}
                        >
                          {dayTypes.map((type) => (
                            <IonSelectOption value={String(type.id)}>
                              {type.name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                    </>
                  )}
                  {selectedSegment === "enrollment" && <Enrollment />}
                  {selectedSegment === "access" ? (
                    <IonButton
                      type="submit"
                      expand="block"
                      style={{ marginTop: "7em", marginBottom: "3em" }}
                      disabled={buttonDisabled}
                    >
                      Add User
                    </IonButton>
                  ) : (
                    <IonButton
                      expand="block"
                      style={{ marginTop: "7em", marginBottom: "3em" }}
                      type="button"
                      onClick={() => setSelectedSegment("access")}
                    >
                      Next
                    </IonButton>
                  )}
                </form>
              </div>
              <div className="cancel-div">
                <IonText>
                  <Link to="/">Cancel</Link>
                </IonText>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      <IonFab vertical="top" horizontal="start" slot="fixed">
        <IonFabButton onClick={() => history.goBack()}>
          <IonIcon icon={arrowBackOutline}></IonIcon>
        </IonFabButton>
      </IonFab>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        cssClass="my-custom-class"
        header={"Alert"}
        message={"Please fill out all fields."}
        buttons={["OK"]}
      />
      <IonAlert
        isOpen={showSelectUserAlert}
        onDidDismiss={() => setShowSelectUserAlert(false)}
        cssClass="my-custom-class"
        header={"Alert"}
        message={"Please select a user type."}
        buttons={["OK"]}
      />
      <SearchUnitsModal
        units={units}
        setShowModal={setShowModal}
        showModal={showModal}
        handleUnitsChange={handleUnitsChange}
      />
      <DuplicateUsersModal
          users={duplicateUsers}
          setShowModal={setShowDuplicatesModal}
          showModal={showDuplicatesModal}
          onProceedClick={onDuplicateProceedClick}
          onCancelClick={() => {setShowDuplicatesModal(false); setDuplicateUsers([]);}}
        />
      <IonPopover
          cssClass="datetime-popover-picker"
          isOpen={showExpiryStart.showPopover}
          event={showExpiryStart.event}
          onDidDismiss={() => setShowExpiryStart({ showPopover: false, event: undefined })}
      >
          <DayPicker canChangeMonth={true} onDayClick={(day) => { setExpiryStart(day.toString()); setShowExpiryStart({ showPopover: false, event: undefined }); }} />
      </IonPopover>
      <IonPopover
          cssClass="datetime-popover-picker"
          isOpen={showExpiryEnd.showPopover}
          event={showExpiryEnd.event}
          onDidDismiss={() => setShowExpiryEnd({ showPopover: false, event: undefined })}
      >
          <DayPicker canChangeMonth={true} onDayClick={(day) => { setExpiryEnd(day.toString()); setShowExpiryEnd({ showPopover: false, event: undefined }); }} />
      </IonPopover>
    </BaseTemplate>
  );
}

export default AddNewUser;
