import React, { useState, useRef, useEffect } from "react";
import {
  IonText,
  IonButton,
  IonItem,
  IonInput,
  IonLabel,
  IonFab,
  IonFabButton,
  IonIcon,
  IonList,
  IonSelect,
  IonSelectOption,
  IonAlert
} from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";
import { Link } from "react-router-dom";
import BaseTemplate from "../../components/base/BaseTemplate";
import AddMemberToStandModal from "../../components/AddMemberToStandModal/AddMemberToStandModal";
import { useHistory } from "react-router-dom";
import { inovioApi } from "../../apis/inovio";
import { getUsers, getUsersFresh, getRelationships, addRelationship } from "../../utils";
import "./EditUnit.css";

export default function EditUnit() {
  const [loading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const nameRef = useRef();
  const addressRef = useRef();
  const [unit, setUnit] = useState();
  const [users, setUsers] = useState();
  const [id, setId] = useState();
  const [usersUnit, setUsersUnit] = useState();
  const [formattedUserTypes, setFormattedUserTypes] = useState();
  const userTypes = JSON.parse(localStorage.getItem("inovio-estate-info"))
    .usertypes;
  const units = JSON.parse(localStorage.getItem("inovio-units"));
  const [userGroups, setUserGroups] = useState();
  const [relationships, setRelationships] = useState({});

  const [relationshipTypes, setRelationshipTypes] = useState([]);
  
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    const id = window.location.pathname.replace("/units/edit/", "");
    setId(id);
    const unit = units.find((unit) => unit.id === parseInt(id));
    setUnit(unit);

    if (localStorage.getItem("inovio-users")) {
      setUsers(JSON.parse(localStorage.getItem("inovio-users")));
    } else {
      getUsers().then((users) => {
        setUsers(users);
      });
    }
    
    let tempTypes = {};
    userTypes.map(type => {
      console.log(type);
      tempTypes[type.id] = type.name;
    });

    setFormattedUserTypes(tempTypes);

    getRelationships().then(res => {
      if(res && Array.isArray(res)) setRelationshipTypes(res);
    });

  }, []);

  useEffect(() => {
    if (users) {
      getSelectedUsersForUnit(id);
    }
  }, [users, id]);

  function handleSubmit(e) {
    e.preventDefault();

    const editUnit = {
      id: unit.id,
      address: addressRef.current.value,
      name: nameRef.current.value,
    };

    console.log(editUnit);

    const token = localStorage.getItem("inovio-access-token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;

    inovioApi
      .post(`/estate/${ESTATE_ID}/unit`, editUnit, config)
      .then((res) => console.log(res));
    localStorage.removeItem("inovio-units");
    history.push("/units");
  }

  function getSelectedUsersForUnit(unitId) {
    let userGroupsObject = {};
    userTypes.map(type => {
      userGroupsObject[type.id] = [];
    });
    const unitsUsers = [];
    users.map((user) => {
      user.unit_id_arr.map((uId) => {
        if (unitId == uId) {
          unitsUsers.push(user);
          userGroupsObject[user.usertype_id].push(user);
        }
      });
    });

    console.log('UNIT USERS', userGroupsObject);

    setUserGroups({...userGroupsObject});
    setUsersUnit([...unitsUsers]);
  }

  function changeRelationship(value, user) {

    if(unit && unit.id && user && user.id && value && value !== user.relationship_id) {
      addRelationship(unit.id, user.id, user.id, value).then(res => {
        if(res && res  === 'Added') {
          setAlertTitle('Successfully updated');
          setAlertMessage(`Relationship type has been assigned to ${user.firstname} ${user.lastname}`);
          setShowAlert(true);

          // window.location.reload();

          getUsers().then((us) => {
            setUsers([...us]);
          });
        }
      });
    }
  }

  function getRelationshipId(user) {
    if(user && user.relationship_id && relationshipTypes && Array.isArray(relationshipTypes) && relationshipTypes.length > 0) {
      const currentRelationship = relationshipTypes.find(r => r.id === user.relationship_id);

      if(currentRelationship && currentRelationship.id) return currentRelationship.id;

      return 0;
    }

    return 0;
  }

  return (
    <BaseTemplate>
      {unit && (
        <div style={{ margin: "1em" }} className="shadow-xl px-10 py-2">
          <h1
            style={{ textAlign: "center", margin: "0 auto 1em auto" }}
            className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold"
          >
            Edit {unit.name}
          </h1>
          <form onSubmit={handleSubmit}>
            <IonItem>
              <IonLabel position="floating">Name</IonLabel>
              <IonInput
                type="text"
                required
                value={unit.name}
                ref={nameRef}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Address</IonLabel>
              <IonInput
                type="text"
                required
                value={unit.address}
                ref={addressRef}
              ></IonInput>
            </IonItem>
            {userGroups && userTypes && (
              <IonList style={{ width: "100%" }}>
                {userTypes.map((type, i) => (
                  <div key={i}>
                  {userGroups[type.id].length > 0 && <h1 className="p-4 text-left text-gray-900 uppercase font-semibold" style={{marginTop: '0.75rem'}}>{type.name}s</h1>}
                    {userGroups[type.id].map(user => (
                      <IonItem key={user.user_id}>
                        <IonLabel>
                          {user.firstname} {user.lastname}
                        </IonLabel>
                        {relationshipTypes && <IonSelect placeholder="Set Relationship" value={getRelationshipId(user)} onIonChange={(e) => changeRelationship(e.target.value, user)} interface="popover">
                          {relationshipTypes.map(({id, name}, i) => (
                            <IonSelectOption value={id}>{name}</IonSelectOption>
                          ))}

                        </IonSelect>}
                        <IonButton routerLink={`/users/edit/${user.user_id}`} style={{marginLeft: '1em'}}>
                          View
                        </IonButton>
                        {/* <span
                          style={{
                            fontSize: "0.75em",
                            color: "grey",
                            textAlign: 'left',
                            width: '50px'
                          }}
                          slot="end"
                        >
                          {formattedUserTypes[user.usertype_id].name}
                        </span> */}
                      </IonItem>
                    ))}
                  </div>
                ))}
              </IonList>
            )}

            <IonButton
              type="submit"
              expand="block"
              style={{ marginTop: "7em" }}
              disabled={loading}
            >
              Update
            </IonButton>
          </form>
          <div
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: "2em",
            }}
          >
            <IonText>
              <Link to="/">Cancel</Link>
            </IonText>
          </div>
        </div>
      )}
      <IonFab
        vertical="top"
        horizontal="start"
        slot="fixed"
        style={{ marginBottom: "3em" }}
      >
        <IonFabButton onClick={() => history.goBack()}>
          <IonIcon icon={arrowBackOutline}></IonIcon>
        </IonFabButton>
      </IonFab>
      <AddMemberToStandModal
        setShowModal={setShowModal}
        showModal={showModal}
      />
      <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={alertTitle}
          message={alertMessage}
          buttons={['OK']}
      />
    </BaseTemplate>
  );
}
