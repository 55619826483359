import React, { useState, useEffect } from "react";
import {
  IonGrid,
  IonCol,
  IonRow,
} from "@ionic/react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Fuse from "fuse.js";
import { getUserAccess } from "../../utils";
import Loading from "../Loading";
import Search from "../Search/Search";

import "./UserLogs.css";

const PER_PAGE = 15;

export default function UserLogs({userId}) {
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasLogs, setHasLogs] = useState(true);

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  const offset = currentPage * PER_PAGE;

  const currentPageData = data.slice(offset, offset + PER_PAGE);

  const pageCount = Math.ceil(data.length / PER_PAGE);

  useEffect(() => {
    getUserAccess(userId, 500).then((res) => {
      if(res.length > 0) {
        setHasLogs(true);
      } else {
        setHasLogs(false);
      }
      setData(res);
      setLoading(false);
    });
  }, []);

  return (
    <>
      {!hasLogs && (
        <div style={{textAlign: 'center'}}>
          <h2 style={{margin: "1em auto"}}>No logs recorded for this user.</h2>
        </div>
      )}
      {hasLogs && (
      <div className="user-logs">
        <IonGrid>
          <IonRow style={{ boxShadow: "none" }}>
            <IonCol size="11" offset="0.5" className="table-container">
              <table
                className="items-center w-full bg-transparent border-collapse"
                id="logs-table"
              >
                <thead>
                  <tr>
                    <th className="px-2 pl-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                      Name
                    </th>
                    <th className="px-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                      Code
                    </th>
                    <th className="px-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                      Direction
                    </th>
                    <th className="px-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                      Gate
                    </th>
                    <th className="px-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                      Reader
                    </th>
                    <th className="px-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                      Date
                    </th>
                    <th className="px-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                      Access Granted
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentPageData.map((l, i) => (
                    <tr key={i}>
                      <th className="border-t-0 px-2 pl-4 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left">
                        <Link
                          to={`/users/edit/${l.user_id}`}
                          style={{ color: "black" }}
                        >
                          {l.firstname !== null && l.firstname}
                          {" "}
                          {l.lastname !== null && l.lastname}
                        </Link>
                      </th>
                      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                        {l.code !== null ? l.code : ""}
                      </td>
                      <td
                        className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4"
                        style={{ color: l.direction === 1 ? "green" : "red" }}
                      >
                        {l.direction === 1 ? "In" : "Out"}
                      </td>
                      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                        {l.acr_name}
                      </td>
                      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                        {l.acr_loc}
                      </td>
                      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                        {new Date(l.event_at).toLocaleDateString()}{" "}
                        {new Date(l.event_at).toLocaleTimeString("en-GB")}
                      </td>
                      <td
                        className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4"
                        style={{ color: l.granted === 1 ? "green" : "red" }}
                      >
                        {l.granted === 0 ? `false, ${l.status}` : "true"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Loading loading={loading} />
            </IonCol>
          </IonRow>
        </IonGrid>
        <div style={{ margin: "0 6em" }}>
          <ReactPaginate
            previousLabel={"←"}
            nextLabel={"→"}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        </div>
      </div>
      )}
    </>
  );
}
