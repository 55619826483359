import React from "react";
import {
IonModal,
IonIcon,
IonButton
} from "@ionic/react";
import { close } from "ionicons/icons";
import {Link} from 'react-router-dom';

const SearchUsersModal = ({
    users,
    showModal, 
    setShowModal,
    onProceedClick,
    onCancelClick
}) => {

    console.log(users);

return (
    <IonModal isOpen={showModal} className="not-fullscreen">
    <div
        style={{
        width: "100%",
        position: "relative",
        height: "20px",
        paddingBottom: "2em",
        paddingTop: "1em",
        }}
    >
        <IonIcon
        onClick={() => setShowModal(false)}
        size="large"
        style={{ position: "absolute", right: "0.5em" }}
        icon={close}
        ></IonIcon>
    </div>
    <div style={{ position: "relative", height: "100%" }}>
        <div style={{ position: "absolute", top: "0", width: "100%" }} className="p-5">
        <h1
            style={{ margin: "0 auto 0.5rem auto" }}
            className="text-gray-600 text-left uppercase whitespace-no-wrap font-semibold"
        >
            Please note
        </h1>
        <h1
            style={{ margin: "0 auto" }}
            className="text-gray-600 text-left whitespace-no-wrap"
        >
            The following users with the same name already exist on the system. You may proceed and create this user if you wish.
        </h1>
        <div className="h-8" />
        {users && Array.isArray(users) && users.map(u => (
            <div className="w-full flex h-10 items-center">
                {u.firstname} {u.lastname}
                <Link 
                    to={`/users/edit/${u.user_id}`}
                    className="justify-self-end ml-auto"
                >
                    <IonButton
                        // expand="full"
                        // onClick={resetFilters}
                        size="small"
                        tooltip="reset filters"
                    >
                    {/* <IonIcon icon={refreshOutline} /> */}
                    view
                    </IonButton>
                </Link>
            </div>
        ))}
        <div className="w-full flex mt-16">
            <IonButton
                color="danger"
                // expand="full"
                onClick={onCancelClick}
                // size="small"
                className="justify-self-end ml-auto"
                tooltip="reset filters"
            >
                {/* <IonIcon icon={refreshOutline} /> */}
                Cancel
            </IonButton>
            <IonButton
                // expand="full"
                onClick={onProceedClick}
                // size="small"
                className="justify-self-end ml-2"
                tooltip="reset filters"
            >
                {/* <IonIcon icon={refreshOutline} /> */}
                Proceed
            </IonButton>
        </div>
        </div>
    </div>
    </IonModal>
);
};

export default SearchUsersModal;
