import React, { useEffect, useState } from 'react';

import {IonButton} from '@ionic/react';
import axios from 'axios';
import mapboxgl from 'mapbox-gl';

import BaseTemplate from '../../components/base/BaseTemplate';
import { getCurrentEstate, getDocuments, isJsonString } from '../../utils';
import FileUpload from '../../components/FileUpload/FileUpload';
import Documents from '../../components/Documents/Documents';
import EstateMap from '../EstateMap/EstateMap';

import 'mapbox-gl/dist/mapbox-gl.css';
import './EstateInfo.css';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export default function EstateInfo() {
    const [estateInfo, setEstateInfo] = useState();
    const [mapCenter, setMapCenter] = useState();
    const [documents, setDocuments] = useState([]);
    const isAdmin = localStorage.getItem('inovio-isAdmin');

    useEffect(() => {
        if(localStorage.getItem('inovio-current-estate')) {
            setEstateInfo(JSON.parse(localStorage.getItem('inovio-current-estate')));
        } else {
            getCurrentEstate().then(current => {
                setEstateInfo(current[0]);
            });
        }

        fetchDocuments();

    }, []);

    function fetchDocuments() {
        getDocuments().then(res => {
            if(res && res.documents) {
                const docs = res.documents;

                const isValid = isJsonString(docs);

                const parsedDocs = JSON.parse(docs);

                if(isValid) {
                    const parsedDocsWithExtensions = parsedDocs.map((doc) => {

                        const extension = doc.file_name.split('.')[1];

                        return {
                            ...doc,
                            extension
                        };
                    });

                    if(parsedDocsWithExtensions && Array.isArray(parsedDocsWithExtensions) && parsedDocsWithExtensions.length > 0) setDocuments([...parsedDocsWithExtensions]);
                }
            }
        });
    }

    useEffect(() => {
        if(estateInfo && estateInfo.estates_address) {
            axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${estateInfo.estates_address}.json?types=address&access_token=pk.eyJ1IjoiY2hhZGRhbmtlciIsImEiOiJjazcxc2E1bnIwMmE0M21ud2oxOTdhNTlvIn0.vPS-2MWv9PRKcuRmmJG13w`)
            .then(res => { 
                setMapCenter(res.data.features[0].center);
                console.log(res.data.features);
                if(document.getElementById('map')) {
                    mapboxgl.accessToken = 'pk.eyJ1IjoiY2hhZGRhbmtlciIsImEiOiJjazcxc2E1bnIwMmE0M21ud2oxOTdhNTlvIn0.vPS-2MWv9PRKcuRmmJG13w';
                    var map = new mapboxgl.Map({
                        container: 'map', // container id
                        style: 'mapbox://styles/mapbox/light-v10', // style URL
                        center: res.data.features[0].center, // starting position [lng, lat]
                        zoom: 14 // starting zoom
                    });
    
                    map.on('load', () => {
                        map.resize();
                        new mapboxgl.Marker()
                        .setLngLat(res.data.features[0].center)
                        .addTo(map);
                    });
                }
            });
        }
    }, [estateInfo]);

    function share(info) {
        if (navigator.share) {
            navigator.share({
                text: info.estates_address
            })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
        }
    }

    return (
        <BaseTemplate>
            <div style={{height: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', position: 'relative', padding: '2em'}}>
                {estateInfo && estateInfo.estates_address && (
                    <>
                        <div className="text-gray-500 align-left text-xl uppercase font-semibold text-left mt-5">{estateInfo.estates_name}</div>

                        <div style={{marginBottom:'2em'}}>
                            {estateInfo.estates_address}
                        </div>
                        
                        {estateInfo && (estateInfo.estates_id !== 6) && <div id="map" className="map" />}
                        {estateInfo && (estateInfo.estates_id === 6) && <EstateMap onlyComponent />}
                        <IonButton style={{position: 'absolute', bottom: '-2em', right: '4em'}} onClick={_ => share(estateInfo)}>Share Address</IonButton>
                    </>
                )}
            </div>
            <div style={{height: '6em'}} />
            <h1 className="text-gray-500 align-middle text-l uppercase font-semibold text-left mt-5 ml-5">Documents</h1>
            <Documents documents={documents} />
            {isAdmin && <FileUpload fetchFiles={fetchDocuments} />}
        </BaseTemplate>
    )
}
