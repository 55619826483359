import React from 'react';
import BaseTemplate from '../../components/base/BaseTemplate';

export default function VisitorManagement() {
    return (
        <BaseTemplate>
            <div>Visitor Management</div>
        </BaseTemplate>
    )
}
