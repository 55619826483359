import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";
import BaseTemplate from "../../components/base/BaseTemplate";
import {
IonButton,
IonFab,
IonFabButton,
IonIcon,
IonGrid,
IonCol,
IonRow,
IonPopover,
IonList,
IonItem,
IonAlert,
} from "@ionic/react";
import { add } from "ionicons/icons";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { downloadOutline, peopleOutline } from "ionicons/icons";
import {
getUserUnits,
getUserUsers,
exportArrayToExcel,
checkToken,
} from "../../utils";
import Search from "../../components/Search/Search";
import Loading from "../../components/Loading";

const PER_PAGE = 15;

export default function Units() {
const [searchQuery, setSearchQuery] = useState("");
const [searchResults, setSearchResults] = useState([]);
const [units, setUnits] = useState([]);
const [users, setUsers] = useState([]);
const [loading, setLoading] = useState(true);
const [currentPage, setCurrentPage] = useState(0);
const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
});
const [showAlert, setShowAlert] = useState(false);
const [popoverUsers, setPopoverUsers] = useState([]);
const userTypes = JSON.parse(localStorage.getItem("inovio-estate-info"))
    .usertypes;

Array.prototype.naturalSort = function () {
    var a,
    b,
    a1,
    b1,
    rx = /(\d+)|(\D+)/g,
    rd = /\d+/;
    return this.sort(function (as, bs) {
    a = String(as.name).toLowerCase().match(rx);
    b = String(bs.name).toLowerCase().match(rx);
    while (a.length && b.length) {
        a1 = a.shift();
        b1 = b.shift();
        if (rd.test(a1) || rd.test(b1)) {
        if (!rd.test(a1)) return 1;
        if (!rd.test(b1)) return -1;
        if (a1 != b1) return a1 - b1;
        } else if (a1 != b1) return a1 > b1 ? 1 : -1;
    }
    return a.length - b.length;
    });
};

useEffect(() => {
    getUserUnits(627)
    .then((units) => {
        console.log(units);
        const sortedUnits = units.naturalSort();
        setUnits(sortedUnits);
        setLoading(false);
    })
    .catch((err) => {
        checkToken(localStorage.getItem("inovio-access-token"));
    });
    // if (localStorage.getItem("inovio-units")) {
    //   const units = JSON.parse(localStorage.getItem("inovio-units"))
    //   setUnits(units.sort((a, b) => parseInt(a.name) - parseInt(b.name)));
    //   setLoading(false);
    // } else {
    //   getUnits()
    //   .then((units) => {
    //     setUnits(units.sort((a, b) => parseInt(a.name) - parseInt(b.name)));
    //     setLoading(false);
    //   })
    //   .catch(err => {
    //     checkToken(localStorage.getItem('inovio-access-token'));
    //   });
    // }
    getUserUsers([627])
        .then((users) => {
        setUsers(users);
        })
        .catch((err) => {
        checkToken(localStorage.getItem("inovio-access-token"));
        });
}, []);

useEffect(() => {
    setCurrentPage(0);
    const unitsFuse = new Fuse(units, {
    keys: ["name"],
    });
    setSearchResults(unitsFuse.search(searchQuery));
}, [searchQuery, units]);

function handlePopoverClick(e, id) {
    getSelectedUsersForUnit(id);
    e.persist();
    setShowPopover({ showPopover: true, event: e });
}

function getSelectedUsersForUnit(unitId) {
    const unitsUsers = [];
    users.map((user) => {
    user.unit_id_arr.map((uId) => {
        if (unitId === uId) {
        unitsUsers.push(user);
        }
    });
    });

    setPopoverUsers(unitsUsers);
}

const revisedUnits =
    searchResults.length > 0 ? searchResults.map((item) => item.item) : units;

function handlePageClick({ selected: selectedPage }) {
    document.querySelector("#main-content").scrollToTop();
    setCurrentPage(selectedPage);
}

const offset = currentPage * PER_PAGE;

const currentPageData = revisedUnits.slice(offset, offset + PER_PAGE);

const pageCount = Math.ceil(revisedUnits.length / PER_PAGE);

return (
    <BaseTemplate>
    <Search
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        placeholder="Search Unit Names"
    />
    <IonGrid>
        <IonRow style={{ boxShadow: "none" }}>
        <IonCol size="10" offset="1">
            {loading && <Loading loading={loading} />}
            {!loading && (
            <div style={{ height: "auto" }} className="table-container">
                <table
                className="items-center w-full bg-transparent border-collapse"
                id="units-table"
                >
                <thead>
                    <tr>
                    <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                        Name
                    </th>
                    <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                        Address
                    </th>
                    <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                        Owner
                    </th>
                    <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                        Quantity
                    </th>
                    <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left"></th>
                    {/* <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left"></th> */}
                    <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                        <IonIcon
                        icon={downloadOutline}
                        style={{ cursor: "pointer", fontSize: 24 }}
                        className="text-gray-600 float-right"
                        onClick={() =>
                            exportArrayToExcel(revisedUnits, "units-data")
                        }
                        ></IonIcon>
                    </th>
                    </tr>
                </thead>
                <tbody>
                    {currentPageData.map((unit, i) => (
                    <tr key={i}>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left">
                        <Link
                            style={{ color: "black" }}
                            to={`/units/edit/${unit.id}`}
                        >
                            {unit.name}
                        </Link>
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left">
                        {unit.address}
                        </td>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left"></td>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left">
                        {unit.user_id_arr.length}
                        </td>
                        <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap px-1 py-4">
                        <IonButton
                            expand="full"
                            color="primary"
                            onClick={(e) => handlePopoverClick(e, unit.id)}
                        >
                            <IonIcon icon={peopleOutline}></IonIcon>
                        </IonButton>
                        </td>
                        <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap px-1 py-4">
                        <IonButton
                            expand="full"
                            color="warning"
                            routerLink={`/units/edit/${unit.id}`}
                        >
                            Edit
                        </IonButton>
                        </td>
                        {/* <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap px-1 py-4">
                        <IonButton expand="full" color="danger" onClick={() => setShowAlert(true)}>
                            Delete
                        </IonButton>
                        </td> */}
                    </tr>
                    ))}
                </tbody>
                </table>
                <div style={{ margin: "1em 1em" }}>
                <ReactPaginate
                    previousLabel={"←"}
                    nextLabel={"→"}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                />
                </div>
            </div>
            )}
            <IonFab
            vertical="bottom"
            horizontal="end"
            slot="fixed"
            style={{
                marginBottom: "1em",
                position: "fixed",
                marginRight: "1em",
            }}
            >
            <IonFabButton routerLink="/new-unit">
                <IonIcon icon={add}></IonIcon>
            </IonFabButton>
            </IonFab>
            <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            cssClass="my-custom-class"
            header={"Alert"}
            message={"Are you sure you want to delete this user?"}
            buttons={["Cancel", "Delete"]}
            />
        </IonCol>
        </IonRow>
    </IonGrid>
    <IonPopover
        cssClass="my-custom-class"
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() =>
        setShowPopover({ showPopover: false, event: undefined })
        }
    >
        {
        <IonList style={{ width: "100%" }}>
            {popoverUsers.map((user) => (
            <IonItem key={user.id} routerLink={`/users/edit/${user.user_id}`}>
                {user.firstname} {user.lastname}{" "}
                <span
                style={{
                    fontSize: "0.75em",
                    color: "grey",
                    textAlign: "left",
                    width: "50px",
                }}
                slot="end"
                >
                {userTypes.map((type) =>
                    user.usertype_id === type.id ? type.name : ""
                )}
                </span>
            </IonItem>
            ))}
            {/* <IonButton expand="full">Link New User</IonButton> */}
        </IonList>
        }
    </IonPopover>
    <IonFab
        vertical="bottom"
        horizontal="end"
        slot="fixed"
        style={{ marginBottom: "1em", position: "fixed", marginRight: "2em" }}
    >
        <IonFabButton routerLink="/new-unit">
        <IonIcon icon={add}></IonIcon>
        </IonFabButton>
    </IonFab>
    </BaseTemplate>
);
}
