import React, { useState } from "react";
import {
  IonPopover,
  IonButton,
  IonIcon,
  IonList,
  IonLabel,
  IonItem,
} from "@ionic/react";
import { useAuth } from "../../contexts/AuthContext";
import {
  logInOutline,
  logOutOutline,
  personCircleOutline,
  settingsOutline,
} from "ionicons/icons";
import { logout } from '../../utils';

import "./UserMenuPopover.css";

export default function UserMenuPopover({ popoverState, setShowPopover }) {
  const [error, setError] = useState("");

  async function handleLogout() {
    setError("");

    try {
      await logout();
    } catch {
      setError("Failed to log out");
    }
  }

  return (
    <>
      <IonPopover
        cssClass="user-menu-popover"
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() =>
          setShowPopover({ showPopover: false, event: undefined })
        }
      >
        {/* <IonList style={{ width: '100%' }}>
          <IonItem routerLink="update-profile">
              <IonIcon style={{marginRight: '0.5em'}} icon={personCircleOutline}></IonIcon>
              <IonLabel>Profile</IonLabel>
          </IonItem>
          <IonItem routerLink="settings">
              <IonIcon style={{marginRight: '0.5em'}} icon={settingsOutline}></IonIcon>
              <IonLabel>Settings</IonLabel>
          </IonItem>
        </IonList> */}
        {!(localStorage.getItem("inovio-current-user") !== "false") ? (
          <IonButton expand="block" routerLink="/login">
            login
            <IonIcon
              style={{ marginLeft: "0.15em" }}
              icon={logInOutline}
            ></IonIcon>
          </IonButton>
        ) : (
          <IonButton
            expand="block"
            onClick={handleLogout}
            className="align-middle text-xs uppercase whitespace-no-wrap font-semibold text-left"
          >
            logout
            <IonIcon style={{ marginLeft: "0.15em" }} icon={logOutOutline} />
          </IonButton>
        )}
      </IonPopover>
    </>
  );
}
