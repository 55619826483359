import React, { useRef, useState } from 'react';
import { IonCard, IonText, IonButton, IonCardContent, IonItem, IonInput, IonLabel, IonGrid, IonRow, IonCol, IonAlert } from '@ionic/react';
import { useAuth } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom'; 

import {resetPassword, parseQueryString} from '../../utils';

import './ResetPassword.css';

export default function ResetPassword() {
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();

    // const { resetPassword } = useAuth();
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    async function handleSubmit(e) {
        e.preventDefault();

        const pageQueries = parseQueryString();

        if(!pageQueries || (typeof pageQueries !== 'object')) return;

        try {
            setMessage('');
            setError('');
            setLoading(true);

            if(!passwordRef.current.value) {
                setError('Please enter your new password');

                return;
            }

            if(!confirmPasswordRef.current.value) {
                setError('Please confirm your new password');

                return;
            }

            if(passwordRef.current.value !== confirmPasswordRef.current.value) {
                setError('The password you entered does not match the confirmation password');

                return;
            }

            if(pageQueries.token && pageQueries.id) {
                await resetPassword(pageQueries.id, pageQueries.token, passwordRef.current.value, confirmPasswordRef.current.value);

                setMessage('Password changed successfully');

                setIsSuccess(true);

                return;
            }

            setError('Something went wrong');

        } catch {
            setError('Something went wrong');
        }
        setLoading(false);
        
    }

    return (
        <div className="forgot-password">
            <IonGrid>
                <IonRow>
                    <IonCol offset-md="3" size-md="6">
                        <IonCard>
                            {!isSuccess ? (
                                <IonCardContent>
                                    <h1 style={{marginBottom: '2em'}}>Reset Password</h1>
                                    <form onSubmit={handleSubmit}>
                                        <IonItem>
                                            <IonLabel position="floating">
                                                Password
                                            </IonLabel>
                                            <IonInput type="password" required ref={passwordRef}>
                                            </IonInput>
                                        </IonItem>
                                        <IonItem>
                                            <IonLabel position="floating">
                                                Confirm Password
                                            </IonLabel>
                                            <IonInput type="password" required ref={confirmPasswordRef}>
                                            </IonInput>
                                        </IonItem>
                                        <IonButton type="submit" expand="block" style={{marginTop: '7em'}} disabled={loading}>
                                            Reset Password
                                        </IonButton>
                                    </form>
                                </IonCardContent> 
                            ) : (
                                <IonCardContent>
                                    <h1>Password Changed Successfully</h1>
                                    <div className="p-8">
                                        <IonButton routerLink="/login" expand="block" style={{marginTop: '2em'}}>
                                            Login Now
                                        </IonButton>
                                    </div>

                                </IonCardContent> 
                            )}
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonAlert
                isOpen={error !== ''}
                onDidDismiss={() => setError('')}
                header={'Error'}
                message={error}
                buttons={['OK']}
            />
            <IonAlert
                isOpen={message !== ''}
                onDidDismiss={() => setMessage('')}
                header={'Success'}
                message={message}
                buttons={['OK']}
            />
        </div>
    )
}
