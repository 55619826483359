import React, { useState } from "react";
import { IonItem, IonGrid, IonCol, IonList } from "@ionic/react";
import Scan from "./Scan";

export default function EnrollmentForm({showModal, setShowModal, setFingerprints, fingerprints, email, userId, codes, setCodes, saveCodes}) {
  const [selectedReader, setSelectedReader] = useState();
  const [fingerIndex, setFingerIndex] = useState(1);
  const [scanning, setScanning] = useState(false);
  const [scanIndex, setScanIndex] = useState(1);

  function handleReaderSubmit(e) {
    e.preventDefault();
  }

  return (
    <div
      className="px-10 pt-0 pb-2"
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "3em",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "60%", margin: "4em auto" }} className="shadow-xl">
        <h1
          style={{ textAlign: "center", margin: "0 auto" }}
          className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold"
        >
          {!scanning &&
            (selectedReader ? `Scan Finger ${fingerIndex}` : "Select Reader")}
          {scanning && `Scanning Finger ${fingerIndex}`}
        </h1>
        <form
          onSubmit={handleReaderSubmit}
          style={{ width: "100%", textAlign: "center" }}
        >
          {!selectedReader && (
            <IonList style={{ margin: "1em auto 2em auto" }}>
              {JSON.parse(localStorage.getItem('inovio-fingerprint-session')).readers.map((reader) => (
                <IonItem
                  key={reader.readerId}
                  onClick={() => setSelectedReader(reader)}
                  style={{ cursor: "pointer" }}
                  button={true}
                >
                  {reader.name}
                </IonItem>
              ))}
            </IonList>
          )}
          {selectedReader && (
            <Scan
              fingerIndex={fingerIndex}
              setFingerIndex={setFingerIndex}
              scanning={scanning}
              setScanning={setScanning}
              scanIndex={scanIndex}
              setScanIndex={setScanIndex}
              showModal={showModal}
              setShowModal={setShowModal}
              fingerprints={fingerprints}
              setFingerprints={setFingerprints}
              selectedReader={selectedReader}
              email={email}
              userId={userId}
              codes={codes}
              setCodes={setCodes}
              saveCodes={saveCodes}
            />
          )}
        </form>
      </div>
    </div>
  );
}
