    import React, { useState, useEffect } from "react";
    import { IonButton, IonAlert, IonIcon } from "@ionic/react";
    import { downloadOutline } from "ionicons/icons";
    import Modal from '../Modal';
    import { Link } from "react-router-dom";
    import UserLogs from '../UserLogs/UserLogs';

    import "./UserPortalUsersList.css";

    export default function UserPortalUsersList({
        items,
        getUnitsForUser,
        setShowPopover,
        exportToExcel,
        getUnitsNamesForUser,
    }) {
    const [showAlert, setShowAlert] = useState(false);
    const userTypes = JSON.parse(localStorage.getItem('inovio-estate-info')).usertypes;
    const [formattedUserTypes, setFormattedUserTypes] = useState();
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [showLogsModal, setShowLogsModal] = useState(false);

    useEffect(() => {
        let tempTypes = {};
        userTypes.map(type => {
        tempTypes[type.id] = type.name;
        });

        setFormattedUserTypes(tempTypes);
    }, []);

    function handleUnitsClick(e, id) {
        getUnitsForUser(id);
        e.persist();
        setShowPopover({ showPopover: true, event: e });
    }
    function openLogsModal(userId) {
        setSelectedUserId(userId);
        setShowLogsModal(true);
    }
    
    function renderList(collection, title) {
        return (
        <div className="table-container">
            {collection && (
            <table
            className="items-center w-full bg-transparent border-collapse"
            id="users-table"
            >
            <thead>
                <tr>
                    <th className="px-2 pl-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                        Name
                    </th>
                    <th className="px-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                        Last Name
                    </th>
                    <th className="px-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                    </th>
                </tr>
            </thead>
            <tbody>
                {collection.map((item, i) => {
                return (
                    <tr key={i}>
                    <th className="border-t-0 px-2 pl-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left">
                        <Link
                        to={`/users/edit/${item.user_id}`}
                        style={{ color: "black" }}
                        >
                        {item.firstname}
                        </Link>
                    </th>
                    <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                        {item.lastname}
                    </td>
                    <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap px-1 py-4">
                        <IonButton
                        routerLink={`/users/edit/${item.user_id}`}
                        expand="full"
                        style={{ width: "100%" }}
                        color="warning"
                        >
                        Edit
                        </IonButton>
                    </td>
                    {/* <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap px-1 py-4">
                        <IonButton
                        expand="full"
                        style={{ width: "100%" }}
                        color="danger"
                        onClick={() => setShowAlert(true)}
                        >
                        Delete
                        </IonButton>
                    </td> */}
                    </tr>
                );
                })}
            </tbody>
            </table>)}
            <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            cssClass="my-custom-class"
            header={"Alert"}
            message={"Are you sure you want to delete this user?"}
            buttons={["Cancel", "Delete"]}
            />
            <Modal showModal={showLogsModal} setShowModal={setShowLogsModal}>
                <UserLogs userId={selectedUserId} />
            </Modal>
        </div>
        );
    }

    return (
        <div className="items-list" style={{ textAlign: "center" }}>
        {renderList(items, "Users")}
        </div>
    );
    }
