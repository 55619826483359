import React, { useState, useEffect } from 'react';
import {
    homeOutline,
    fileTrayFullOutline,
    listCircleOutline,
    peopleOutline,
    informationOutline,
    alertCircleOutline,
    personAddOutline,
    addCircleOutline,
    alertOutline,
    addOutline,
    arrowForwardOutline,
    keyOutline
} from "ionicons/icons";
import BaseTemplate from "./base/BaseTemplate";
import { IonIcon, IonFab, IonFabButton, IonButton } from "@ionic/react";
import Modal from './Modal';
import UserLastSeen from "./UserLastSeen";
import { getUserInfo, getUnitInfo } from '../utils/index';

const sections = [
    {
        name: "Add Visitor",
        icon: personAddOutline,
        link: "/new-visitor"
    },
    {
        name: "Unit Logs",
        icon: fileTrayFullOutline,
        link: "/logs/164"
    },
    {
        name: "My Users",
        icon: peopleOutline,
        link: "/my-users"
    },
    {
        name: "Visitor Codes",
        icon: keyOutline,
        link: "/my-codes"
    },
];

export default function UserDashboard() {

    const [modalUser, setModalUser] = useState();
    const [showModal, setShowModal] = useState(false);

    function handleUserClick(user) {
        setModalUser(user);
        setShowModal(true);
    }

    useEffect(() => {
        const currentUser = JSON.parse(localStorage.getItem('inovio-current-user'));
        let userInfo = {};
        if(currentUser) {
            const { id } = currentUser;
            
            getUserInfo(id).then(res => {
                userInfo['info'] = res.user;
                userInfo['codes'] = res.codes;
                userInfo['units'] = {
                    ids: res.units
                };
                localStorage.setItem('inovio-user-info', JSON.stringify(res));
                if(res.units.length > 0) {
                    res.units.map(unitId => {
                        getUnitInfo(unitId).then(res => {
                            userInfo['units'][unitId] = res;
                            localStorage.setItem('inovio-user-info', JSON.stringify(userInfo));
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    });
                }
            });

            window.OneSignal.push(function() {
                const { email } = JSON.parse(localStorage.getItem("inovio-current-user"));
                window.OneSignal.setEmail(email)          
                    .then(function(emailId) {
                        // Callback called when email have finished sending
                        console.log("emailId: ", emailId);  
                    }); 
            });
        }
    }, []);

    return (
        <BaseTemplate>
            <div className="flex flex-wrap" style={{ marginTop: "3em" }}>
                {sections &&
                sections.map((type, i) => (
                    <div className="w-full lg:w-6/12 xl:w-3/12 px-4" key={i}>
                    <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                        <div
                        className="flex-auto p-4"
                        style={{
                            borderLeft: `4px solid var(--ion-color-primary)`,
                            cursor: "pointer",
                        }}
                        >
                        <div className="flex flex-wrap">
                            <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                                <h5 className="text-gray-500 uppercase font-bold text-xs pb-2">
                                    {type.name}
                                </h5>
                                <span className="font-semibold text-xl text-gray-800">
                                    <IonIcon size="large" icon={type.icon} />
                                </span>
                                <IonButton routerLink={type.link} style={{float: 'right'}}>
                                    <IonIcon icon={arrowForwardOutline} />
                                </IonButton>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                ))}
            </div>

            {/* {modalUser && 
                <Modal showModal={showModal} setShowModal={setShowModal}>
                    <UserLastSeen user={modalUser} />
                </Modal>
            } */}
        </BaseTemplate>
    )
}
