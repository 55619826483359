import jwtDecode from 'jwt-decode';
import { inovioApi, inovioVmsHelper } from '../apis/inovio';
import XLSX from 'xlsx';
import md5 from 'md5';
import axios from 'axios';

let _AT = "";
let refreshready = true;

export function isLoggedIn() {
    if(localStorage.getItem('inovio-current-user')) {
        return true
    }

    return false
}

export async function checkIfTokenExpired(token) {
    const decodedToken = jwtDecode(token)
    if(new Date(decodedToken.exp * 1000) < new Date()) {
        await refresh(localStorage.getItem('inovio-refresh-token'))
        return true
    }

    return false
}

export function exportTableToExcel(tableID, filename = ''){
    var downloadLink;
    var dataType = 'application/vnd.ms-excel';
    var tableSelect = document.getElementById(tableID);
    var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
    
    // Specify file name
    filename = filename?filename+'.xls':'excel_data.xls';
    
    // Create download link element
    downloadLink = document.createElement("a");
    
    document.body.appendChild(downloadLink);
    
    if(navigator.msSaveOrOpenBlob){
        var blob = new Blob(['\ufeff', tableHTML], {
            type: dataType
        });
        navigator.msSaveOrOpenBlob( blob, filename);
    }else{
        // Create a link to the file
        downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
    
        // Setting the file name
        downloadLink.download = filename;
        
        //triggering the function
        downloadLink.click();
    }
}

export function exportArrayToExcel(array, name) {

    let arrayWS = XLSX.utils.json_to_sheet(array); 
  
    // Create a new Workbook
    var wb = XLSX.utils.book_new() ;
  
    // Name your sheet
    XLSX.utils.book_append_sheet(wb, arrayWS, `${name}`);
  
    // export your excel
    XLSX.writeFile(wb, `${name}.xlsx`);
}

export function browserType() {
    window.navigator.sayswho = (function(){
        var ua= window.navigator.userAgent, tem, 
        M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if(/trident/i.test(M[1])){
            tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE '+(tem[1] || '');
        }
        if(M[1]=== 'Chrome'){
            tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
            if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        M= M[2]? [M[1], M[2]]: [window.navigator.appName, window.navigator.appVersion, '-?'];
        if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
        return M.join(' ');
    })();
    
    return window.navigator.sayswho;
}

export async function getCurrentEstate() {
    //let accessToken = localStorage.getItem('inovio-access-token');
    const path = '/me/estatemanager';

    const res = await inovioApiCheck('GET', path);
    console.log(res);
    if(res.data[0] && !res.data[0].estates_id) {
        localStorage.setItem('inovio-current-estate', JSON.stringify({estates_id: res.data[0]}));
        localStorage.setItem('inovio-isAdmin', JSON.stringify(false));
    } else {
        if(res.data[0]) {
            localStorage.setItem('inovio-current-estate', JSON.stringify(res.data[0]));
            localStorage.setItem('inovio-isAdmin', JSON.stringify(true));
        } else {
            localStorage.setItem('inovio-current-estate', JSON.stringify({estates_id: 1}));
            localStorage.setItem('inovio-isAdmin', JSON.stringify(false));
        }
        localStorage.setItem('inovio-estates', JSON.stringify(res.data));
        
    }

    if(res.data) {
        return res.data;
    }
}

export async function getUsers() {
    if(!localStorage.getItem('inovio-current-estate')) {
        window.location.href = "/login";
        return;
    }
    const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
    const path = `/estate/${ESTATE_ID}/users`;

    const res = await inovioApiCheck('GET', path);

    localStorage.setItem('inovio-users', JSON.stringify(res.data))

    if(res.data) {
        return res.data;
    }
}

export async function getUsersFresh() {
    if(!localStorage.getItem('inovio-current-estate')) {
        window.location.href = "/login";
        return;
    }
    const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
    const path = `/estate/${ESTATE_ID}/users`;

    const res = await inovioApi.get(path, { headers: { Authorization: `Bearer ${_AT}` } });

    localStorage.setItem('inovio-users', JSON.stringify(res.data))

    if(res.data) {
        return res.data;
    }
}

export async function codeGet(body) {
    if(!localStorage.getItem('inovio-current-estate')) {
        window.location.href = "/login";
        return;
    }
    const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
    const path = `/visitor/${ESTATE_ID}/codeget`;

    const res = await inovioApiCheck('POST', path, body);

    if(res.data) {
        return res.data;
    }
}

export async function userRelationshipAdd() {
    if(!localStorage.getItem('inovio-current-estate')) {
        window.location.href = "/login";
        return;
    }
    // {
    //     unit_id;
    //     owner_id
    //     user_id
    //     relationship_id
    // }
    const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
    const path = `/user/relationshipadd`;

    const res = await inovioApiCheck('GET', path);

    localStorage.setItem('inovio-users', JSON.stringify(res.data))

    if(res.data) {
        return res.data;
    }
}

export async function getUnits() {
    if(!localStorage.getItem('inovio-current-estate')) {
        window.location.href = "/login";
        return;
    }
    const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
    const path = `/estate/${ESTATE_ID}/units`;

    const res = await inovioApiCheck('GET', path);

    localStorage.setItem('inovio-units', JSON.stringify(res.data))
    if(res.data) {
        return res.data;
    }

}

export async function getVisitorInfo(userId, unitId) {
    if(!localStorage.getItem('inovio-current-estate')) {
        window.location.href = "/login";
        return;
    }
    const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
    const body = {
        unitId, 
        userId
    };
    const path =  `/visitor/${ESTATE_ID}/getvisitorinfo`;

    const res = await inovioApiCheck('POST', path, body);
    if(res.data) {
        return res.data;
    }   
}

export const getInfo = async () => {
    //let accessToken = localStorage.getItem('inovio-access-token');
    if(!localStorage.getItem('inovio-current-estate')) {
        window.location.href = "/login";
        return;
    }
    const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
    console.log(ESTATE_ID);
    const path = `/estate/${ESTATE_ID}/info`;

    const res = await inovioApiCheck('GET', path);

    localStorage.setItem('inovio-estate-info', JSON.stringify(res.data));
    if(res.data) {
        return res.data;
    }

}

export async function getUserInfo(id) {
    // let accessToken = localStorage.getItem('inovio-access-token');
    
    const path = `/estate/userinfo/${id}`;

    const res = await inovioApiCheck('GET', path);

    if(res.data) {
        return res.data;
    }
}

export async function getUnitInfo(id) {
    if(!localStorage.getItem('inovio-current-estate')) {
        window.location.href = "/login";
        return;
    }
    const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
    const path = `/estate/${ESTATE_ID}/unitinfo/${id}`;

    const res = await inovioApiCheck('GET', path);

    if(res.data) {
        return res.data;
    }
}

export async function getAccess(from, to, limit) {
    if(!localStorage.getItem('inovio-current-estate')) {
        window.location.href = "/login";
        return;
    }
    const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
    const body = {
        limit,
        from, 
        to,
        estate_id: ESTATE_ID
    };
    const path =  `/log/access`;

    const res = await inovioApiCheck('POST', path, body);
    if(res.data) {
        return res.data;
    }
}

// entry/exit logs per unit
export async function getUnitAccess(from, to, unitId) {
    if(!localStorage.getItem('inovio-current-estate')) {
        window.location.href = "/login";
        return;
    }
    const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
    const body = {
        from, 
        to,
        estate_id: ESTATE_ID
    };
    const path =  `/log/access/${unitId}`;

    const res = await inovioApiCheck('POST', path, body);
    if(res.data) {
        return res.data;
    }
}

// entry/exit logs per user
export async function getUserAccess(userId, limit) {
    if(!localStorage.getItem('inovio-current-estate')) {
        window.location.href = "/login";
        return;
    }
    const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
    const body = {
        limit,
        user_id: userId,
        estate_id: ESTATE_ID
    };
    const path =  `/log/useraccess`;

    const res = await inovioApiCheck('POST', path, body);
    if(res.data) {
        return res.data;
    }
}

export async function getUsersIn() {
    if(!localStorage.getItem('inovio-current-estate')) {
        window.location.href = "/login";
        return;
    }
    const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
    const path = `/report/usersin/${ESTATE_ID}/7`;

    const res = await inovioApiCheck('GET', path);

    if(res.data) {
        return res.data;
    }
}

export async function getBusyDays() {
    if(!localStorage.getItem('inovio-current-estate')) {
        window.location.href = "/login";
        return;
    }
    const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
    const path = `/report/busydays/${ESTATE_ID}/7`;

    const res = await inovioApiCheck('GET', path);

    if(res.data) {
        return res.data;
    }   
}

export async function getBusyUserTypes() {
    if(!localStorage.getItem('inovio-current-estate')) {
        window.location.href = "/login";
        return;
    }
    const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
    const path = `/report/busyusertypes/${ESTATE_ID}/7`;

    const res = await inovioApiCheck('GET', path);

    if(res.data) {
        return res.data;
    }   
}

export async function getDenieds() {
    if(!localStorage.getItem('inovio-current-estate')) {
        window.location.href = "/login";
        return;
    }
    const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
    const path = `/report/denieds/${ESTATE_ID}/7`;

    const res = await inovioApiCheck('GET', path);

    if(res.data) {
        return res.data;   
    }
}

export async function getFingerprintSession() {
    if(!localStorage.getItem('inovio-current-estate')) {
        window.location.href = "/login";
        return;
    }
    const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
    const body = {
        estateId: ESTATE_ID
    };
    const path =  `/enroll/biostar/fingerprint`;

    const res = await inovioApiCheck('POST', path, body);
    console.log(res);

    if(res.data) {
        return res.data;
    }
}

export async function scanFinger(finger, readerId) {
    //let accessToken = localStorage.getItem('inovio-access-token');
    const body = {
        sessionId: JSON.parse(localStorage.getItem('inovio-fingerprint-session')).sessionId,
        readerId,
        action: "read",
        finger: parseInt(finger)
    };
    const path =  `/enroll/biostar/fingerprint`;

    const res = await inovioApiCheck('POST', path, body);
    console.log(res);
    if(res.data) {
        return res.data;
    }
}

export async function reassignCode(userId, userIdAssign, code, unitId) {
    if(!localStorage.getItem('inovio-current-estate')) {
        window.location.href = "/login";
        return;
    }
    const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
    const body = {
        userId,
        userIdAssign,
        code,
        unitId,
        estateId: ESTATE_ID
    };
    const path =  `/user/usercodereassign`;

    const res = await inovioApiCheck('POST', path, body);
    if(res.data) {
        return res.data;
    }
}

export async function getRelationships() {
    const path =  `/user/relationships`;

    const res = await inovioApiCheck('GET', path);

    console.log('RELATIONSHIPS', res);

    if(res.data) {
        return res.data;
    }
} 

export async function addRelationship(unitId, ownerId, userId, relationshipId) {
    const body = {
        unit_id: unitId,
        owner_id: relationshipId === 3 ? ownerId : undefined,
        user_id: userId,
        relationship_id: relationshipId
    };

    const path =  `/user/relationshipadd`;

    const res = await inovioApiCheck('POST', path, body);
    if(res.data) {
        return res.data;
    } else if(res) {
        return res;
    }
}

export async function saveFingerPrint(email, userId) {
    //let accessToken = localStorage.getItem('inovio-access-token');
    const body = {
        sessionId: JSON.parse(localStorage.getItem('inovio-fingerprint-session')).sessionId,
        action: "save",
        email, 
        userId,
    };
    const path =  `/enroll/biostar/fingerprint`;

    const res = await inovioApiCheck('POST', path, body);
    console.log(res);

    if(res.data) {
        return res.data;
    }
}

export async function getAcrs() {
    if(!localStorage.getItem('inovio-current-estate')) {
        window.location.href = "/login";
        return;
    }
    const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
    const path = `/estate/${ESTATE_ID}/acrs`;

    const res = await inovioApiCheck('GET', path);

    console.log(res);

    if(res.data) {
        return res.data;
    }
    //estates/estateid/acrs
}

export async function deleteUserCode(userId, code) {
    if(!localStorage.getItem('inovio-current-estate')) {
        window.location.href = "/login";
        return;
    }
    const estateId = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;

    const body = {
        userId, 
        code, 
        estateId
    };

    const path = '/user/usercodedelete';

    const res = await inovioApiCheck('POST', path, body);

    if(res.data) {
        return res.data;
    }
}

export async function getUserUnits(id) {
    if(!localStorage.getItem('inovio-current-estate')) {
        window.location.href = "/login";
        return;
    }
    const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
    const path = `/estate/${ESTATE_ID}/units`;

    const res = await inovioApiCheck('GET', path);

    const units = [];
    res.data.map(unit => {
        unit.user_id_arr.map(userId => {
            if(id === userId) {
                units.push(unit);
            }
        })
    });

    return units;   
}

export async function getUserUsers(userIds) {
    let users = [];

    userIds.map(id => {
        getUserInfo(id).then(data => {
            console.log(data);
            if(data.user) {
                users.push(data.user[0]);
            }
        });
    });

    console.log(users);

    return users;   
}

export async function checkToken(token) {
    if(token) {
        checkIfTokenExpired(token)
    }
} 

function refresh() {
    refreshready=false;
    
    return new Promise(async (resolve, reject) => {
        let refreshToken = localStorage.getItem('inovio-refresh-token');
        inovioApi.post('/auth/token', {
            token: refreshToken
        },
        ).then(res => {
            console.log('refresh API response: ', res.data)
            //inovioApi.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`;
            _AT = res.data.accessToken
            //localStorage.removeItem('inovio-units')
            //localStorage.removeItem('inovio-users')
            localStorage.setItem('inovio-access-token', res.data.accessToken)
            refreshready=true;
            return resolve (res.data.accessToken)
        })
        .catch(async error => {
            console.log('refresh_error',error);
            // await inovioApi.delete('/auth/logout', {
            //     token: refreshToken
            // });
            localStorage.removeItem('inovio-access-token');
            localStorage.removeItem('inovio-refresh-token');
            localStorage.removeItem('inovio-current-user');
            //localStorage.removeItem('inovio-units')
            //localStorage.removeItem('inovio-users')
            refreshready=true;
            
            reload();
            if(window.location.pathname !== '/login') {
                logout();
            } 
            return resolve (false);
        });
    });
}

function reload() {
    localStorage.removeItem('inovio-access-token')
    localStorage.removeItem('inovio-refresh-token')
    localStorage.removeItem('inovio-current-user')
    localStorage.removeItem('inovio-units')
    localStorage.removeItem('inovio-users')
    localStorage.removeItem('inovio-current-estate')
    localStorage.removeItem('inovio-estate-info')
    window.location.reload();
}

function getCache(type,url,body) {
    let name = body ? md5(type + url + body) : md5(type + url) 
    let key = 'CD_'+name
    let expiry = localStorage.getItem('CE_' + name)
    if (!expiry) return false;
    let timeout = getTimeout(type,url);

    const now = new Date();
    if (now - expiry < timeout) {
        return localStorage.getItem('CD_' + name)
    } else {
        return false;
    }
}

function saveCache(type,url,body,result) {
    let timeout = getTimeout(type,url);
    if (timeout == 0) return false;
    
    let name = body ? md5(type + url + body) : md5(type + url)
    let key = 'CD_'+name
    const now = new Date()
    localStorage.setItem('CE_'+name,now)
    localStorage.setItem('CD_'+name,JSON.stringify(result))
}

function getTimeout(type,url) {
    if (type.toUpperCase()!='GET') return 0;
    if (url.includes('/estate') && url.includes('/user') ) return 1000*30;
    if (url.includes('/users')) return 0;
    if (url.includes('/units')) return 1000*30;
    if (url.includes('/report')) return 1000*30;
    if (url.includes('/login')) return 0;

    return 0;
}

export async function inovioApiCheck(type, url, body) {
    //if (`Bearer ${_AT}` !== inovioApi.defaults.headers.common['Authorization']) console.log('E1',`Bearer ${_AT}`,inovioApi.defaults.headers.common['Authorization'])
    //if (_AT !== localStorage.getItem('inovio-access-token')) console.log('E2',_AT,localStorage.getItem('inovio-access-token'))
    //let accessToken = localStorage.getItem('inovio-access-token');
    //inovioApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    //if (typeof inovioApi.defaults.headers.common['Authorization'] === 'undefined') inovioApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('inovio-access-token')}`;
    if (_AT.length<=0) _AT = await refresh();
    return new Promise(async (resolve, reject) => {
        
        type = type.toUpperCase();

        let cache = getCache(type,url,body)
        if (cache) return resolve(cache);

        let looped=0;
        while (true) {
            looped++;
            let result;
            try {
                console.log(inovioApi.defaults.headers.common)
                if (type=='GET') {
                    result = await inovioApi.get(url,{ headers: { Authorization: `Bearer ${_AT}` } });
                    saveCache(type,url,null,result)
                    return resolve (result)
                } else if (type== 'POST') {
                    result = await inovioApi.post(url, body,{ headers: { Authorization: `Bearer ${_AT}` } });
                    saveCache(type,url,body,result)
                    return resolve (result)
                } else if(type === 'DELETE') {
                    result = await inovioApi.delete(url, body,{ headers: { Authorization: `Bearer ${_AT}` } });
                    // saveCache(type,url,null,result)
                    return resolve (result)
                } else {
                    await logout();
                    return resolve(false);
                }
            } catch (e) {
                if (typeof e.response==='undefined') {
                    console.log(e)
                    await logout();
                    return resolve(false)
                }
                if ([400,401,402,403].includes(e.response.status)) {
                    //console.log(looped, 'Refresh', e.response.status)
                    console.log('!!400',{
                        loop:looped,
                        resp:e.response.status,
                        head:inovioApi.defaults.headers.common,
                        at:_AT
                    })
                    while (!refreshready) {}
                    await refresh()
                } else {
                    await logout();
                    console.log("UNHANDLED STATUS",e.response.status);
                }
            }
            
            if (looped>=4) {
                cache = getCache((type,url,body));
                console.log(cache);
                if (cache) return resolve (cache);
                return resolve(false) 
            }
        }
    })
}

export async function logout() {
    const path = '/auth/logout';
    const body = {
        token: _AT
    };
    const res = await inovioApiCheck('DELETE', path, body);
    localStorage.clear();
    window.location.href = "/login";
    return true;
}

export async function handleVMSApprove(decison, userId, visitorName, reason) {
    const res = inovioVmsHelper.post('/approvals', {
        approved: decison,
        userId,
        visitorName,
        reason: reason ? reason : 'none - approved',
        createdAt: new Date()
    });

    console.log(res);
}

export async function morphoInitiateSession(estateId) {
    const body = {
        estateId
    };

    const res = await inovioApiCheck('POST', '/enroll/morpho/fingerprint', body);

    if(typeof res === 'string') {
        return res;
    } else if(typeof res === 'object' && res.data) {
        return res.data;
    }

    console.log(res);
}

export async function morphoFollowup(body) {
    const formBody = {
        "action":"startSession",
        ...body
    };

    const res = await inovioApiCheck('POST', '/enroll/morpho/fingerprint', formBody);

    if(typeof res === 'string') {
        return res;
    } else if(typeof res === 'object' && res.data) {
        return res.data;
    }

    console.log(res);
}

// # 2nd
// POST {{url}}/enroll/morpho/fingerprint
// Authorization: Bearer {{token}}
// Content-Type: application/json

// {
//     "action":"startSession",
//     "sessionId": "27784abe9ae87f80905e7222c70f9f32",
//     "firstName":"chad",
//     "lastName":"chad",
//     "estateId":6,
//     "morphoId":3,
//     "hands":2,
//     "userId":"1099",
//     "leftFingers":[0,0,0,0],
//     "rightFingers":[0,0,0,0]
// }

// # hands
// # 0 = left
// # 1 = right
// # 2 = both

// # fingers
// # 0=good
// # 1=bandaged
// # 2=amputated

// # 3rd poll
// GET {{url}}/enroll/morpho/fingerprint/poll/fae374754e16a82f6d28ce27784abe9a
// Authorization: Bearer {{token}}

export async function morphoPoll(sessionId) {
    const res = await inovioApiCheck('GET', `/enroll/morpho/fingerprint/poll/${sessionId}`);

    if(typeof res === 'string') {
        return res;
    } else if(typeof res === 'object' && res.data) {
        return res.data;
    }

    console.log(res);
}

// BOKKINGS

// estate/getbookingconfiguration/:estateId

export async function getBookingConfig() {

    const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;

    const res = await inovioApiCheck('GET', `/estate/getbookingconfiguration/${ESTATE_ID}`);

    if(typeof res === 'string') {
        return res;
    } else if(typeof res === 'object' && res.data) {
        return res.data;
    }
}

// estate/getbookingconfigurationfiles/:estateId

export async function getBookingConfigFiles() {

    const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;

    const res = await inovioApiCheck('GET', `/estate/getbookingconfigurationfiles/${ESTATE_ID}`);

    if(typeof res === 'string') {
        return res;
    } else if(typeof res === 'object' && res.data) {
        return res.data;
    }
}

// estate/getbooking/:estateId/:bookingId

export async function getBooking(bookingId) {

    const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;

    const res = await inovioApiCheck('GET', `/estate/getbooking/${ESTATE_ID}/${bookingId}`);

    if(typeof res === 'string') {
        return res;
    } else if(typeof res === 'object' && res.data) {
        return res.data;
    }
}

// estate/checkbookingavailability/:estateId
// {dateFrom,dateTo,groupSize}

export async function checkBookingAvailability(dateFrom, dateTo, groupSize, bookingVenueId) {

    const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;

    const body = {
        dateFrom,
        dateTo,
        groupSize,
        bookingVenueId
    };

    const res = await inovioApiCheck('POST', `/estate/checkbookingavailability/${ESTATE_ID}`, body);

    if(typeof res === 'boolean') {
        return res;
    } else if(typeof res === 'object' && res.data) {
        return res.data;
    }
}

export async function addBooking({estateId,bookingVenueId,date,duration,contactName,contactNumber,contactEmail,bookedOut,groupSize}) {

    const body = {
        estateId,
        bookingVenueId,
        date: new Date(date),
        duration,
        contactName,
        contactNumber,
        contactEmail,
        bookedOut,
        groupSize
    };

    const res = await inovioApiCheck('POST', `/estate/addbooking`, body);

    if(typeof res === 'string') {
        return res;
    } else if(typeof res === 'object' && res.data) {
        return res.data;
    }
}

// estate/updatebooking
// {estateId,bookingId,date,duration,contactName,contactNumber,contactEmail,bookedOut,groupSize,id}

export async function updateBooking({estateId,bookingVenueId,date,duration,contactName,contactNumber,contactEmail,bookedOut,groupSize,id, bookingStatus}) {

    const body = {
        estateId,
        bookingVenueId,
        date,
        duration,
        contactName,
        contactNumber,
        contactEmail,
        bookedOut,
        groupSize,
        id,
        bookingStatus
    };

    const res = await inovioApiCheck('POST', `/estate/updatebooking`, body);

    if(typeof res === 'string') {
        return res;
    } else if(typeof res === 'object' && res.data) {
        return res.data;
    }
}

export async function getBookings(dateFrom, dateTo) {
    const estateId = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;

    const body = {
        dateFrom, 
        dateTo,
        estateId
    };

    const res = await inovioApiCheck('POST', `/estate/getbookings`, body);

    if(typeof res === 'string') {
        return res;
    } else if(typeof res === 'object' && res.data) {
        return res.data;
    }
}

export async function getBookingsByEmail(dateFrom, dateTo, email) {
    const estateId = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;

    const body = {
        dateFrom, 
        dateTo,
        estateId,
        email
    };

    const res = await inovioApiCheck('POST', `/estate/getbookingsbyemail`, body);

    if(typeof res === 'string') {
        return res;
    } else if(typeof res === 'object' && res.data) {
        return res.data;
    }
}

export async function deleteMorphoCode(userId, code) {
    const estateId = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;

    const body = {
        userId,
        estateId,
        code
    };

    const res = await inovioApiCheck('POST', `/enroll/deletemorphouser`, body);

    if(typeof res === 'string') {
        return res;
    } else if(typeof res === 'object' && res.data) {
        return res.data;
    }
}

export async function uploadDocument(fileBase64, fileName, name, size) {
    const estateId = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;

    const body =
    {
        file: fileBase64,
        fileName,
        name, //display name
        sizeBytes: size
    }

    const res = await inovioApiCheck('POST', `/estate/uploadfilecm/${estateId}`, body);

    if(typeof res === 'string') {
        return res;
    } else if(typeof res === 'object' && res.data) {
        return res.data;
    }
}

// estate/checkbookingavailability/:estateId
// {dateFrom,dateTo,groupSize,bookingVenueId}

// /getbookingconfigurationfiles/:estateId

export async function getDocuments() {
    const estateId = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;

    const res = await inovioApiCheck('GET', `/estate/getbookingconfigurationfiles/${estateId}`);

    if(typeof res === 'string') {
        return res;
    } else if(typeof res === 'object' && res.data) {
        return res.data;
    }
}

export function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function parseQueryString() {
    let str = window.location.search.replaceAll('%20', ' ');
    let objURL = {};

    str.replace(
        new RegExp("([^?=&]+)(=([^&]*))?", "g"),
        function ($0, $1, $2, $3) {
            objURL[$1] = $3;
        }
    );
    return objURL;
};

export function isValidJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function isValidDate(d) {
    if (Object.prototype.toString.call(d) === "[object Date]") {
        // it is a date
        if (isNaN(d.getTime())) {  // d.valueOf() could also work
            // date is not valid
            return false;
        } else {
            // date is valid
            return true;
        }
    } else {
        // not a date
        return false;
    }
}

export async function disableUser(userId) {
    const estateId = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;

    const res = await inovioApiCheck('GET', `/user/disableuser/${estateId}/${userId}`);

    if(typeof res === 'string') {
        return res;
    } else if(typeof res === 'object' && res.data) {
        return res.data;
    }
}

export async function enableUser(userId) {
    const estateId = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;

    const res = await inovioApiCheck('GET', `/user/enableuser/${estateId}/${userId}`);

    if(typeof res === 'string') {
        return res;
    } else if(typeof res === 'object' && res.data) {
        return res.data;
    }
}

export async function forgotPasswordEmail(email) {
    // const estateId = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;

    const res = await inovioApi.put(`/estate/forgotpwd/${email}`, {});

    if(typeof res === 'string') {
        return res;
    } else if(typeof res === 'object' && res.data) {
        return res.data;
    }
}

export async function resetPassword(userId, token, password, confirmPassword) {
    // const estateId = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;
    const body = {
        token: token,
        password: password,
        confirm_password: confirmPassword
    };

    const res = await inovioApi.put(`/estate/pwdchange/${userId}`, body);

    if(typeof res === 'string') {
        return res;
    } else if(typeof res === 'object' && res.data) {
        return res.data;
    }
}

// export function parseQueryString() {

//     if(window && window.location && window.location.search) {
//         let str = window.location.search.replaceAll('%20', ' ');
//         let objURL = {};
    
//         str.replace(
//             new RegExp("([^?=&]+)(=([^&]*))?", "g"),
//             function ($0, $1, $2, $3) {
//                 objURL[$1] = $3;
//             }
//         );
//         return objURL;
//     }

//     return {};

// };

// ###

// PUT {{url}}/estate/pwdchange/4284
// Content-Type: application/json

// {
//     "token":"831a28d6bfb823071e16f2b7c2e1c8f9",
//     "password":"yo",
//     "confirm_password":"yo"
// }
