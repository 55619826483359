import React, { useState, useEffect, useRef } from "react";
import {
    IonAlert,
    IonFab,
    IonFabButton,
    IonIcon,
    IonGrid,
    IonCol,
    IonRow,
    IonList,
    IonItem,
    IonPopover,
    IonButton,
    IonSelect, 
    IonSelectOption,
    IonLabel
} from "@ionic/react";
import Fuse from "fuse.js";
import ReactPaginate from "react-paginate";
import BaseTemplate from "../../components/base/BaseTemplate";
import UsersList from "../../components/UserPortalUsersList/UserPortalUsersList";
import Search from "../../components/Search/Search";
import { add } from "ionicons/icons";
import { getUserUsers, getUserUnits, exportArrayToExcel, checkToken, getUserInfo } from "../../utils";
import Loading from "../../components/Loading";
import { refreshOutline } from 'ionicons/icons';

const PER_PAGE = 15;

function Users() {
    const [error, setError] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [users, setUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [units, setUnits] = useState([]);
    const [popoverUnits, setPopoverUnits] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [companyTypes, setCompanyTypes] = useState();
    const [allCompanyTypes, setAllCompanyTypes] = useState();
    const [filters, setFilters] = useState([]);
    const [popoverState, setShowPopover] = useState({
        showPopover: false,
        event: undefined,
    });
    const userTypes = JSON.parse(localStorage.getItem('inovio-estate-info')).usertypes;

    const userTypesSelectRef = useRef();
    const companyTypesSelectRef = useRef();

    Array.prototype.naturalSort= function(){
        var a, b, a1, b1, rx=/(\d+)|(\D+)/g, rd=/\d+/;
        return this.sort(function(as, bs){
            a= String(as.firstname).toLowerCase().match(rx);
            b= String(bs.firstname).toLowerCase().match(rx);
            while(a.length && b.length){
                a1= a.shift();
                b1= b.shift();
                if(rd.test(a1) || rd.test(b1)){
                    if(!rd.test(a1)) return 1;
                    if(!rd.test(b1)) return -1;
                    if(a1!= b1) return a1-b1;
                }
                else if(a1!= b1) return a1> b1? 1: -1;
            }
            return a.length- b.length;
        });
    }

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('inovio-user-info'));
        const unitId = userInfo.units.ids[0];
        const tempUsers = userInfo.units[unitId].users;
        setUsers(tempUsers);
        setLoading(false);

        // for(let i = 0; i < userIds.length; i++) {
        //     getUserInfo(userIds[i].user_id).then(res => {
        //         tempUsers.push(res.user[0]);

        //         if(i === (userIds.length - 1)) {
        //             console.log(tempUsers);
        //             setUsers(tempUsers);
        //             setLoading(false);
        //         }
        //     });
        // }
    }, []);

    function getUnitsForUser(userId) {
        const usersUnits = [];
        const user = users.find((user) => user.id === userId);
        units.map((unit) => {
        user.unit_id_arr.map((id) => {
            if (unit.id === id) {
            usersUnits.push(unit);
            }
        });
        });

        setPopoverUnits(usersUnits);
    }

    function getUnitsNamesForUser(userId) {
        const usersUnits = [];
        const user = users.find((user) => user.id === userId);
        units.map((unit) => {
        if(user) {
            user.unit_id_arr.map((id) => {
            if (unit.id === id) {
                usersUnits.push(unit);
            }
            });
        }
        });

        return usersUnits;
    }

    useEffect(() => {
        setCurrentPage(0);
        const types = getCompanyTypes(users);
        setCompanyTypes(types);
        if(types.length > 1) {
        setAllCompanyTypes(types);
        }
        const usersFuse = new Fuse(users, {
        keys: ["firstname", "lastname", "email"],
        });
        const results = usersFuse.search(searchQuery);
        setSearchResults(results);
    }, [searchQuery, users]);

    const revisedUsers =
        searchResults.length > 0 ? searchResults.map((item) => item.item) : users;

    function handlePageClick({ selected: selectedPage }) {
        document.querySelector('#main-content').scrollToTop();
        setCurrentPage(selectedPage);
    }

    const offset = currentPage * PER_PAGE;

    const currentPageData = revisedUsers.slice(offset, offset + PER_PAGE);

    const pageCount = Math.ceil(revisedUsers.length / PER_PAGE);

    function handleFilterByType(value) {
        let tempFilters = [...filters.filter(f => !f.includes("type")), `type:${value}`];
        setCurrentPage(0);
        if(value === "0") {
        setUsers(allUsers);
        return;
        }
        const tempUsers = handleFilters(tempFilters, allUsers);
        setUsers(tempUsers.filter(user => user.usertype_id === parseInt(value)));
    }

    function exportToExcel() {
        exportArrayToExcel(revisedUsers, 'users-data');
    }

    function getCompanyTypes(users) {
        let uniqueTypes = [];
        users.forEach((user) => {
        if (uniqueTypes.indexOf(user.company) === -1) {
            if(user.company !== null) {
            uniqueTypes.push(user.company);
            }
        }
        });
        return uniqueTypes;
    }

    function handleFilterByCompany(value) {
        let tempFilters = [...filters.filter(f => !f.includes("company")), `company:${value}`];
        setCurrentPage(0);
        if (value === "all") {
        setUsers(allUsers);
        return;
        }
        const tempUsers = handleFilters(tempFilters, allUsers);
        setUsers(tempUsers.filter((item) => item.company === value));
    }

    function resetFilters() {
        setCurrentPage(0);
        userTypesSelectRef.current.value = "";
        companyTypesSelectRef.current.value = "";
        setUsers(allUsers);
    }

    function filterByCompany(value, data) {
        return data.filter((item) => item.company === value);
    }

    function filterByType(value, data) {
        return data.filter((item) => item.usertype_id === parseInt(value));
    }

    function handleFilters(filters, data) {
        for(let i = 0; i < filters.length; i++) {
        const filterType = filters[i].split(":")[0];
        const filterValue = filters[i].split(":")[1];
        switch(filterType) {
            case "company": {
            data = filterByCompany(filterValue, data);
            break;
            }
            case "type": {
            data = filterByType(filterValue, data);
            break;
            }
        }
        
        }
        return data;
    }

    return (
        <BaseTemplate>
        <div id="search">
            <Search setSearchQuery={setSearchQuery} placeholder="Search Names"/>
        </div>
        <IonRow>
            <IonCol size-md="4.5" offset-md="1" size-xs="12">
            <IonItem className="shadow-xl">
                <IonLabel>Filter By Type</IonLabel>
                <IonSelect
                okText="Okay"
                cancelText="Dismiss"
                interface="popover"
                onIonChange={(e) => handleFilterByType(e.detail.value)}
                ref={userTypesSelectRef}
                >
                {userTypes.map((type) => (
                    <IonSelectOption value={String(type.id)} key={type.id}>
                    {type.name}
                    </IonSelectOption>
                ))}
                </IonSelect>
            </IonItem>
            </IonCol>
            <IonCol size-md="4.5" size-xs="12">
            <IonItem className="shadow-xl">
                    <IonLabel>Filter by Company</IonLabel>
                    <IonSelect
                    okText="Okay"
                    cancelText="Dismiss"
                    onIonChange={(e) => handleFilterByCompany(e.detail.value)}
                    interface="popover"
                    ref={companyTypesSelectRef}
                    >
                    {allCompanyTypes &&
                        allCompanyTypes.map((type, i) => (
                        <IonSelectOption key={i} value={type}>
                            {type}
                        </IonSelectOption>
                        ))}
                    </IonSelect>
                </IonItem>
            </IonCol>
            <IonCol size-md="1" size-xs="12">
                <IonButton
                expand="full"
                onClick={resetFilters}
                tooltip="reset filters"
                >
                {/* <IonIcon icon={refreshOutline} /> */}
                RESET FILTERS
                </IonButton>
            </IonCol>
            </IonRow>
        <div className="users">
            <IonGrid>
            <IonRow style={{ boxShadow: "none" }}>
                <IonCol offset="1" size="10">
                {loading && <Loading loading={loading} />}
                {!loading && (
                    <UsersList
                    setShowPopover={setShowPopover}
                    getUnitsForUser={getUnitsForUser}
                    getUnitsNamesForUser={getUnitsNamesForUser}
                    items={currentPageData}
                    exportToExcel={exportToExcel}
                    />
                )}
                <div style={{ margin: "1em 1em" }}>
                    <ReactPaginate
                    previousLabel={"←"}
                    nextLabel={"→"}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                    />
                </div>
                </IonCol>
            </IonRow>
            </IonGrid>
            <IonAlert
            isOpen={error !== ""}
            onDidDismiss={() => setError("")}
            header={"Error"}
            message={error}
            buttons={["OK"]}
            />
        </div>
        <IonPopover
            cssClass="my-custom-class"
            event={popoverState.event}
            isOpen={popoverState.showPopover}
            onDidDismiss={() =>
            setShowPopover({ showPopover: false, event: undefined })
            }
        >
            {
            <IonList style={{ width: "100%" }}>
                {popoverUnits.map((unit) => (
                <IonItem key={unit.id} routerLink={`/units/edit/${unit.id}`}>
                    {unit.name}
                </IonItem>
                ))}
                {/* <IonButton expand="full">Link New Unit</IonButton> */}
            </IonList>
            }
        </IonPopover>
        <IonFab
            vertical="bottom"
            horizontal="end"
            slot="fixed"
            style={{ marginBottom: "5em", position: "fixed", marginRight: "2em" }}
        >
            <IonFabButton routerLink="/new-user">
            <IonIcon icon={add}></IonIcon>
            </IonFabButton>
        </IonFab>
        </BaseTemplate>
    );
}

export default Users;
