import React from "react";
import {
  IonModal,
  IonIcon,
  IonContent
} from "@ionic/react";
import { close } from "ionicons/icons";

const Modal = ({
    children,
  showModal,
  setShowModal,
  className,
  onDidDismiss
}) => {

  return (
    <IonModal isOpen={showModal} cssClass={`generic-modal ${className}`} onDidDismiss={onDidDismiss ? onDidDismiss : () => setShowModal(false)}>
        <IonContent>
      <div
        style={{
          width: "100%",
          position: "relative",
          height: "20px",
          paddingBottom: "2em",
          paddingTop: "1em",
          cursor: 'pointer',
          zIndex: 99999
        }}
      >
        <IonIcon
          onClick={() => setShowModal(false)}
          size="large"
          style={{ position: "absolute", right: "0.5em" }}
          icon={close}
        ></IonIcon>
      </div>
      <div style={{ position: "relative", height: "90%" }}>
        <div style={{ position: "absolute", top: "0", width: "100%", height: '100%' }}>
            {children}
        </div>
      </div>
      </IonContent>
    </IonModal>
  );
};

export default Modal;
