import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import './tailwind.output.css';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

// Sentry.init({
//     dsn: "https://2bfe794cec6a45c2a6d322a913676cb0@o513301.ingest.sentry.io/5702237",
//     integrations: [new Integrations.BrowserTracing()],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
// });

ReactDOM.render(
        <App />, 
    document.getElementById('root')
);

if(process.env.NODE_ENV === 'production') {
    window.isUpdateAvailable = new Promise(function(resolve, reject) {
        // lazy way of disabling service workers while developing
        if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
        // register service worker file
            navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/service-worker.js`)
                .then(reg => {
                    reg.onupdatefound = () => {
                        const installingWorker = reg.installing;
                        installingWorker.onstatechange = () => {
                            switch (installingWorker.state) {
                                case 'installed':
                                    if (navigator.serviceWorker.controller) {
                                        // update available
                                        resolve(true);
                                    } else {
                                        // no update available
                                        resolve(false);
                                    }
                                    break;
                            }
                        };
                    };
                })
        .catch(err => console.error('[SW ERROR]', err));
        }
    });
}