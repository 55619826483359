import React, { useState, useEffect } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonTitle,
  IonIcon,
  IonButtons,
  IonButton,
} from "@ionic/react";
import { logInOutline, menuOutline, helpOutline } from "ionicons/icons";
import MobileMenu from "../MobileMenu/MobileMenu";
import UserMenuPopover from "../UserMenuPopover/UserMenuPopover";
import { useLocation } from 'react-router-dom';
import { isLoggedIn } from '../../utils';
import HelpVideo from '../HelpVideo';
import Modal from '../Modal';

import "./BaseTemplate.css";

const videoUrls = {
  dashboard: 'eOVCzCN7Duc',
  editUser: '',
  logs: 'GTNKAvkp2qs',
  users: '',
  units: '',

}

export default function BaseTemplate({
  children,
  title,
  backButton,
}) {
  const [userPopoverState, setShowUserPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [showHelpModal, setShowHelpModal] = useState(false);

  useEffect(() => {
    const menu = document.getElementById("mobile-menu");
    if(menu) {
      if(currentPath !== location.pathname) {
        menu.close();
        setCurrentPath(location.pathname);
      }
    }

  }, [location, currentPath]);

  async function handleMenuToggle() {
    const menu = document.getElementById("mobile-menu");
    const isOpen = await menu.isOpen();

    if (!isOpen) {
      menu.open();
    } else {
      menu.close();
    }
  }

  return (
    <>
      {window.innerWidth < 600 ? <MobileMenu /> : ""}
      <IonHeader style={{ textAlign: "center" }}>
        <IonToolbar>
          {/* <div className="time-div">
            {window.innerWidth > 600 && <Time />}
          </div> */}
          <IonButtons slot="start">
            {window.innerWidth < 600 ? (
              <IonButton onClick={handleMenuToggle}>
                <IonIcon style={{color: 'white'}} size="large" icon={menuOutline}></IonIcon>
              </IonButton>
            ) : (
              ""
            )}
          </IonButtons>
          <IonTitle><strong>{title}</strong></IonTitle>
          <IonButtons slot="end">
            {/* <IonButton
              style={{height: '40px'}}
              onClick={() => setShowHelpModal(true)}
            >
              <IonIcon icon={helpOutline} size="large" style={{color: 'white'}} />
            </IonButton>             */}
            <IonButton
              style={{height: '40px'}}
              onClick={(e) => {
                e.persist();
                setShowUserPopover({ showPopover: true, event: e });
              }}
            >
              {isLoggedIn() ? (
                  <img style={{ height: 35, width: 35 }} src="/assets/white-circle.webp" alt="user avatar" />
              ) : (
                <IonIcon size="large" icon={logInOutline}></IonIcon>
              )}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent style={{ margin: "5em auto" }} id="main-content">
        <div className="background">
          <div className="page-content">
            {children}
          </div>
        </div>
      </IonContent>
      <UserMenuPopover
        popoverState={userPopoverState}
        setShowPopover={setShowUserPopover}
      />
      <Modal showModal={showHelpModal} setShowModal={setShowHelpModal}>
          <HelpVideo videoId={videoUrls[currentPath.replace('/', '')]}></HelpVideo>
      </Modal>
    </>
  );
}
