import React, { useState, useEffect } from "react";
import {
  IonItem,
  IonLabel,
  IonTextarea,
  IonButton,
  IonGrid,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonInput,
} from "@ionic/react";

export default function PanicReportForm({ panic }) {
  const [status, setStatus] = useState();

  function handleSubmit(e) {
    e.preventDefault();
  }

  useEffect(() => {
    if (panic) {
      setStatus(panic.status);
    }
  }, []);

  return (
    <div className="px-10 py-2">
      <IonGrid>
        <IonCol size="8" offset="2">
          <form onSubmit={handleSubmit} style={{ marginTop: "1em" }}>
            <IonItem>
              <IonLabel>Status</IonLabel>
              <IonSelect
                value={status}
                okText="Okay"
                cancelText="Dismiss"
                onIonChange={(e) => setStatus(e.detail.value)}
              >
                <IonSelectOption value="unapproved">unapproved</IonSelectOption>
                <IonSelectOption value="reported">reported</IonSelectOption>
                <IonSelectOption value="acknowledged">
                  acknowledged
                </IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Responder</IonLabel>
              <IonInput type="text" value={panic.responder}></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Summary</IonLabel>
              <IonTextarea value={panic.report}></IonTextarea>
            </IonItem>
            <IonButton expand="block" style={{ marginTop: "7em" }}>
              Submit
            </IonButton>
          </form>
        </IonCol>
      </IonGrid>
    </div>
  );
}
