import React, { useState } from "react";
import {
  IonButton,
  IonGrid,
  IonCol,
  IonRow,
  IonItem,
  IonAlert,
} from "@ionic/react";
import Loading from "../Loading";
import { scanFinger, saveFingerPrint } from '../../utils';
import { inovioApi } from '../../apis/inovio';

export default function Scan({
  fingerIndex,
  setFingerIndex,
  scanning,
  setScanning,
  scanIndex,
  setScanIndex,
  setShowModal,
  fingerprints,
  setFingerprints,
  selectedReader,
  email, 
  userId,
  codes, 
  setCodes,
  saveCodes
}) {
  const [loading, setLoading] = useState(false);
  const [tempResults, setTempResults] = useState([]);
  const [resultIndex, setResultIndex] = useState(1);
  const [showAlert, setShowAlert] = useState(false);
  const [alertHeader, setAlertHeader] = useState('');
  const [alertSubtitle, setAlertSubtitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  function startScan() {
    setLoading(true);
    setScanning(true);
    setScanIndex(scanIndex + 1);

    scanFinger(fingerIndex, selectedReader.readerId).then(res => {
      console.log(res);
      setLoading(false);
      let number = 0;
      if(res.error) {
        return;
      }
      if (typeof res.result !== number) {
          number = parseInt(res.result);
      }
      if(number > 40) {
        console.log("over 40");
        setTempResults([...tempResults, number]);
      } else {
        console.log("under 40", res.result, tempResults);
        setTempResults([...tempResults, number]);
      }
      setScanning(false);
    });
  }

  function save() {
    saveFingerPrint(email, parseInt(userId)).then(res => {
      console.log(res);
      setFingerprints([...fingerprints, res]);
      setShowModal(false);
    });
  }

  function addFinger() {
    setTempResults([]);
    setFingerIndex(fingerIndex + 1);
  }

  function total(srcarr) {
    let arr = [...srcarr];
    let sortedArray = arr.sort(function(a, b){return b-a});
    if(arr.length > 1) {
      const topTwoTotal = sortedArray[0] + sortedArray[1];

      return topTwoTotal;
    } else {
      return sortedArray[0];
    }
    // return arr.reduce((a, b) => a + b, 0);
  }

  return (
    <div className="px-5">
      <IonGrid>
        {loading && <div className="pb-5"><Loading loading={loading} /></div>}
        {!loading && (
          <IonCol>
            {/* {!scanning && !tempResults[resultIndex - 1] && (
              <IonItem style={{ width: "100%" }}>
                <p>
                  Click 'start scan' and place your finger firmly on the reader.
                </p>
              </IonItem>
          )} */}
            {tempResults[resultIndex - 1] && (
              <>
                {/* <p>{!(total(tempResults) >= 100) ? 'Scan finger until top two scans total score is over 100' : `Scanning of finger ${fingerIndex} completed, either save your scan to receive a code or scan an additional finger.`}</p> */}
                <IonItem style={{ width: "100%" }}>
                  <p slot="end" className="font-weight-900">
                    <strong>Score</strong>
                  </p>
                </IonItem>
                {tempResults.map((result, i) => (
                  <IonItem key={i} style={{ width: "100%" }}>
                    <p>{`Scan ${i + 1}`}</p> <p slot="end">{result}</p>
                  </IonItem>
                ))}
                <IonItem style={{ width: "100%" }}>
                  <p>
                    <strong>Total</strong>
                  </p>{" "}
                  <p slot="end">
                    <strong>{total(tempResults)}</strong>
                  </p>
                </IonItem>
              </>
            )}
            <div style={{ marginTop: "2em" }}>
              {!(total(tempResults) >= 100) &&
                resultIndex === 1 && (
                  <IonRow>
                    <IonButton
                      style={{ width: "100%" }}
                      expand="full"
                      color="primary"
                      onClick={() => startScan()}
                    >
                      {tempResults.length === 0 ? `Start Scan` : "Scan Again"}
                    </IonButton>
                  </IonRow>
                )}
              {total(tempResults) >= 100 && (
                <IonRow>
                  <IonButton
                    style={{ width: "47.5%", marginRight: "5%" }}
                    expand="block"
                    color="warning"
                    onClick={() => addFinger()}
                  >
                    Add Finger
                  </IonButton>
                  <IonButton
                    style={{ width: "47.5%" }}
                    expand="block"
                    color="primary"
                    onClick={() => save()}
                  >
                    Save
                  </IonButton>
                </IonRow>
              )}
            </div>
          </IonCol>
        )}
      </IonGrid>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        cssClass='my-custom-class'
        header={alertHeader}
        subHeader={alertSubtitle}
        message={alertMessage}
        buttons={['OK']}
      />
    </div>
  );
}
