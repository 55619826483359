import React, { useState, useEffect } from "react";
import {
  IonModal,
  IonList,
  IonItem,
  IonIcon,
} from "@ionic/react";
import Fuse from "fuse.js";
import { close } from "ionicons/icons";
import Search from "../Search/Search";
import "./SearchUnitsModal.css";

const SearchUnitsModal = ({
  units,
  showModal,
  setShowModal,
  handleUnitsChange,
  userCurrentUnits
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const unitsFuse = new Fuse(units, {
      keys: ["name"],
    });
    let results = unitsFuse.search(searchQuery);
    // remove duplicates from list of units
    if(userCurrentUnits && userCurrentUnits.length > 0) {
      results = results.filter(res => { 
        for(let i = 0; i < userCurrentUnits.length; i++) {
          if(res.item.id !== userCurrentUnits[i].id) {
            return res;
          }
        }
      });
    }
    setSearchResults(results);
  }, [searchQuery, units]);

  return (
    <IonModal isOpen={showModal} cssClass="my-custom-class">
      <div
        style={{
          width: "100%",
          position: "relative",
          height: "20px",
          paddingBottom: "2em",
          paddingTop: "1em",
        }}
      >
        <IonIcon
          onClick={() => setShowModal(false)}
          size="large"
          style={{ position: "absolute", right: "0.5em" }}
          icon={close}
        ></IonIcon>
      </div>
      <div style={{ position: "relative", height: "100%" }}>
        <div style={{ position: "absolute", top: "0", width: "100%" }}>
          <h1
            style={{ margin: "0 auto" }}
            className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold"
          >
            Search Units
          </h1>
          <Search
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            style={{ marginBottom: 0 }}
          />
          <div style={{ textAlign: "center" }}>
            <IonList style={{ margin: "auto" }}>
              {searchResults.map((result, i) => (
                <IonItem
                  onClick={() => handleUnitsChange(result.item.id)}
                  style={{ cursor: "pointer" }}
                  key={i}
                >
                  {result.item.name}
                </IonItem>
              ))}
            </IonList>
          </div>
        </div>
      </div>
    </IonModal>
  );
};

export default SearchUnitsModal;
