import React from 'react';
import BaseTemplate from '../../components/base/BaseTemplate';

export default function Settings() {
    return (
        <BaseTemplate title="Settings">
            <div style={{height: '100vh'}}>
                Settings
            </div>
        </BaseTemplate>
    )
}
