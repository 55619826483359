import React, { useState, useRef } from "react";
import {
  IonText,
  IonButton,
  IonItem,
  IonInput,
  IonLabel,
  IonFab,
  IonFabButton,
  IonIcon,
} from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";
import { Link } from "react-router-dom";
import BaseTemplate from "../../components/base/BaseTemplate";
import AddMemberToStandModal from "../../components/AddMemberToStandModal/AddMemberToStandModal";
import { useHistory } from "react-router-dom";
import { inovioApi } from "../../apis/inovio";
import "./NewUnit.css";

export default function AddUnit() {
  const [loading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const nameRef = useRef();
  const addressRef = useRef();

  function handleSubmit(e) {
    e.preventDefault();

    const addUnit = {
      address: addressRef.current.value,
      name: nameRef.current.value,
    };

    console.log(addUnit);

    const token = localStorage.getItem("inovio-access-token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const ESTATE_ID = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;

    inovioApi
      .post(`/estate/${ESTATE_ID}/unit`, addUnit, config)
      .then((res) => console.log(res));
    localStorage.removeItem("inovio-units");
    history.push("/units");
  }

  return (
    <BaseTemplate>
      <div style={{ margin: "1em" }} className="shadow-xl py-10 px-2">
        <h1
          style={{ textAlign: "center", margin: "0 auto 1em auto" }}
          className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold"
        >
          New Unit
        </h1>
        <form onSubmit={handleSubmit}>
          <IonItem>
            <IonLabel position="floating">Name</IonLabel>
            <IonInput type="text" required ref={nameRef}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Address</IonLabel>
            <IonInput type="text" ref={addressRef}></IonInput>
          </IonItem>
          <IonButton
            type="submit"
            expand="block"
            style={{ marginTop: "7em" }}
            disabled={loading}
          >
            Add
          </IonButton>
        </form>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: "2em",
          }}
        >
          <IonText>
            <Link to="/">Cancel</Link>
          </IonText>
        </div>
      </div>
      <IonFab
        vertical="top"
        horizontal="start"
        slot="fixed"
        style={{ marginBottom: "3em" }}
      >
        <IonFabButton onClick={() => history.goBack()}>
          <IonIcon icon={arrowBackOutline}></IonIcon>
        </IonFabButton>
      </IonFab>
      <AddMemberToStandModal
        setShowModal={setShowModal}
        showModal={showModal}
      />
    </BaseTemplate>
  );
}
