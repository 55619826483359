import React, { useState, useEffect } from "react";
import {
  IonApp,
  IonRouterOutlet,
  IonPage,
  IonProgressBar,
  IonSplitPane,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route, Redirect } from "react-router-dom";

import { AuthProvider } from "../contexts/AuthContext";

import MainTabs from "./MainTabs";
import PrivateRoute from "./PrivateRoute";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import UpdateProfile from "../pages/UpdateProfile/UpdateProfile";
import Signup from "../pages/Signup/Signup";
import Dashboard from "../pages/Dashboard/Dashboard";
import Login from "../pages/Login/Login";
import AddNewUser from "../pages/AddNewUser/AddNewUser";
import Menu from "./Menu/Menu";
import EditUser from "../pages/EditUser/EditUser";
import NewEstate from "../pages/NewEstate/NewEstate";
import NewUnit from "../pages/NewUnit/NewUnit";
import EstateConfig from "../pages/EstateConfig/EstateConfig";
import EntryLogs from "../pages/EntryLogs/EntryLogs";
import Settings from "../pages/Settings/Settings";
import EditUnit from "../pages/EditUnit/EditUnit";
import Profile from "../pages/Profile/Profile";
import Units from "../pages/Units/Units";
import Users from "../pages/Users/Users";
import UserView from "../pages/UserView/UserView";
import UnitView from "../pages/UnitView/UnitView";
import EstateInfo from "../pages/EstateInfo/EstateInfo";
import UpdateAvailableToast from "./UpdateAvailableToast";
import OfflineToast from "./OfflineToast";
import PanicManagement from "../pages/PanicManagement/PanicManagement";
import VisitorManagement from "../pages/VisitorManagement/VisitorManagement";
import BarcodeScanner from "./BarodeScanner";
import UserDashboard from "./UserDashboard";
import MyUsers from "../pages/GeneralUserPages/MyUsers";
import MyUnits from "../pages/GeneralUserPages/MyUnits";
import MyLogs from "../pages/GeneralUserPages/MyLogs";
import MyCodes from "../pages/GeneralUserPages/MyCodes";
import NewVisitor from "../pages/NewVisitor";
import Modal from "./Modal";
import Verify from "./Verify";

import UserBookings from "../pages/Bookings/user/Bookings";
import ManageBookings from "../pages/Bookings/estate-manager/Bookings";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "../theme/variables.css";

/* Core CSS */
import "../theme/core.css";
import "../theme/utilities.css";
import EstateMap from "../pages/EstateMap/EstateMap";
import ResetPassword from "../pages/ForgotPassword/ResetPassword";

const App = () => {
  const [changingPage, setChangingPage] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [isAdmin, setIsAdmin] = useState(
    JSON.parse(localStorage.getItem("inovio-isAdmin"))
  );

  useEffect(() => {
    setIsAdmin(JSON.parse(localStorage.getItem("inovio-isAdmin")));
    if (
      window.location.pathname !== "/login" &&
      !JSON.parse(localStorage.getItem("inovio-isAdmin")) &&
      JSON.parse(localStorage.getItem("isPushNotificationsEnabled"))
    ) {
      setShowModal(false);
    }
  });

  useEffect(() => {
    if (
      window.location.pathname !== "/login" &&
      !JSON.parse(localStorage.getItem("inovio-isAdmin")) &&
      !JSON.parse(localStorage.getItem("isPushNotificationsEnabled")) &&
      !JSON.parse(localStorage.getItem('hasViewedModal'))
    ) {
      // setShowModal(true);
      localStorage.setItem('hasViewedModal', true)
    }
  }, []);

  return (
    <AuthProvider>
      <IonApp>
        <IonPage>
          {changingPage && (
            <IonProgressBar type="indeterminate"></IonProgressBar>
          )}
          {/* <UpdateAvailableToast /> */}
          <OfflineToast />
          <IonReactRouter id="main-content">
            {/* <IonSplitPane contentId="main" when={isAdmin ? '(min-width: 600px)' : false}> */}
            <IonSplitPane contentId="main" when={"(min-width: 600px)"}>
              <Menu isAdmin={isAdmin} />
              <IonRouterOutlet id="main">
                {/* <Route exact path="/" render={() => isAdmin ? <Redirect to="/dashboard" /> : <MainTabs />} /> */}
                <Route
                  exact
                  path="/"
                  render={() => <Redirect to="/dashboard" />}
                />
                {isAdmin ? (
                  <PrivateRoute exact path="/dashboard" component={Dashboard} />
                ) : (
                  <PrivateRoute
                    exact
                    path="/dashboard"
                    component={UserDashboard}
                  />
                )}
                <PrivateRoute
                  exact
                  path="/update-profile"
                  component={UpdateProfile}
                  setChangingPage={setChangingPage}
                />
                <Route
                  exact
                  path="/login"
                  render={() => <Login setChangingPage={setChangingPage} />}
                />
                <PrivateRoute exact path="/new-estate" component={NewEstate} />
                <PrivateRoute exact path="/new-unit" component={NewUnit} />
                <PrivateRoute
                  exact
                  path="/estate-config"
                  component={EstateConfig}
                />
                <PrivateRoute exact path="/logs" component={EntryLogs} />
                <Route
                  exact
                  path="/signup"
                  render={() => <Signup setChangingPage={setChangingPage} />}
                />
                <Route
                  exact
                  path="/forgot-password"
                  component={ForgotPassword}
                  setChangingPage={setChangingPage}
                />
                <Route
                  exact
                  path="/reset-password"
                  component={ResetPassword}
                  setChangingPage={setChangingPage}
                />
                <PrivateRoute
                  exact
                  path="/new-user"
                  component={AddNewUser}
                  setChangingPage={setChangingPage}
                />
                <PrivateRoute
                  exact
                  path="/users/edit/:id"
                  component={EditUser}
                />
                <PrivateRoute exact path="/users/:id" component={UserView} />
                <PrivateRoute
                  exact
                  path="/units/edit/:id"
                  component={EditUnit}
                />
                <PrivateRoute exact path="/settings" component={Settings} />
                <PrivateRoute exact path="/bookings" component={UserBookings} />
                <PrivateRoute exact path="/bookings/manage" component={ManageBookings} />
                <PrivateRoute exact path="/profile" component={Profile} />
                <PrivateRoute exact path="/units" component={Units} />
                <PrivateRoute exact path="/users" component={Users} />
                <PrivateRoute exact path="/units/:id" component={UnitView} />
                <PrivateRoute
                  exact
                  path="/estate-info"
                  component={EstateInfo}
                />
                <PrivateRoute
                  exact
                  path="/visitor-management"
                  component={VisitorManagement}
                />
                <PrivateRoute
                  exact
                  path="/panic-management"
                  component={PanicManagement}
                />
                <PrivateRoute
                  exact
                  path="/scanner"
                  component={BarcodeScanner}
                />
                <PrivateRoute
                  exact
                  path="/facilities"
                  component={EstateMap}
                />
                <PrivateRoute exact path="/my-users" component={MyUsers} />
                <PrivateRoute exact path="/my-units" component={MyUnits} />
                <PrivateRoute exact path="/my-codes" component={MyCodes} />
                <PrivateRoute exact path="/logs/:unitId" component={MyLogs} />
                <PrivateRoute
                  exact
                  path="/new-visitor"
                  component={NewVisitor}
                />
                <Route exact path="/verify" component={Verify} />
              </IonRouterOutlet>
            </IonSplitPane>
            {!JSON.parse(
              localStorage.getItem("isPushNotificationsEnabled")
            ) && (
              <Modal showModal={showModal} setShowModal={setShowModal}>
                <div style={{ textAlign: "center" }}>
                  <div style={{ margin: "5em auto", width: "280px" }}>
                    Get notifications when a visitor is requesting access.
                    <div className="onesignal-customlink-container"></div>
                  </div>
                </div>
              </Modal>
            )}
          </IonReactRouter>
        </IonPage>
      </IonApp>
    </AuthProvider>
  );
};

export default App;
