import React, { useRef, useState, useEffect } from "react";
import {
  IonLoading,
  IonButton,
  IonItem,
  IonSelectOption,
  IonSelect,
  IonInput,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonFab,
  IonFabButton,
  IonIcon,
  IonToggle,
  // IonDatetime,
  IonSegment,
  IonSegmentButton,
  IonChip,
  IonPopover,
} from "@ionic/react";

import { useHistory, Link } from "react-router-dom";

import BaseTemplate from "../../components/base/BaseTemplate";
import { arrowBackOutline, closeOutline } from "ionicons/icons";
import { inovioApi } from "../../apis/inovio";
import { getUserInfo, getUsers, getUnits } from "../../utils";
import SearchUnitsModal from "../../components/SearchUnitsModal/SearchUnitsModal";
import Modal from "../../components/Modal";
import Enrollment from "../../components/Enrollment/Enrollment";
import UpdateEmailForm from "../../components/UpdateEmailForm";
import Loading from "../../components/Loading";
import UserLogs from '../../components/UserLogs/UserLogs';
import DayPicker from 'react-day-picker';

import {format} from 'date-fns';

import "./EditUser.css";

function EditUser() {
  const [user, setUser] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cell, setCell] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [company, setCompany] = useState("");
  const [loading, setLoading] = useState(true);
  const [mondayAccess, setMondayAccess] = useState();
  const [tuesdayAccess, setTuesdayAccess] = useState();
  const [wednesdayAccess, setWednesdayAccess] = useState();
  const [thursdayAccess, setThursdayAccess] = useState();
  const [fridayAccess, setFridayAccess] = useState();
  const [saturdayAccess, setSaturdayAccess] = useState();
  const [sundayAccess, setSundayAccess] = useState();
  const [weekdayAccess, setWeekdayAccess] = useState();
  const [allAccess, setAllAccess] = useState();
  const [refreshed, setRefreshed] = useState(false);
  const [userType, setUserType] = useState();
  const history = useHistory();
  const [grantAccessChecked, setGrantAccessChecked] = useState();
  const [receiveCallsChecked, setReceiveCallsChecked] = useState();
  const [manageUsersChecked, setManageUsersChecked] = useState();
  const [users, setUsers] = useState();
  const [units, setUnits] = useState();
  const [usersUnits, setUsersUnits] = useState([]);
  const [userCodes, setUserCodes] = useState([]);
  const [expiryStart, setExpiryStart] = useState();
  const [expiryEnd, setExpiryEnd] = useState();
  const [showExpiryStart, setShowExpiryStart] = useState({ showPopover: false, event: undefined });
  const [showExpiryEnd, setShowExpiryEnd] = useState({ showPopover: false, event: undefined });

  const [isArchived, setIsArchived] = useState(false);

  const [selectedSegment, setSelectedSegment] = useState("info");
  const unitsSelectRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showLogsModal, setShowLogsModal] = useState(false);
  const userTypes = JSON.parse(localStorage.getItem("inovio-estate-info"))
    .usertypes;
  let dayTypes = JSON.parse(localStorage.getItem("inovio-estate-info"))
    .daytypes;
  const [
    showFingerprintSessionStarting,
    setShowFingerprintSessionStarting,
  ] = useState(false);
  const id = window.location.pathname.replace("/users/edit/", "");

  dayTypes.unshift({
    id: null,
    name: "None",
  });

  useEffect(() => {

    if(window.location.search === "?enrollment") {
      setSelectedSegment("enrollment");
    }
    getUsers().then((users) => {
      setUsers(users);
    });
    if (localStorage.getItem("inovio-units")) {
      setUnits(JSON.parse(localStorage.getItem("inovio-units")));
    } else {
      getUnits().then((units) => {
        setUnits(units);
      });
    }
  }, []);

  useEffect(() => {
    if (users && units) {
      const user = users.find((user) => user.user_id === parseInt(id));
      if(user && user.unit_id_arr) {
        const usersCurrentUnits = getUserUnits(user.unit_id_arr);
        setUsersUnits(usersCurrentUnits);
      } else {
        const usersCurrentUnits = getUserUnits([]);
        setUsersUnits(usersCurrentUnits);
      }
      setUser(user);
      setUserType(String(user.usertype_id));
      setGrantAccessChecked(user.right_access === 1);
      setManageUsersChecked(user.right_user === 1);
      setReceiveCallsChecked(user.right_calls === 1);
    }
  }, [users, units]);

  useEffect(() => {
    if (user) {
      getSetUser();
      setLoading(false);
    }
  }, [user]);

  function getSetUser() {
    getUserInfo(user.user_id).then((res) => {
      console.log(res);
      setUserCodes(res.codes);
    });
  }

  function getUserUnits(unitIds) {
    const currentUsersUnits = [];
    unitIds.map((id) => {
      units.map((unit) => {
        if (unit.id === id) {
          currentUsersUnits.push(unit);
        }
      });
    });
    return currentUsersUnits;
  }

  useEffect(() => {
    if (user) {
      setFirstName(user.firstname);
      setLastName(user.lastname);
      setEmail(user.email);
      setCell(user.cellphone);
      setPassword(user.password);
      setIdNumber(user.idnumber);
      setCompany(user.company);
      setMondayAccess(user.day_mon_id !== null ? String(user.day_mon_id) : null);
      setTuesdayAccess(user.day_tue_id !== null ? String(user.day_tue_id) : null);
      setWednesdayAccess(user.day_wed_id !== null ? String(user.day_wed_id) : null);
      setThursdayAccess(user.day_thu_id !== null ? String(user.day_thu_id) : null);
      setFridayAccess( user.day_fri_id !== null ?  String(user.day_fri_id) : null);
      setSaturdayAccess(user.day_sat_id !== null ?  String(user.day_sat_id): null);
      setSundayAccess(user.day_sun_id !== null ?  String(user.day_sun_id) : null);
      setExpiryStart(user.starts_at);
      setExpiryEnd(user.expires_at);
    }
  }, [user]);

  useEffect(() => {}, [usersUnits]);

  function handleSubmit(e) {
    console.log(expiryStart, expiryEnd);
    setButtonDisabled(true);
    e.preventDefault();

    const editUser = {
      id: user.user_id,
      firstname: firstName,
      lastname: lastName,
      email: email,
      cellphone: cell,
      password: password,
      company: company,
      idnumber: idNumber,
      right_access: grantAccessChecked ? 1 : 0,
      right_calls: receiveCallsChecked ? 1 : 0,
      right_user: manageUsersChecked ? 1 : 0,
      usertype_id: userType,
      day_mon_id: parseInt(mondayAccess),
      day_tue_id: parseInt(tuesdayAccess),
      day_wed_id: parseInt(wednesdayAccess),
      day_thu_id: parseInt(thursdayAccess),
      day_fri_id: parseInt(fridayAccess),
      day_sat_id: parseInt(saturdayAccess),
      day_sun_id: parseInt(sundayAccess),
      starts_at: expiryStart,
      expires_at: expiryEnd,
      codes: userCodes,
      units: usersUnits.map((unit) => unit.id),
      is_archived: isArchived
    };

    const token = localStorage.getItem("inovio-access-token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const ESTATE_ID = JSON.parse(localStorage.getItem("inovio-current-estate"))
      .estates_id;

    inovioApi
      .post(`/estate/${ESTATE_ID}/user`, editUser, config)
      .then((res) => { 
        console.log(res);
        window.location.reload();
      })
      .catch(err => console.log(err));
  }

  function removeUnit(index) {
    const newUnits = usersUnits.filter((unit, i) => i !== index);

    setUsersUnits(newUnits);
  }

  function removeCode(index) {
    const newCodes = userCodes.filter((code, i) => i !== index);

    setUserCodes(newCodes);
  }

  function handleCodesChange(value, index) {
    const codes = userCodes;
    codes[index] = { id: index, code: value, type: 1 };
    setUserCodes(codes);
  }

  function handleUnitsChange(value) {
    const updatedUnits = usersUnits;
    updatedUnits[updatedUnits.length] = units.find(
      (unit) => unit.id === parseInt(value)
    );
    setUsersUnits(updatedUnits);
    setRefreshed(!refreshed);
    setShowModal(false);
  }

  function openUnitsSelectRef() {
    unitsSelectRef.current.open();
  }

  function setAllAccessValue(value) {
    setAllAccess(value);
    setMondayAccess(value);
    setTuesdayAccess(value);
    setWednesdayAccess(value);
    setThursdayAccess(value);
    setFridayAccess(value);
    setSaturdayAccess(value);
    setSundayAccess(value);
  }

  function setWeekdayAccessValue(value) {
    setWeekdayAccess(value);
    setMondayAccess(value);
    setTuesdayAccess(value);
    setWednesdayAccess(value);
    setThursdayAccess(value);
    setFridayAccess(value);
  }

  return (
    <BaseTemplate>
      {loading && <Loading loading={loading} />}
      {user && !loading && (
        <div className="edit-user">
          <IonGrid>
            <IonRow>
              <IonCol>
                <div className="shadow-xl px-10 py-2">
                  <h1
                    style={{ margin: "0 auto 1em auto" }}
                    className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold"
                  >
                    {user.firstname} {user.lastname}
                  </h1>
                  <IonButton
                        expand="block"
                        style={{ margin: "1em" }}
                        type="button"
                        onClick={() => setShowLogsModal(true)}
                      >
                        View Logs
                  </IonButton>
                  <IonSegment
                    value={selectedSegment}
                    onIonChange={(e) => setSelectedSegment(e.detail.value)}
                  >
                    <IonSegmentButton value="info">
                      <IonLabel>Information</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="access">
                      <IonLabel>Access</IonLabel>
                    </IonSegmentButton>
                    {JSON.parse(localStorage.getItem('inovio-isAdmin')) && <IonSegmentButton value="enrollment">
                      <IonLabel>Enrollment</IonLabel>
                    </IonSegmentButton>}
                  </IonSegment>
                  <form onSubmit={handleSubmit} style={{ marginTop: "1em" }}>
                    {selectedSegment === "info" && (
                      <>
                        <IonItem>
                          <IonLabel position="floating">First Name</IonLabel>
                          <IonInput
                            value={firstName}
                            autocomplete="off"
                            onIonChange={(e) => setFirstName(e.detail.value)}
                          ></IonInput>
                        </IonItem>
                        <IonItem>
                          <IonLabel position="floating">Last Name</IonLabel>
                          <IonInput
                            value={lastName}
                            autocomplete="off"
                            onIonChange={(e) => setLastName(e.detail.value)}
                          ></IonInput>
                        </IonItem>
                        <IonItem>
                          <IonLabel position="floating">ID Number</IonLabel>
                          <IonInput
                            value={idNumber}
                            autocomplete="off"
                            onIonChange={(e) => setIdNumber(e.detail.value)}
                          ></IonInput>
                        </IonItem>
                        <IonItem>
                          <IonLabel position="floating">Email</IonLabel>
                          <IonInput
                            readonly={true}
                            type="email"
                            value={email}
                            autocomplete="off"
                            onIonChange={(e) => setEmail(e.detail.value)}
                          ></IonInput>
                          <IonButton
                            slot="end"
                            className="ion-margin"
                            onClick={() => setShowEmailModal(true)}
                          >
                            Change Email
                          </IonButton>
                        </IonItem>
                        <IonItem>
                          <IonLabel position="floating">Cell</IonLabel>
                          <IonInput
                            type="tel"
                            autocomplete="new-password"
                            value={cell}
                            onIonChange={(e) => setCell(e.detail.value)}
                          ></IonInput>
                        </IonItem>
                        <IonItem>
                          <IonLabel position="floating">Password</IonLabel>
                          <IonInput
                            type="password"
                            autocomplete="new-password"
                            value={password}
                            onIonChange={(e) => setPassword(e.detail.value)}
                          ></IonInput>
                        </IonItem>
                        <IonItem>
                          <IonLabel position="floating">Company</IonLabel>
                          <IonInput
                            value={company}
                            autocomplete="off"
                            onIonChange={(e) => setCompany(e.detail.value)}
                          ></IonInput>
                        </IonItem>
                        <IonItem>
                          <IonLabel>Start Date</IonLabel>
                          {/* <IonDatetime
                            displayFormat="MM DD YYYY"
                            placeholder="MM DD YYYY"
                            min="2000-01-01" 
                            max="2030-12-31"
                            value={expiryStart}
                            onIonChange={(e) => setExpiryStart(e.detail.value)}
                          ></IonDatetime> */}
                          <IonInput style={{textAlign: 'right'}} onClick={(e) => {e.persist(); setShowExpiryStart({ showPopover: true, event: e })}} placeholder="Expiry Start Date" value={expiryStart ? format(new Date(expiryStart), 'MM DD YYYY') : ''} />

                        </IonItem>
                        <IonItem>
                          <IonLabel>Expiry Date</IonLabel>
                          {/* <IonDatetime
                            displayFormat="MM DD YYYY"
                            placeholder="MM DD YYYY"
                            min="2000-01-01" 
                            max="2030-12-31"
                            value={expiryEnd}
                            onIonChange={(e) => setExpiryEnd(e.detail.value)}
                          ></IonDatetime> */}
                        <IonInput style={{textAlign: 'right'}} onClick={(e) => {e.persist(); setShowExpiryEnd({ showPopover: true, event: e })}} placeholder="Expiry End Date" value={expiryEnd ? format(new Date(expiryEnd), 'MM DD YYYY') : ''} />

                        </IonItem>
                        <IonItem>
                          <IonLabel>Grant Access</IonLabel>
                          <IonToggle
                            checked={grantAccessChecked}
                            onIonChange={(e) =>
                              setGrantAccessChecked(e.detail.checked)
                            }
                          />
                        </IonItem>
                        <IonItem>
                          <IonLabel>Receive Calls</IonLabel>
                          <IonToggle
                            checked={receiveCallsChecked}
                            onIonChange={(e) =>
                              setReceiveCallsChecked(e.detail.checked)
                            }
                          />
                        </IonItem>
                        <IonItem>
                          <IonLabel>Manage Users</IonLabel>
                          <IonToggle
                            checked={manageUsersChecked}
                            onIonChange={(e) =>
                              setManageUsersChecked(e.detail.checked)
                            }
                          />
                        </IonItem>
                        {/* {userCodes.map((code, i) => (
                          <IonItem>
                            <IonLabel position="floating">
                              Code {i + 1}
                            </IonLabel>
                            <IonInput
                              value={code.code}
                              onIonChange={(e) =>
                                handleCodesChange(e.detail.value, i)
                              }
                            ></IonInput>
                            <IonIcon
                              icon={closeOutline}
                              slot="end"
                              color="danger"
                              style={{ paddingTop: "0.25em" }}
                              onClick={() => removeCode(i)}
                            ></IonIcon>
                          </IonItem>
                        ))}
                        <IonItem>
                          <IonButton
                            onClick={() => setUserCodes([...userCodes, ""])}
                          >
                            Add Additional Code
                          </IonButton>
                        </IonItem> */}
                        {JSON.parse(localStorage.getItem('inovio-isAdmin')) && <>
                          {usersUnits.map((unit, i) => (
                            <IonItem key={i}>
                              <IonLabel><Link to={`/units/edit/${unit.id}`}><IonChip>UNIT {unit.name}</IonChip></Link></IonLabel>
                              {/* <IonInput value={unit.name} onIonChange={e => handleUnitsChange(e.detail.value, i)}></IonInput> */}
                              <IonIcon
                                icon={closeOutline}
                                slot="end"
                                color="danger"
                                style={{ paddingTop: "0.25em", cursor: "pointer"}}
                                onClick={() => removeUnit(i)}
                              ></IonIcon>
                            </IonItem>
                          ))}
                          <IonItem>
                            {JSON.parse(localStorage.getItem('inovio-isAdmin')) && <IonButton onClick={() => setShowModal(true)}>
                              {user.unit_id_arr.length > 0
                                ? "Add additonal unit"
                                : "Link Unit"}
                            </IonButton>}
                            <IonSelect
                              id="units-select"
                              ref={unitsSelectRef}
                              onIonChange={(e) =>
                                handleUnitsChange(e.detail.value)
                              }
                            >
                              {units.map((unit) => (
                                <IonSelectOption value={unit.id} key={unit.id}>
                                  {unit.name}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          </IonItem>
                        </>}
                        <IonItem>
                          <IonLabel>User Type</IonLabel>
                          <IonSelect
                            okText="Okay"
                            cancelText="Dismiss"
                            value={userType}
                            onIonChange={(e) => setUserType(e.detail.value)}
                          >
                            {userTypes.map((type) => (
                              <IonSelectOption
                                value={String(type.id)}
                                key={type.id}
                              >
                                {type.name}
                              </IonSelectOption>
                            ))}
                          </IonSelect>
                        </IonItem>
                      </>
                    )}
                    {selectedSegment === "access" && (
                      <>
                      <div style={{margin: '2em 1em'}}>
                        <h1 style={{textAlign: 'left'}} className="uppercase my-3">Access Times</h1>
                        {dayTypes.map((type, i) => (
                          <div style={{textAlign: 'left'}} key={i}>
                            <p>{type.name !== "None" && `${type.name}: ${type.time_start} - ${type.time_end}`}</p>
                          </div>
                        ))}
                      </div>
                        <IonItem>
                          <IonLabel>Set All</IonLabel>
                          <IonSelect
                            okText="Okay"
                            interface="popover"
                            cssClass="select-popover"
                            cancelText="Dismiss"
                            value={allAccess}
                            onIonChange={(e) => setAllAccessValue(e.detail.value)}
                          >
                            {dayTypes.map((type, i) => (
                              <IonSelectOption value={type.id !== null ? String(type.id) : type.id} key={i}>
                                {type.name}
                              </IonSelectOption>
                            ))}
                          </IonSelect>
                        </IonItem>
                        <IonItem>
                          <IonLabel>Set Weekdays</IonLabel>
                          <IonSelect
                            okText="Okay"
                            interface="popover"
                            cancelText="Dismiss"
                            value={weekdayAccess}
                            onIonChange={(e) => setWeekdayAccessValue(e.detail.value)}
                          >
                            {dayTypes.map((type, i) => (
                              <IonSelectOption value={type.id !== null ? String(type.id) : type.id} key={i}>
                                {type.name}
                              </IonSelectOption>
                            ))}
                          </IonSelect>
                        </IonItem>
                        <IonItem>
                          <IonLabel>Monday</IonLabel>
                          <IonSelect
                            okText="Okay"
                            interface="popover"
                            cancelText="Dismiss"
                            value={mondayAccess}
                            onIonChange={(e) => setMondayAccess(e.detail.value)}
                          >
                            {dayTypes.map((type) => (
                              <IonSelectOption
                                value={type.id !== null ? String(type.id) : type.id}
                                key={type.id}
                              >
                                {type.name}
                              </IonSelectOption>
                            ))}
                          </IonSelect>
                        </IonItem>
                        <IonItem>
                          <IonLabel>Tuesday</IonLabel>
                          <IonSelect
                            okText="Okay"
                            interface="popover"
                            cancelText="Dismiss"
                            value={tuesdayAccess}
                            onIonChange={(e) =>
                              setTuesdayAccess(e.detail.value)
                            }
                          >
                            {dayTypes.map((type) => (
                              <IonSelectOption
                                value={type.id !== null ? String(type.id) : type.id}
                                key={type.id}
                              >
                                {type.name}
                              </IonSelectOption>
                            ))}
                          </IonSelect>
                        </IonItem>
                        <IonItem>
                          <IonLabel>Wednesday</IonLabel>
                          <IonSelect
                            okText="Okay"
                            interface="popover"
                            cancelText="Dismiss"
                            value={wednesdayAccess}
                            onIonChange={(e) =>
                              setWednesdayAccess(e.detail.value)
                            }
                          >
                            {dayTypes.map((type) => (
                              <IonSelectOption
                                value={type.id !== null ? String(type.id) : type.id}
                                key={type.id}
                              >
                                {type.name}
                              </IonSelectOption>
                            ))}
                          </IonSelect>
                        </IonItem>
                        <IonItem>
                          <IonLabel>Thursday</IonLabel>
                          <IonSelect
                            okText="Okay"
                            interface="popover"
                            cancelText="Dismiss"
                            value={thursdayAccess}
                            onIonChange={(e) =>
                              setThursdayAccess(e.detail.value)
                            }
                          >
                            {dayTypes.map((type) => (
                              <IonSelectOption
                                value={type.id !== null ? String(type.id) : type.id}
                                key={type.id}
                              >
                                {type.name}
                              </IonSelectOption>
                            ))}
                          </IonSelect>
                        </IonItem>
                        <IonItem>
                          <IonLabel>Friday</IonLabel>
                          <IonSelect
                            okText="Okay"
                            interface="popover"
                            cancelText="Dismiss"
                            value={fridayAccess}
                            onIonChange={(e) => setFridayAccess(e.detail.value)}
                          >
                            {dayTypes.map((type) => (
                              <IonSelectOption
                                value={type.id !== null ? String(type.id) : type.id}
                                key={type.id}
                              >
                                {type.name}
                              </IonSelectOption>
                            ))}
                          </IonSelect>
                        </IonItem>
                        <IonItem>
                          <IonLabel>Saturday</IonLabel>
                          <IonSelect
                            okText="Okay"
                            interface="popover"
                            cancelText="Dismiss"
                            value={saturdayAccess}
                            onIonChange={(e) =>
                              setSaturdayAccess(e.detail.value)
                            }
                          >
                            {dayTypes.map((type) => (
                              <IonSelectOption
                                value={type.id !== null ? String(type.id) : type.id}
                                key={type.id}
                              >
                                {type.name}
                              </IonSelectOption>
                            ))}
                          </IonSelect>
                        </IonItem>
                        <IonItem>
                          <IonLabel>Sunday</IonLabel>
                          <IonSelect
                            okText="Okay"
                            interface="popover"
                            cancelText="Dismiss"
                            value={sundayAccess}
                            onIonChange={(e) => setSundayAccess(e.detail.value)}
                          >
                            {dayTypes.map((type) => (
                              <IonSelectOption
                                value={type.id !== null ? String(type.id) : type.id}
                                key={type.id}
                              >
                                {type.name}
                              </IonSelectOption>
                            ))}
                          </IonSelect>
                        </IonItem>
                      </>
                    )}
                    {selectedSegment === "enrollment" && user && (
                      <Enrollment
                        user={user}
                        userId={user.user_id}
                        email={user.email}
                        userTypeId={userType}
                        setShowLoading={setShowFingerprintSessionStarting}
                        codes={userCodes}
                        setCodes={setUserCodes}
                        users={users}
                        getSetUser={getSetUser}
                      />
                    )}
                    {/* {
                      selectedSegment === "units" &&
                      <>
                        <IonList style={{ width: "100%" }}>
                          {usersUnits.map((unit) => (
                            <IonItem>
                              <IonLabel>{unit.name}</IonLabel>
                              <IonIcon
                                slot="end"
                                icon={trashOutline}
                                color="danger"
                              ></IonIcon>
                            </IonItem>
                          ))}
                        </IonList>
                      </>
                    } */}
                    {selectedSegment === "access" && (
                      <IonButton
                        type="submit"
                        expand="block"
                        style={{ marginTop: "7em", marginBottom: "3em" }}
                        disabled={buttonDisabled}
                      >
                        Update
                      </IonButton>
                    )}
                    {selectedSegment === "info" && (
                      <IonButton
                        expand="block"
                        style={{ marginTop: "7em", marginBottom: "3em" }}
                        type="button"
                        onClick={() => setSelectedSegment("access")}
                      >
                        Next
                      </IonButton>
                    )}
                  </form>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      )}
      <IonFab vertical="top" horizontal="start" slot="fixed">
        <IonFabButton onClick={() => history.goBack()}>
          <IonIcon icon={arrowBackOutline}></IonIcon>
        </IonFabButton>
      </IonFab>
      {/* <IonAlert
        isOpen={showConfirmAlert}
        onDidDismiss={() => setShowConfirmAlert(false)}
        cssClass="my-custom-class"
        header={"Alert"}
        subHeader={"Subtitle"}
        message={"Are you sure you want to make these changes?"}
        buttons={["confirm", "cancel"]}
      /> */}
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showFingerprintSessionStarting}
        onDidDismiss={() => setShowFingerprintSessionStarting(false)}
        message={"starting session"}
      />
      {units && (
        <SearchUnitsModal
          units={units}
          userCurrentUnits={usersUnits}
          setShowModal={setShowModal}
          showModal={showModal}
          handleUnitsChange={handleUnitsChange}
        />
      )}
      <Modal setShowModal={setShowEmailModal} showModal={showEmailModal}>
        <UpdateEmailForm email={email} setEmail={setEmail} setShowModal={setShowEmailModal}/>
      </Modal>
      {user && (
        <Modal showModal={showLogsModal} setShowModal={setShowLogsModal}>
              <UserLogs userId={user.user_id} />
        </Modal>
      )}
      <IonPopover
          cssClass="datetime-popover-picker"
          isOpen={showExpiryStart.showPopover}
          event={showExpiryStart.event}
          onDidDismiss={() => setShowExpiryStart({ showPopover: false, event: undefined })}
      >
          <DayPicker canChangeMonth={true} onDayClick={(day) => { setExpiryStart(day.toString()); setShowExpiryStart({ showPopover: false, event: undefined }); }} />
      </IonPopover>
      <IonPopover
          cssClass="datetime-popover-picker"
          isOpen={showExpiryEnd.showPopover}
          event={showExpiryEnd.event}
          onDidDismiss={() => setShowExpiryEnd({ showPopover: false, event: undefined })}
      >
          <DayPicker canChangeMonth={true} onDayClick={(day) => { setExpiryEnd(day.toString()); setShowExpiryEnd({ showPopover: false, event: undefined }); }} />
      </IonPopover>
    </BaseTemplate>
  );
}

export default EditUser;
