    import React, { useState, useEffect, useRef } from "react";
    import BaseTemplate from "../../components/base/BaseTemplate";
    import {
    IonDatetime,
    IonItem,
    IonGrid,
    IonCol,
    IonRow,
    IonLabel,
    IonAlert,
    IonIcon,
    IonSelect,
    IonSelectOption,
    IonButton,
    } from "@ionic/react";
    import { Link } from "react-router-dom";
    import ReactPaginate from "react-paginate";
    import Fuse from "fuse.js";
    import { downloadOutline, refreshOutline } from "ionicons/icons";
    import { getUnitAccess, exportArrayToExcel, getAcrs } from "../../utils";
    import Loading from "../../components/Loading";
    import Search from "../../components/Search/Search";
    import Modal from "../../components/Modal";
    import AddManualLogEntry from "../../components/AddManualLogEntry";

    const PER_PAGE = 15;

    export default function EntryLogs() {
    const unitId = localStorage.getItem('inovio-user-info') ? JSON.parse(localStorage.getItem('inovio-user-info')).units.ids[0] : window.location.href = "/";
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    const [selectedStartDate, setSelectedStartDate] = useState(
        yesterday.toISOString()
    );
    const [selectedEndDate, setSelectedEndDate] = useState(today.toISOString());
    const [prevSelectedStartDate, setPrevSeectedStartDate] = useState(yesterday);
    const [prevSelectedEndDate, setPrevSelectedEndDate] = useState(today);
    const [currentPage, setCurrentPage] = useState(0);
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const [entered, setEntered] = useState();
    const [exited, setExited] = useState();
    const [granted, setGranted] = useState();
    const [denied, setDenied] = useState();
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [gateTypes, setGateTypes] = useState([]);
    const [mode, setMode] = useState("historic");
    const [intervals, setIntervals] = useState([]);
    const [filters, setFilters] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [acrList, setAcrList] = useState([]);
    const userTypes = JSON.parse(localStorage.getItem("inovio-estate-info"))
        .usertypes;
    const grantedRef = useRef();
    const directionRef = useRef();
    const typeRef = useRef();
    const gateRef = useRef();

    function calculateEntered(data) {
        setEntered(data.filter((d) => (d.direction === 1) && (d.granted === 1)).length);
    }

    function calculateExited(data) {
        setExited(data.filter((d) => d.direction === 2).length);
    }

    function calculateGranted(data) {
        setGranted(data.filter((d) => d.granted === 1).length);
    }

    function calculateDenied(data) {
        setDenied(data.filter((d) => d.granted === 0).length);
    }

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);
    }

    const revisedData =
        searchResults.length > 0 ? searchResults.map((item) => item.item) : data;

    const offset = currentPage * PER_PAGE;

    const currentPageData = revisedData.slice(offset, offset + PER_PAGE);

    const pageCount = Math.ceil(revisedData.length / PER_PAGE);

    useEffect(() => {
        getUnitAccess(selectedStartDate, selectedEndDate, unitId).then((res) => {
            console.log(res);
            setData(res);
            setAllData(res);
            setLoading(false);
            calculateEntered(res);
            calculateExited(res);
            calculateGranted(res);
            calculateDenied(res);
            const types = getGateTypes(res);
            setGateTypes(types);
        });
        
        getAcrs().then(res => {
            setAcrList(res);
        });
        // const id = setInterval(() => {
        //   setSelectedEndDate(new Date().toISOString());
        //   console.log('ran');
        // }, 30000);

        // setIntervals([...intervals, id]);

        // return function cleanup() {
        //   intervals.map(interval => {
        //     clearInterval(interval);
        //   });
        // }
    }, []);

    useEffect(() => {
        setCurrentPage(0);
        const dataFuse = new Fuse(data, {
        keys: ["firstname", "lastname"],
        });
        setSearchResults(dataFuse.search(searchQuery));
    }, [searchQuery, data]);

    useEffect(() => {
        if (selectedStartDate && selectedEndDate) {
        const dateDifference =
            new Date(selectedEndDate) - new Date(selectedStartDate);
        if (new Date(selectedStartDate) > new Date(selectedEndDate)) {
            setSelectedStartDate(prevSelectedStartDate);
            setAlertMessage("start date must be before end date");
            setShowAlert(true);
        }
        if (new Date(selectedStartDate) < new Date(selectedEndDate)) {
            if (dateDifference > 2678400000) {
            setAlertMessage("dates must be within a month of each other");
            setShowAlert(true);
            } else {
            getUnitAccess(selectedStartDate, selectedEndDate, unitId).then((res) => {
                setCurrentPage(0);
                setPrevSeectedStartDate(selectedStartDate);
                setPrevSelectedEndDate(selectedEndDate);
                const tempData = handleFilters(filters, res);
                setData(tempData);
                setLoading(false);
                calculateEntered(res);
                calculateExited(res);
                calculateGranted(res);
                calculateDenied(res);
            });
            }
        }
        }
    }, [selectedStartDate, selectedEndDate]);

    useEffect(() => {
        let id = '';
        if(mode === 'historic') {
        //cancel interval
        intervals.map(interval => {
            clearInterval(interval);
        });
        } else {
        id = setInterval(() => {
            setSelectedEndDate(new Date().toISOString());
        }, 15000);
    
        setIntervals([...intervals, id]);
        }

        return function cleanup() {
        intervals.map(interval => {
            clearInterval(interval);
        });
        }
    }, [mode]);

    function handleDateChange(value, type) {
        if(value === null) return;
        setLoading(true);
        if (type === "start") {
        setSelectedStartDate(value);
        } else if (type === "end") {
        setSelectedEndDate(value);
        }
    }

    function handleFilterByGate(value) {
        if(value === "") return;
        let tempFilters = [...filters.filter(f => !f.includes("gate")), `gate:${value}`];
        setFilters(tempFilters);
        if (value === "all") {
        setData(allData);
        return;
        }
        const tempData = handleFilters(tempFilters, allData);
        setData(tempData.filter((item) => item.acr_name === value));
        setCurrentPage(0);
    }

    function handleFilterByType(value) {
        if(value === "") return;
        let tempFilters = [...filters.filter(f => !f.includes("type")), `type:${value}`];
        setFilters(tempFilters);
        if (value === "0") {
        setData(allData);
        return;
        }
        const tempData = handleFilters(tempFilters, allData);
        if(value === "v") {
        let visitors = [];
        for(let i = 0; i < tempData.length; i++) {
            if(tempData[i].user_id === null && data[i].firstname) {
            visitors.push(tempData[i]);
            }
        }
        setData(visitors);
        setCurrentPage(0);
        return;
        }
        setData(tempData.filter((item) => item.usertype_id === parseInt(value)));
        setCurrentPage(0);
    }

    function handleFilterByDirection(value) {
        if(value === "") return;
        let tempFilters = [...filters.filter(f => !f.includes("direction")), `direction:${value}`];
        setFilters(tempFilters);
        if (value === "all") {
        setData(allData);
        return;
        }
        const tempData = handleFilters(tempFilters, allData);
        setData(tempData.filter((item) => item.direction === parseInt(value)));
        setCurrentPage(0);
    }

    function handleFilterByGranted(value) {
        if(value === "") return;
        let tempFilters = [...filters.filter(f => !f.includes("granted")), `granted:${value}`];
        setFilters(tempFilters);
        
        if (value === "all") {
        setData(allData);
        return;
        }
        const tempData = handleFilters(tempFilters, allData);
        setData(tempData.filter((item) => item.granted === parseInt(value)));
        setCurrentPage(0);
    }

    function filterByGate(value, data) {
        return data.filter((item) => item.acr_name === value);
    }

    function filterByType(value, data) {
        if(value === "v") {
        let visitors = [];
        for(let i = 0; i < data.length; i++) {
            if(data[i].user_id === null && data[i].firstname) {
            visitors.push(data[i]);
            }
        }
        return visitors;
        }
        return data.filter((item) => item.usertype_id === parseInt(value));
    }

    function filterByDirection(value, data) {
        return data.filter((item) => item.direction === parseInt(value));
    }

    function filterByGranted(value, data) {
        return data.filter((item) => item.granted === parseInt(value));
    }

    function handleFilters(filters, data) {
        for(let i = 0; i < filters.length; i++) {
        const filterType = filters[i].split(":")[0];
        const filterValue = filters[i].split(":")[1];

        switch(filterType) {
            case "gate": {
            data = filterByGate(filterValue, data);
            break;
            }
            case "type": {
            data = filterByType(filterValue, data);
            break;
            }
            case "direction": {
            data = filterByDirection(filterValue, data);
            break;
            }
            case "granted": {
            data = filterByGranted(filterValue, data);
            break;
            }
        }
        
        }
        return data;
    }

    function getGateTypes(logs) {
        let uniqueTypes = [];
        logs.forEach((log) => {
        if (uniqueTypes.indexOf(log.acr_name) === -1) {
            uniqueTypes.push(log.acr_name);
        }
        });
        return uniqueTypes;
    }

    function resetFilters() {
        setCurrentPage(0);
        gateRef.current.value = "";
        directionRef.current.value = "";
        grantedRef.current.value = "";
        typeRef.current.value = "";
        setFilters([]);
        setData(allData);
    }

    return (
        <BaseTemplate>
        <div className="entry-logs">
            <IonGrid>
            <IonRow style={{ boxShadow: "none" }}>
                <IonCol size-md="2" offset-md="0.5" size-xs="12">
                <IonItem className="shadow-xl">
                    <IonSelect
                    okText="Okay"
                    cancelText="Dismiss"
                    onIonChange={(e) => setMode(e.detail.value)}
                    value={mode}
                    interface="popover"
                    >
                    <IonSelectOption value="historic">Historic</IonSelectOption>
                    <IonSelectOption value="real-time">Real Time</IonSelectOption>
                    </IonSelect>
                    <br />
                </IonItem>
                </IonCol>

                {mode === 'historic' && <><IonCol size-md="4.5" size-xs="6">
                <IonItem className="shadow-xl">
                    <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                    Start Date
                    </IonLabel>
                    <IonDatetime
                    displayFormat="D MMM YYYY H:mm"
                    min="1997"
                    max={new Date().getFullYear()}
                    value={selectedStartDate}
                    onIonChange={(e) => handleDateChange(e.detail.value, "start")}
                    ></IonDatetime>
                </IonItem>
                </IonCol>
                <IonCol size-md="4.5" size-xs="6">
                <IonItem className="shadow-xl">
                    <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                    End Date
                    </IonLabel>
                    <IonDatetime
                    displayFormat="D MMM YYYY H:mm"
                    min="1997"
                    max={new Date().getFullYear()}
                    value={selectedEndDate}
                    onIonChange={(e) => handleDateChange(e.detail.value, "end")}
                    ></IonDatetime>
                </IonItem>
                </IonCol></>}
            </IonRow>

            {mode === "historic" && <>
                <IonRow>
                    <Search
                    style={{ marginTop: 0 }}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    />
                </IonRow>
                <IonRow>
                    <IonCol size-md="1" offset-md="1">
                    <IonButton
                        expand="full"
                        onClick={resetFilters}
                        tooltip="reset filters"
                    >
                        <IonIcon icon={refreshOutline}></IonIcon>
                    </IonButton>
                    </IonCol>
                </IonRow>
            </>}
            <IonRow style={{ boxShadow: "none" }}>
                <IonCol size="11" offset="0.5" className="table-container">
                <table
                    className="items-center w-full bg-transparent border-collapse"
                    id="logs-table"
                >
                    <thead>
                    <tr>
                        <th className="px-2 pl-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                        Name
                        </th>
                        <th className="px-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                        Type
                        </th>
                        <th className="px-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                        Direction
                        </th>
                        <th className="px-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                        Gate
                        </th>
                        <th className="px-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                        Date
                        </th>
                        <th className="px-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                        Access Granted
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {currentPageData.map((l, i) => (
                        <tr key={i}>
                        <th className="border-t-0 px-2 pl-4 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left">
                            {
                            l.user_id ?
                            <Link
                                to={`/users/edit/${l.user_id}`}
                                style={{ color: "black" }}
                            >
                                {l.firstname !== null && l.firstname}
                                {" "}
                                {l.lastname !== null && l.lastname}
                            </Link>
                            :
                            <>
                                {l.firstname !== null && l.firstname}
                                {" "}
                                {l.lastname !== null && l.lastname}
                            </>
                            }
                        </th>
                        <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                            {userTypes.map((type) =>
                            l.usertype_id === type.id ? type.name : ""
                            )}
                            {!l.user_id && l.firstname && "Visitor"}
                        </td>
                        <td
                            className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4"
                            style={{ color: l.direction === 1 ? "green" : "red" }}
                        >
                            {l.direction === 1 ? "In" : "Out"}
                        </td>
                        <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                            {l.acr_name && l.acr_name}
                        </td>
                        <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                            {new Date(l.event_at).toLocaleDateString()}{" "}
                            {new Date(l.event_at).toLocaleTimeString("en-GB")}
                        </td>
                        <td
                            className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4"
                            style={{ color: l.granted === 1 ? "green" : "red" }}
                        >
                            {l.granted === 0 ? `false, ${l.status}` : "true"}
                        </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <Loading loading={loading} />
                </IonCol>
            </IonRow>
            </IonGrid>
            <div style={{ margin: "0 6em" }}>
            <ReactPaginate
                previousLabel={"←"}
                nextLabel={"→"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
            />
            </div>
        </div>
        <Modal showModal={showModal} setShowModal={setShowModal}>
            <AddManualLogEntry acrList={acrList} />
        </Modal>
        <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            cssClass="my-custom-class"
            header={"Alert"}
            message={alertMessage}
            buttons={["OK"]}
        />
        </BaseTemplate>
    );
    }
