import axios from 'axios';

export const inovioApi = axios.create({
    baseURL: 'https://api.inovio.co.za/v1'
});

// https://api.inovio.co.za/v1
// http://devstage.restrive.co.za:3000/v1

export const inovioVmsHelper = axios.create({
    baseURL: 'https://inovio-vms-helper.herokuapp.com'
});