import React, { useState } from "react";
import BaseTemplate from "../../components/base/BaseTemplate";
import Modal from "../../components/Modal";
import PanicReportForm from "../../components/PanicReportForm";

const PANICS = [
  {
    unit: "111",
    status: "unapproved",
    responder: "Nkosi",
    report: "",
  },
  {
    unit: "2",
    status: "unapproved",
    responder: "Nkosi",
    report: "",
  },
  {
    unit: "23",
    status: "reported",
    responder: "Nkosi",
    report: "False Alarm",
  },
  {
    unit: "119",
    status: "unapproved",
    responder: "Nkosi",
    report: "",
  },
  {
    unit: "23",
    status: "acknowledged",
    responder: "Nkosi",
    report: "",
  },
  {
    unit: "34",
    status: "acknowledged",
    responder: "Nkosi",
    report: "False Alarm",
  },
];

export default function PanicManagement() {
  const [showModal, setShowModal] = useState(false);
  const [modalPanic, setModalPanic] = useState();

  function handlePanicClick(panic) {
    setShowModal(true);
    setModalPanic(panic);
  }

  return (
    <BaseTemplate>
      <h1
        style={{ margin: "1em auto 0 auto" }}
        className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold"
      >
        Panic Management
      </h1>
      <div className="roll-call">
        <div className="flex flex-wrap" style={{ marginTop: "3em" }}>
          {PANICS &&
            PANICS.map((panic, i) => (
              <div className="w-full py-2 px-4">
                <div
                  className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg"
                  style={{
                    backgroundColor:
                      panic.status === "unapproved"
                        ? "#fc5353"
                        : panic.status === "acknowledged"
                        ? "#ffb833"
                        : "#94db88",
                  }}
                >
                  <div
                    className="flex-auto p-4"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => handlePanicClick(panic)}
                  >
                    <div className="flex flex-wrap">
                      <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                        <h5
                          className="text-gray-500 uppercase font-bold text-xs"
                          style={{ color: "white" }}
                        >
                          {panic.status}
                        </h5>
                        <span
                          className="font-semibold text-xl text-gray-800"
                          style={{ color: "white" }}
                        >
                          UNIT {panic.unit}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          <Modal showModal={showModal} setShowModal={setShowModal}>
            <PanicReportForm panic={modalPanic} />
          </Modal>
        </div>
      </div>
    </BaseTemplate>
  );
}
