import React, { useState, useEffect } from "react";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonIcon,
  IonAlert,
  IonSpinner,
} from "@ionic/react";
import FullScreenModal from "../FullScreenModal";
import EnrollmentForm from "./EnrollmentForm";
import MorphoEnrollmentForm from "./MorphoEnrollmentForm";

import { getFingerprintSession, deleteUserCode, reassignCode, isValidDate } from "../../utils";
import { inovioApi } from "../../apis/inovio";
import { close } from "ionicons/icons";
import SearchUsersModal from "../SearchUsersModal/SearchUsersModal";
import MorphoSelectFingers from './MorphoSelectFingers';
import Modal from '../Modal';
import { morphoFollowup, morphoPoll, morphoInitiateSession, deleteMorphoCode } from '../../utils';

import {format} from 'date-fns';

export default function Enrollment({
  user,
  setShowLoading,
  email,
  userId,
  codes,
  setCodes,
  userTypeId,
  users,
  getSetUser
}) {
  const [showEnrollForm, setShowEnrollForm] = useState(false);
  const [showMorphoEnrollForm, setShowMorphoEnrollForm] = useState(false);
  const [fingerprints, setFingerprints] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [codeLoading, setCodeLoading] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [reassignedUser, setReassignedUser] = useState();
  const [showReassignedConfirmationAlert, setShowReassignedConfirmationAlert] = useState(false);
  const [reassignedCode, setReassignedCode] = useState();
  const [showMorpho, setShowMorpho] = useState(false);
  const [activeCodeType, setActiveCodeType] = useState(1);

  useEffect(() => {
    if(reassignedUser && reassignedCode) {
      reassignCode(userId, reassignedUser.user_id, parseInt(reassignedCode.code), reassignedUser.unit_id_arr[0]).then(res => {
        console.log(res);
        let tempCodes = [];
        for(let i = 0; i < codes.length; i++) {
          if(codes[i].code !== reassignedCode.code) {
            tempCodes.push(codes[i]);
          }
        }
        setCodes(tempCodes);
        setShowReassignedConfirmationAlert(true);
        // window.location.reload();
      });
    }
  }, [reassignedUser]);

  function handleButtonClick() {
    setShowLoading(true);
    getFingerprintSession()
      .then((res) => {
        setShowLoading(false);
        localStorage.setItem("inovio-fingerprint-session", JSON.stringify(res));
        console.log(res);
      })
      .then(() => setShowEnrollForm(true));
  }

  function handleMorphoClick() {
    setShowLoading(true);

    const ESTATE_ID = JSON.parse(localStorage.getItem("inovio-current-estate"))
    .estates_id;
    morphoInitiateSession(ESTATE_ID)
      .then((res) => {
        setShowLoading(false);
        localStorage.setItem("inovio-morpho-session", JSON.stringify(res));
        console.log(res);
      })
      .then(() => setShowMorphoEnrollForm(true));
  }

  function handleAddCodeClick(type) {
    setActiveCodeType(type);

    setCodes([...codes, { type, code: "", new: true }]);
  }

  function handleCodeChange(value, i) {
    let tempCodes = codes;
    tempCodes[i] = { type: activeCodeType, code: value };
    setCodes(tempCodes);
  }

  function clearLastCode() {
    let tempCodes = codes;
    tempCodes = tempCodes.filter((code, i) => i !== tempCodes.length - 1);
    console.log(tempCodes);
    setCodes(tempCodes);
  }

  function removeCode(code) {
    deleteUserCode(userId, code.code).then((res) => {
      console.log(res);
      getSetUser();
    });
  }

  function saveCodes(i, userCodes) {
    if (userCodes[userCodes.length - 1].code === "") {
      setAlertMessage("Code cannot be empty.");
      setShowAlert(true);
      clearLastCode();
      return;
    }
    if (userCodes[userCodes.length - 1].code.length > 13) {
      setAlertMessage("Code too long, code must be shorter than 13 characters.");
      setShowAlert(true);
      clearLastCode();
      return;
    }
    // if (userCodes[userCodes.length - 1].code.length < 13) {
    //   setAlertMessage("Code too short, code must be 4 characters long.");
    //   setShowAlert(true);
    //   clearLastCode();
    //   return;
    // }
    setCodeLoading(true);
    const editUser = {
      id: userId,
      email: email,
      codes: userCodes,
      usertype_id: userTypeId,
    };

    const token = localStorage.getItem("inovio-access-token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const ESTATE_ID = JSON.parse(localStorage.getItem("inovio-current-estate"))
      .estates_id;

    inovioApi
      .post(`/estate/${ESTATE_ID}/user`, editUser, config)
      .then((res) => {
        setCodeLoading(false);
        console.log(res);
        let codeExists;
        res.data.map((d) => {
          if (d == "code exists") {
            codeExists = true;
            console.log("code exists");
            setAlertMessage("Code already exists, please enter another code.");
            setShowAlert(true);
            let tempCodes = codes;
            tempCodes[i] = { code: "", type: activeCodeType, new: true };
            setCodes(tempCodes);
            return;
          }
        });
        if (!codeExists) {
          // let tempCodes = codes;
          // tempCodes[i] = { code: tempCodes[i].code, type: activeCodeType };
          // setCodes(tempCodes);
          getSetUser();
        }
      });
  }

  function handleReassignClick(code) {
    setShowUserModal(true);
    setReassignedCode(code);
  }

  function resolveCodeType(code) {
    switch(code) {
      case 1: return "Manual";
      case 2: return "Biostar Fingerprint";
      case 3: return "RFID";
      default: return "Morpho";
    }
  }

  function removeMorphoCode(code) {
    deleteMorphoCode(userId, code).then(res => {
      console.log(res);
      getSetUser();
    });
  }

  return (
    <div style={{ marginTop: "5em", marginBottom: "5em" }} id="enrollment">
      <IonItem>
        {codes.length > 0 || fingerprints.length > 0 ? (
          <h1 className="uppercase font-weight-900">Codes</h1>
        ) : (
          <h1 className="uppercase font-weight-900">No Codes Added</h1>
        )}
      </IonItem>
      {codes.map((code, i) => (
        <IonItem key={i}>
          <IonInput
            value={code.code}
            readonly={code.new ? false : true}
            onIonChange={(e) => handleCodeChange(e.detail.value, i)}
          ></IonInput>
          
          {!code.new && (
            <>
              <IonLabel className="bold">{(code.created_at && isValidDate(new Date(code.created_at))) ? format(new Date(code.created_at), 'DD/mm/YYYY HH:mm') : 'No date captured'}</IonLabel>
              <IonLabel className="ml-2">{resolveCodeType(code.type)}</IonLabel>
            </>
          )}

          {code.new && !codeLoading && (
            <IonButton slot="end" onClick={() => saveCodes(i, codes)}>
              save
            </IonButton>
          )}
          {code.new && codeLoading && <IonSpinner />}
          {code.type !== 4 && <IonButton
            style={{ margin: "1em" }}
            onClick={() => handleReassignClick(code)}
          >
            Reassign
          </IonButton>}
          <IonButton
            color="danger"
            style={{ marginLeft: "0.15em", cursor: "pointer" }}
            onClick={code.type === 4 ? () => removeMorphoCode(code.code) : () => removeCode(code)}
          >
            Delete
          </IonButton>
        </IonItem>
      ))}
      {fingerprints.length > 0 &&
        fingerprints.map((fingerprint, i) => (
          <IonItem key={i}>
            <IonInput value={fingerprint.code} readonly={true} />
            <IonLabel slot="end">Fingerprint</IonLabel>
          </IonItem>
        ))}
        
      <div style={{ float: "left", marginTop: "2em" }}>
        <IonButton
          style={{ marginRight: "1em" }}
          onClick={() => handleAddCodeClick(1)}
        >
          Add Manual Code
        </IonButton>
        <IonButton
          style={{ marginRight: "1em" }}
          onClick={() => handleAddCodeClick(3)}
        >
          Add RFID Code
        </IonButton>
        <IonButton onClick={handleButtonClick} style={{ marginRight: "1em" }}>
          Add Biostar Fingerprint
        </IonButton>
        <IonButton onClick={handleMorphoClick}>
          Enroll with Morpho
        </IonButton>
      </div>
      <FullScreenModal
        showModal={showEnrollForm}
        setShowModal={setShowEnrollForm}
      >
        <EnrollmentForm
          showModal={showEnrollForm}
          setShowModal={setShowEnrollForm}
          setFingerprints={setFingerprints}
          fingerprints={fingerprints}
          email={email}
          userId={userId}
          codes={codes}
          setCodes={setCodes}
          saveCodes={saveCodes}
        />
      </FullScreenModal>
      <FullScreenModal
        showModal={showMorphoEnrollForm}
        setShowModal={setShowMorphoEnrollForm}
      >
        <MorphoEnrollmentForm user={user} />
      </FullScreenModal>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        cssClass="my-custom-class"
        header={"Alert"}
        message={alertMessage}
        buttons={["OK"]}
      />
      <SearchUsersModal
        showModal={showUserModal}
        setShowModal={setShowUserModal}
        users={users}
        setUser={setReassignedUser}
        title="Select User to Reassign code to"
      />
      <IonAlert
        isOpen={showReassignedConfirmationAlert}
        onDidDismiss={() => setShowReassignedConfirmationAlert(false)}
        header={'Confirmation'}
        message={`Code reassigned to ${reassignedUser && reassignedUser.firstname} ${reassignedUser && reassignedUser.lastname} successfully`}
        buttons={['OK']}
      />
      <Modal showModal={showMorpho} setShowModal={setShowMorpho}>
          <MorphoSelectFingers getSetUser={getSetUser} />
      </Modal>
    </div>
  );
}
