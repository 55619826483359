import React, { useEffect } from "react";
import { downloadOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import Chart from 'chart.js';

export default function UserTypesBarChart({busyUserTypes}) {

  useEffect(() => {
    console.log(busyUserTypes);
    const ut = JSON.parse(localStorage.getItem('inovio-estate-info')).usertypes;

    ut.unshift({
      id: 0,
      name: "Visitor"
    });
    var barChartData = {
      labels: ut.map(type => type.name),
      datasets: [
        {
          backgroundColor: ["#ff8787", "#ffdd78", "#dbc0fa", "#a8e0e0", "#D8D8A0", "#F8CEAF", "#61569C", "#545D69"],
          borderWidth: 1,
          data: Object.values(busyUserTypes),
        },
      ],
    };

    var ctx = document.getElementById("usertypes-bar-chart").getContext("2d");
    var myBar = new Chart(ctx, {
      type: "bar",
      data: barChartData,
      options: {
        responsive: true,
        legend: {
          position: "top",
          display: false
        },
        title: {
          display: false,
          text: "Chart.js Bar Chart",
        },
      },
    });

    //Download Chart Image
    document
      .getElementById("download-usertypes-bar-chart")
      .addEventListener("click", function () {
        /*Get image of canvas element*/
        var url_base64jp = document
          .getElementById("usertypes-bar-chart")
          .toDataURL("image/jpg");
        /*get download button (tag: <a></a>) */
        var a = document.getElementById("download-usertypes-bar-chart");
        /*insert chart image url to download button (tag: <a></a>) */
        a.href = url_base64jp;
      });
  }, []);
  return (
    <div>
      <div
        className="px-4 md:px-10 mx-auto w-full -m-24"
        style={{
          marginTop: "6em",
          width: window.innerWidth > 990 ? "147%" : "100%",
        }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
            <div className="flex flex-col min-w-0 break-words w-full mb-6 shadow-xl rounded">
              <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h6 className="uppercase text-gray-600 mb-1 text-xs font-semibold">
                      Last 7 Days
                    </h6>
                    <a
                      id="download-usertypes-bar-chart"
                      download={`entries-per-user-type-for-week-${new Date().toLocaleDateString()}.jpg`}
                      className="float-right"
                      href=""
                    >
                      <IonIcon
                        icon={downloadOutline}
                        style={{ cursor: "pointer", margin: "0 0.5em", fontSize: 24 }}
                        className="text-gray-600"
                      ></IonIcon>
                    </a>
                    <h2 className=" text-xl font-semibold">
                      Access Per User Type
                    </h2>
                  </div>
                </div>
              </div>
              <div
                className="relative"
                style={{ height: "auto", paddingBottom: "1em" }}
              >
                <canvas id="usertypes-bar-chart"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
