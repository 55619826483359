import React, { useEffect, useState } from "react";
import { IonSpinner } from "@ionic/react";
import "./RollCall.css";
import { getInfo } from "../../utils";

export default function RollCall({
  data,
  colors,
  setShowModal,
  setModalTitle,
  setModalUsers,
}) {
  const [userTypes, setUserTypes] = useState();

  useEffect(() => {
    if (localStorage.getItem("inovio-estate-info")) {
      const ut = JSON.parse(localStorage.getItem("inovio-estate-info")).usertypes;
      console.log(ut);
      ut.push({
        id: 0,
        name: "Visitor"
      });
      setUserTypes(ut);
    } else {
      getInfo().then((res) => {
        const ut = res.usertypes
        console.log(ut);
        ut.push({
          id: 0,
          name: "Visitor"
        });
        setUserTypes(ut);
      });
    }
  }, []);

  function handleUserTypeClick(title, users) {
    setModalTitle(title);
    setModalUsers(users ? users : []);
    setShowModal(true);
  }

  function getTypeName(name) {
    const lastChar = name[name.length - 1];

    if(lastChar === 'y') {
      return name.replace('y', '') + 'ies';
    }

    return name + 's';
  }

  return (
    <div className="roll-call">
      <div className="flex flex-wrap" style={{ marginTop: "3em" }}>
        {userTypes &&
          userTypes.map((type, i) => (
            <div className="w-full lg:w-6/12 xl:w-3/12 px-4 py-3" key={i}>
              <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                <div
                  className="flex-auto p-4"
                  style={{
                    borderLeft: `4px solid ${colors[i]}`,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleUserTypeClick(
                      type.name + `s`,
                      data.users[String(type.id)]
                    )
                  }
                >
                  <div className="flex flex-wrap">
                    <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                      <h5 className="text-gray-500 uppercase font-bold text-xs">
                        {getTypeName(type.name)}
                      </h5>
                      <span className="font-semibold text-xl text-gray-800">
                        {data &&
                          (data.totals[String(type.id)] === undefined
                            ? 0
                            : data.totals[String(type.id)])}
                        {!data && <IonSpinner />}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      {/* <div
        className="shadow-xl"
        style={{
          width: "90%",
          margin: "auto",
          height: "110%",
          marginTop: "1em",
          overflowY: "auto",
        }}
      >
        <table
          className="items-center w-full bg-transparent border-collapse"
          style={{ zIndex: "99" }}
        >
          <thead>
            <tr>
              <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left"></th>
              <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                In
              </th>
              <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                Out
              </th>
              <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                %
              </th>
            </tr>
          </thead>
          <tbody>
            {userTypes &&
              userTypes.map((type, i) => (
                <tr>
                  <th
                    style={{ borderLeft: `4px solid ${colors[i]}` }}
                    className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left"
                  >
                    {type.name + 's'}
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                    {data &&
                      (data.totals[String(type.id)] === undefined
                        ? 0
                        : data.totals[String(type.id)])}
                    {!data && <IonSpinner />}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                    {users && data && (users.filter(user => user.usertype_id === type.id).length) - data.totals[String(type.id)]}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                    <i className="fas fa-arrow-up text-green-500 mr-4"></i>
                    {data && users && Math.floor(((data.totals[String(type.id)]) / (users.filter(user => user.usertype_id === type.id).length) * 100))}%
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div> */}
    </div>
  );
}
