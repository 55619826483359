import React from "react";
import {IonButton} from '@ionic/react';

export default function Approved({firstname, surname}) {
    return (
        <div
            style={{
                height: "100vh",
                width: "100vw",
                backgroundColor: "var(--ion-color-success)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <h1 style={{ color: '#fff' }}>{firstname} {surname} approved for entry.</h1>
            {/* <IonButton>Next</IonButton> */}
        </div>
    );
}
