import React from 'react';

import { useState, useRef } from 'react';
import _ from 'lodash';
import { cloudUpload } from 'ionicons/icons';
import {IonGrid, IonCol, IonRow, IonButton, IonIcon, IonInput, IonAlert} from '@ionic/react';
import { uploadDocument } from '../../utils';

const FileUpload = ({fetchFiles}) => {
    const filesRef = useRef(null);
    const [files, setFiles] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    function handleFilesClick() {
        console.log(filesRef);
        if(filesRef && filesRef.current) {
            filesRef.current.click();
        }
    }

    function handleFilesUpload(e) {
        e.persist();
        if(e && e.target.files && e.target.files.length > 0) {
            [].forEach.call(e.target.files, (file) => {
                const reader = new FileReader();
                reader.onload = function() {
                    const tempFiles = files;

                    if(typeof reader.result === 'string') {
                        tempFiles.push({
                            fullName: file.name,
                            size_bytes: file.size,
                            isLoading: false,
                            content: reader.result,
                            name: ''
                        });

                        console.log(tempFiles);
    
                        setFiles([...tempFiles]);
                    }
                };
                reader.onerror = function() {};

                reader.readAsDataURL(file);
            })
        }
    }

    function setFileDisplayName(index, str) {
        let tempFiles = files;

        tempFiles[index].name = str;
        
        setFiles([...tempFiles]);
    }

    function removeFile(fileName) {
        let tempFiles = files.filter((f) => f.fullName !== fileName);

        setFiles(tempFiles);
    }

    function upload() {
        if(files && files.length > 0) {

            const isValid = checkValidDisplayNames(files);

            if(!isValid) return;

            if(isValid) {
                files.forEach(async (f, i) => {
                    await uploadDocument(f.content.slice(28, f.content.length), f.fullName, f.name, f.size_bytes);
    
                    if((i + 1) === files.length) {
                        fetchFiles && fetchFiles();
    
                        setFiles([]);
                    }
                });
            }
        } else {
            setAlertMessage(`Please select at least one file to upload.`);
            setShowAlert(true);
        }
    }

    function checkValidDisplayNames(files) {
        let isValid = true;

        files.map(f => {
            if(!f.name) {
                setAlertMessage(`Please enter a display name for ${f.fullName}.`);
                setShowAlert(true);

                isValid = false;
            };
        });

        return isValid;
    }

    return (
        <div className="documents-form">
            <div className="documents-form-container">
                <input style={{visibility: 'hidden'}} multiple type="file" ref={filesRef} onChange={e => handleFilesUpload(e)} accept=".xls,.xlsx,.doc,.docx,.txt,.pdf,.ppt,.pptx" />
                <IonButton expand="full" onClick={handleFilesClick}><IonIcon icon={cloudUpload} slot="start" />Select file(s) to upload</IonButton>
                {files && (files.length > 0) && <IonGrid className="upload-documents">
                    {files.map((f, i) => (
                        <IonRow key={i} className={"document-upload-item"}>
                            <IonCol size-md="1" size-xs="1" className="flex-align-center">
                                <img className="doc-icon" src="/assets/icons/doc-icon-sml.svg" />
                            </IonCol>
                            <IonCol>
                                <IonInput value={f.name ? f.name : ''} onIonChange={e => setFileDisplayName(i, e.target.value)} className="text-left" placeholder="Enter Display Name" />
                            </IonCol>
                            <IonCol className="flex-align-center">
                                <p className="bold margin-none">{f.fullName}</p>
                            </IonCol>
                            <IonCol size-md="1" size-xs="1" className="flex-align-center cursor-pointer">
                                <img onClick={() => removeFile(f.fullName)} className="icon-16" src="/assets/icons/close.png" />
                            </IonCol>
                        </IonRow>
                    ))}
                </IonGrid>}
                {files && files.length > 0 && <div style={{height: 50}} />}
                {files && files.length > 0 && <IonButton disabled={!files || files.length === 0} onClick={upload} expand="full">Upload file(s)</IonButton>}
                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    cssClass="my-custom-class"
                    message={alertMessage}
                    buttons={["OK"]}
                />
        </div>
    </div>
    );
};

export default FileUpload;