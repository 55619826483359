import React, { useRef, useState, useEffect } from "react";
import {
  IonText,
  IonButton,
  IonItem,
  IonSelectOption,
  IonSelect,
  IonInput,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonFab,
  IonFabButton,
  IonIcon,
  IonToggle,
  IonList,
  IonDatetime,
} from "@ionic/react";
import BaseTemplate from "../../components/base/BaseTemplate";
import { arrowBackOutline } from "ionicons/icons";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { inovioApi } from "../../apis/inovio";
import { getUserInfo } from "../../utils";

import "./UserView.css";

function EditUser() {
  const [user, setUser] = useState();
  const firstnameRef = useRef();
  const lastnameRef = useRef();
  const emailRef = useRef();
  const cellRef = useRef();
  const [loading] = useState(false);
  const [mondayAccess, setMondayAccess] = useState();
  const [tuesdayAccess, setTuesdayAccess] = useState();
  const [wednesdayAccess, setWednesdayAccess] = useState();
  const [thursdayAccess, setThursdayAccess] = useState();
  const [fridayAccess, setFridayAccess] = useState();
  const [saturdayAccess, setSaturdayAccess] = useState();
  const [sundayAccess, setSundayAccess] = useState();
  // const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [userType, setUserType] = useState();
  const history = useHistory();
  const [grantAccessChecked, setGrantAccessChecked] = useState();
  const [receiveCallsChecked, setReceiveCallsChecked] = useState();
  const [manageUsersChecked, setManageUsersChecked] = useState();

  const users = JSON.parse(localStorage.getItem("inovio-users"));
  const units = JSON.parse(localStorage.getItem("inovio-units"));
  const [usersUnits, setUsersUnits] = useState([]);
  const [userCodes, setUserCodes] = useState([]);
  const [selectedExpiry, setSelectedExpiry] = useState();

  useEffect(() => {
    const id = window.location.pathname.replace("/users/", "");
    const user = users.find((user) => user.id === parseInt(id));
    const usersUnits = getUserUnits(user.unit_id_arr);
    setUsersUnits(usersUnits);
    setUser(user);
    setUserType(String(user.usertype_id));
    setGrantAccessChecked(user.right_access === 1);
    setManageUsersChecked(user.right_user === 1);
    setReceiveCallsChecked(user.right_calls === 1);

    getUserInfo(id).then((res) => {
      setUserCodes(res.codes);
    });
  }, []);

  function getUserUnits(unitIds) {
    const usersUnits = [];
    unitIds.map((id) => {
      units.map((unit) => {
        if (unit.id === id) {
          usersUnits.push(unit);
        }
      });
    });

    return usersUnits;
  }

  useEffect(() => {
    if (user) {
      setMondayAccess(String(user.day_mon_id));
      setTuesdayAccess(String(user.day_tue_id));
      setWednesdayAccess(String(user.day_wed_id));
      setThursdayAccess(String(user.day_thu_id));
      setFridayAccess(String(user.day_fri_id));
      setSaturdayAccess(String(user.day_sat_id));
      setSundayAccess(String(user.day_sun_id));
    }
  }, [user]);

  function handleSubmit(e) {
    e.preventDefault();

    const editUser = {
      id: user.id,
      firstname: firstnameRef.current.value,
      lastname: lastnameRef.current.value,
      email: emailRef.current.value,
      cellphone: cellRef.current.value,
      right_access: grantAccessChecked ? 1 : 0,
      right_calls: receiveCallsChecked ? 1 : 0,
      right_user: manageUsersChecked ? 1 : 0,
      usertype_id: userType,
      day_mon_id: parseInt(mondayAccess),
      day_tue_id: parseInt(tuesdayAccess),
      day_wed_id: parseInt(wednesdayAccess),
      day_thu_id: parseInt(thursdayAccess),
      day_fri_id: parseInt(fridayAccess),
      day_sat_id: parseInt(saturdayAccess),
      day_sun_id: parseInt(sundayAccess),
      expires_at: selectedExpiry,
    };

    const token = localStorage.getItem("inovio-access-token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    inovioApi
      .post("/estate/1/user", editUser, config)
      .then((res) => console.log(res));
  }

  return (
    <BaseTemplate>
      {user && (
        <div className="edit-user">
          <IonGrid>
            <IonRow>
              <IonCol>
                <div className="shadow-xl p-10">
                <h1
                  style={{ margin: "1em auto" }}
                  className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold"
                >
                  {user.firstname} {user.lastname}
                </h1>
                <form onSubmit={handleSubmit}>
                  {/* <div>
                      <label htmlFor="file-input">
                        <IonAvatar>
                          <img
                            src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                            alt="profile pic"
                          />
                        </IonAvatar>
                      </label>
                      <input
                        id="file-input"
                        style={{ display: "none" }}
                        type="file"
                        onChange={handleChange}
                      />
                    </div> */}
                  {/* <div style={{width: '100%', textAlign: 'center'}}>
                      <IonChip>
                        last inside: {new Date(user.lastIn).toLocaleDateString()}
                      </IonChip>
                    </div> */}
                  <IonItem>
                    <IonLabel position="floating">First Name</IonLabel>
                    <IonInput
                      ref={firstnameRef}
                      value={user.firstname}
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="floating">Last Name</IonLabel>
                    <IonInput
                      ref={lastnameRef}
                      value={user.lastname}
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="floating">Email</IonLabel>
                    <IonInput ref={emailRef} value={user.email}></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="floating">Cell</IonLabel>
                    <IonInput ref={cellRef} value={user.cellnumber}></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel>MM DD YY</IonLabel>
                    <IonDatetime
                      displayFormat="MM DD YY"
                      placeholder="Select Expiry"
                      value={selectedExpiry}
                      onIonChange={(e) => setSelectedExpiry(e.detail.value)}
                    ></IonDatetime>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Grant Access</IonLabel>
                    <IonToggle
                      checked={grantAccessChecked}
                      onIonChange={(e) =>
                        setGrantAccessChecked(e.detail.checked)
                      }
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel>Receive Calls</IonLabel>
                    <IonToggle
                      checked={receiveCallsChecked}
                      onIonChange={(e) =>
                        setReceiveCallsChecked(e.detail.checked)
                      }
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel>ManageUsers</IonLabel>
                    <IonToggle
                      checked={manageUsersChecked}
                      onIonChange={(e) =>
                        setManageUsersChecked(e.detail.checked)
                      }
                    />
                  </IonItem>
                  {userCodes.map((code, i) => (
                    <IonItem>
                      <IonLabel position="floating">Code {i + 1}</IonLabel>
                      <IonInput value={code.code}></IonInput>
                    </IonItem>
                  ))}
                  <IonItem>
                    <IonLabel>User Type</IonLabel>
                    <IonSelect
                      okText="Okay"
                      cancelText="Dismiss"
                      value={userType}
                      onIonChange={(e) => setUserType(e.detail.value)}
                    >
                      <IonSelectOption value="1">Resident</IonSelectOption>
                      <IonSelectOption value="2">Domestic</IonSelectOption>
                      <IonSelectOption value="3">Contractor</IonSelectOption>
                      <IonSelectOption value="4">Visitor</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Monday</IonLabel>
                    <IonSelect
                      okText="Okay"
                      cancelText="Dismiss"
                      value={mondayAccess}
                      onIonChange={(e) => setMondayAccess(e.detail.value)}
                    >
                      <IonSelectOption value="1">Full Day</IonSelectOption>
                      <IonSelectOption value="2">
                        Half Day - Morning
                      </IonSelectOption>
                      <IonSelectOption value="3">
                        Half Day - Afternoon
                      </IonSelectOption>
                      <IonSelectOption value="4">Working Hours</IonSelectOption>
                      <IonSelectOption value="5">Long Hours</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Tuesday</IonLabel>
                    <IonSelect
                      okText="Okay"
                      cancelText="Dismiss"
                      value={tuesdayAccess}
                      onIonChange={(e) => setTuesdayAccess(e.detail.value)}
                    >
                      <IonSelectOption value="1">Full Day</IonSelectOption>
                      <IonSelectOption value="2">
                        Half Day - Morning
                      </IonSelectOption>
                      <IonSelectOption value="3">
                        Half Day - Afternoon
                      </IonSelectOption>
                      <IonSelectOption value="4">Working Hours</IonSelectOption>
                      <IonSelectOption value="5">Long Hours</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Wednesday</IonLabel>
                    <IonSelect
                      okText="Okay"
                      cancelText="Dismiss"
                      value={wednesdayAccess}
                      onIonChange={(e) => setWednesdayAccess(e.detail.value)}
                    >
                      <IonSelectOption value="1">Full Day</IonSelectOption>
                      <IonSelectOption value="2">
                        Half Day - Morning
                      </IonSelectOption>
                      <IonSelectOption value="3">
                        Half Day - Afternoon
                      </IonSelectOption>
                      <IonSelectOption value="4">Working Hours</IonSelectOption>
                      <IonSelectOption value="5">Long Hours</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Thursday</IonLabel>
                    <IonSelect
                      okText="Okay"
                      cancelText="Dismiss"
                      value={thursdayAccess}
                      onIonChange={(e) => setThursdayAccess(e.detail.value)}
                    >
                      <IonSelectOption value="1">Full Day</IonSelectOption>
                      <IonSelectOption value="2">
                        Half Day - Morning
                      </IonSelectOption>
                      <IonSelectOption value="3">
                        Half Day - Afternoon
                      </IonSelectOption>
                      <IonSelectOption value="4">Working Hours</IonSelectOption>
                      <IonSelectOption value="5">Long Hours</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Friday</IonLabel>
                    <IonSelect
                      okText="Okay"
                      cancelText="Dismiss"
                      value={fridayAccess}
                      onIonChange={(e) => setFridayAccess(e.detail.value)}
                    >
                      <IonSelectOption value="1">Full Day</IonSelectOption>
                      <IonSelectOption value="2">
                        Half Day - Morning
                      </IonSelectOption>
                      <IonSelectOption value="3">
                        Half Day - Afternoon
                      </IonSelectOption>
                      <IonSelectOption value="4">Working Hours</IonSelectOption>
                      <IonSelectOption value="5">Long Hours</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Saturday</IonLabel>
                    <IonSelect
                      okText="Okay"
                      cancelText="Dismiss"
                      value={saturdayAccess}
                      onIonChange={(e) => setSaturdayAccess(e.detail.value)}
                    >
                      <IonSelectOption value="1">Full Day</IonSelectOption>
                      <IonSelectOption value="2">
                        Half Day - Morning
                      </IonSelectOption>
                      <IonSelectOption value="3">
                        Half Day - Afternoon
                      </IonSelectOption>
                      <IonSelectOption value="4">Working Hours</IonSelectOption>
                      <IonSelectOption value="5">Long Hours</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Sunday</IonLabel>
                    <IonSelect
                      okText="Okay"
                      cancelText="Dismiss"
                      value={sundayAccess}
                      onIonChange={(e) => setSundayAccess(e.detail.value)}
                    >
                      <IonSelectOption value="1">Full Day</IonSelectOption>
                      <IonSelectOption value="2">
                        Half Day - Morning
                      </IonSelectOption>
                      <IonSelectOption value="3">
                        Half Day - Afternoon
                      </IonSelectOption>
                      <IonSelectOption value="4">Working Hours</IonSelectOption>
                      <IonSelectOption value="5">Long Hours</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  <IonItem>
                    <p className="bg-gray-100 text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold text-left">
                      Units
                    </p>
                  </IonItem>
                  <IonList>
                    {usersUnits.map((unit) => (
                      <IonItem>
                        <IonLabel>{unit.name}</IonLabel>
                      </IonItem>
                    ))}
                  </IonList>
                  <IonButton
                    type="submit"
                    expand="block"
                    style={{ marginTop: "7em" }}
                    disabled={loading}
                  >
                    Update
                  </IonButton>
                </form>
                <div className="cancel-div">
                  <IonText>
                    <Link to="/">Cancel</Link>
                  </IonText>
                </div>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      )}
      <IonFab vertical="top" horizontal="start" slot="fixed">
        <IonFabButton onClick={() => history.goBack()}>
          <IonIcon icon={arrowBackOutline}></IonIcon>
        </IonFabButton>
      </IonFab>
    </BaseTemplate>
  );
}

export default EditUser;
