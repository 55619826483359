    import React, { useState, useEffect } from "react";
    import BaseTemplate from "./base/BaseTemplate";
    import { IonGrid, IonRow, IonCol, IonButton, IonCardContent, IonCard, IonSelect, IonSelectOption } from "@ionic/react";
    import { getVisitorInfo, handleVMSApprove } from '../utils';
    import Denied from "./Denied";
    import Approved from "./Approved";

    const reasons = ['Not at home', 'Not expecting a delivery', 'Busy', 'Other'];

    export default function Verify() {
    const [firstname, setFirstname] = useState("");
    const [surname, setSurname] = useState("");
    const [status, setStatus] = useState();
    const [reason, setReason] = useState();
    const [photo, setPhoto] = useState('none');

    useEffect(() => {
        const params = parseQueryString();
        console.log(params);
        setFirstname(params.firstname.replace('%20', ' '));
        setSurname(params.surname.replace('%20', ' '));
        const userid = 115;
        const visitorName = 'Chad Danker';
        if(params.approve) {
            setStatus('approved');
            handleVMSApprove('true', userid, params.firstname.replace('%20', ' ') + ' ' + params.surname.replace('%20', ' ')).then(res => {
                console.log(res);
            });
        }
        if(params.reject) {
            setStatus('denied');
            handleVMSApprove('false', userid, visitorName).then(res => {
                console.log(res);
            });
        }
        // const userInfo = JSON.parse(localStorage.getItem('inovio-user-info'));
        // below for demo purposes
        const userId = 115;
        const unitId = 16;

        console.log(userId, unitId);
        getVisitorInfo(userId, unitId).then(res => {
            console.log(res);
            let base64Photo = Buffer.from(res.photo.data).toString();

            console.log(base64Photo.slice(0, 10));
            setPhoto(base64Photo);
        });

    }, []);

    useEffect(() => {
        if(status === 'approved' || status === 'denied') {
            setPhoto('none');
        }
    }, [status]);

    function arrayBufferToBase64( buffer ) {
        var binary = '';
        var bytes = new Uint8Array( buffer );
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return window.btoa( binary );
    }

    const parseQueryString = function () {
        var str = window.location.search;
        var objURL = {};

        str.replace(
        new RegExp("([^?=&]+)(=([^&]*))?", "g"),
            function ($0, $1, $2, $3) {
                objURL[$1] = $3;
            }
        );
        return objURL;
    };

    function handleApproveClick() {
        setStatus('approved');
        handleVMSApprove('true', 115, `${firstname} ${surname}`).then(res => {
            console.log(res);
        });
    }

    function handleDenyClick() {
        setStatus('denied');
    }

    function handleSelectReason(reason) {
        handleVMSApprove('false', 115, `${firstname} ${surname}`, reason).then(res => {
            console.log(res);
        });
        setReason(reason);
    }

    return (
        <BaseTemplate>
        <div
            style={{
                position: "relative",
                height: "100vh",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundImage: 'url(data:image/png;base64,' + photo + ')',
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundPosition: "center",
                backgroundSize: "cover"
            }}
        >
            {!status && <>
                <IonCard style={{position: 'relative', top: '-12em'}}>
                    <IonCardContent>
                        <h1 className="uppercase">
                            {firstname} {surname}
                        </h1>
                    </IonCardContent>
                </IonCard>
                <div style={{ position: "absolute", bottom: "5em", width: "100%" }}>
                    <IonGrid>
                        <IonRow>
                        <IonCol offset="1" size="5">
                            <IonButton expand="full" onClick={() => handleApproveClick()}>Approve</IonButton>
                        </IonCol>
                        <IonCol size="5">
                            <IonButton expand="full" color="danger" onClick={() => handleDenyClick()}>
                            Deny
                            </IonButton>
                        </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
            </>}
            {status === 'denied' && !reason && <>
                <h3 style={{ margin: '1em' }}>Please select a reason for denying {firstname} {surname} entry.</h3>
                <IonSelect
                    value={reason}
                    okText="Okay"
                    cancelText="Dismiss"
                    onIonChange={(e) => handleSelectReason(e.detail.value)}
                    className="bg-gray-100 align-middle text-xs uppercase font-semibold text-left"
                    style={{width: '90%', marginTop: '1em'}}
                    placeholder="select reason"
                >
                {reasons &&
                    reasons.map((r, i) => (
                    <IonSelectOption
                        value={r}
                        key={i}
                        className="bg-gray-100 text-gray-600 align-middle text-xs uppercase font-semibold text-left"
                    >
                        {r}
                    </IonSelectOption>
                    ))}
                </IonSelect>
            </>}
            {reason && <Denied firstname={firstname} surname={surname} reason={reason} />}
            {status === 'approved' && <Approved firstname={firstname} surname={surname} />}
        </div>
        </BaseTemplate>
    );
    }
