import React from "react";

export default function HelpVideo({ videoId }) {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <iframe
        title="help-video"
        width="50%"
        height="500"
        style={{ margin: "0 auto" }}
        src={`https://www.youtube.com/embed/${videoId}`}
        className="shadow-xl"
      ></iframe>
    </div>
  );
}
