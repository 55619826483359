import React, { useState } from "react";
import {
  IonButton,
  IonItem,
  IonSelectOption,
  IonSelect,
  IonInput,
  IonLabel,
  IonFab,
  IonFabButton,
  IonIcon,
} from "@ionic/react";
import BaseTemplate from "../../components/base/BaseTemplate";
import "./NewEstate.css";
import { arrowBackOutline } from "ionicons/icons";
import { useHistory } from "react-router-dom";

export default function AddNewItem() {
  const [loading] = useState(false);
  const history = useHistory();

  function handleSubmit(e) {
    e.preventDefault();
  }

  return (
    <BaseTemplate>
      <div style={{ margin: "1em" }} className="shadow-xl p-10">
        <h1
          style={{ textAlign: "center", marginBottom: "2em" }}
          className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold"
        >
          New Estate
        </h1>
        <form onSubmit={handleSubmit}>
          <IonItem>
            <IonLabel position="floating">Name</IonLabel>
            <IonInput></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Address</IonLabel>
            <IonInput></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>Select Estate Manager</IonLabel>
            <IonSelect value={"admin-1"} okText="Okay" cancelText="Dismiss">
              <IonSelectOption value="admin-1">Admin 1</IonSelectOption>
              <IonSelectOption value="admin-2">Admin 2</IonSelectOption>
              <IonSelectOption value="admin-3">Admin 3</IonSelectOption>
              <IonSelectOption value="admin-4">Admin 4</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonButton
            type="submit"
            expand="block"
            style={{ marginTop: "7em" }}
            disabled={loading}
          >
            Add
          </IonButton>
        </form>
      </div>
      <IonFab vertical="top" horizontal="start" slot="fixed">
        <IonFabButton onClick={() => history.goBack()}>
          <IonIcon icon={arrowBackOutline}></IonIcon>
        </IonFabButton>
      </IonFab>
    </BaseTemplate>
  );
}
