import React from 'react';
import { IonItem, IonTextarea, IonCard } from '@ionic/react';
import BaseTemplate from '../../components/base/BaseTemplate';

export default function EstateConfig() {
    return (
        <BaseTemplate title="Estate Config">
            <div style={{padding: '1em', textAlign: 'center'}}>
                <h1 style={{ margin: '1em auto' }} className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                    Estate Config
                </h1>
                <form>
                    <IonCard>
                        <IonItem>
                            <IonTextarea></IonTextarea>
                        </IonItem>
                    </IonCard>
                </form>
            </div>
        </BaseTemplate>
    )
}
