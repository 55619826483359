import React from 'react';
import { BarcodeScanner } from '@ionic-native/barcode-scanner';
import { IonButton } from '@ionic/react';

const Scanner = () => {
  const openScanner = async () => {
    const data = await BarcodeScanner.scan();
    console.log(`Barcode data: ${data.text}`);
  };
  return (
    <IonButton onClick={openScanner}>Scan barcode</IonButton>
  );
};


export default Scanner;