import React, { useState, useEffect } from "react";
import {
  IonMenu,
  IonIcon,
  IonContent,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import {
  homeOutline,
  fileTrayFullOutline,
  listCircleOutline,
  peopleOutline,
  informationOutline,
  calendarOutline,
  personAddOutline
} from "ionicons/icons";
import InstallButton from "../InstallButton/InstallButton";
import "./Menu.css";
import { getCurrentEstate, getInfo } from "../../utils";

function Menu({ isAdmin }) {
  const [currentEstate, setCurrentEstate] = useState(null);
  const [currentUnit, setCurrentUnit] = useState();
  const [estates, setEstates] = useState();
  const isLoggedIn = localStorage.getItem("inovio-current-user");

  const [estateInfo, setEstateInfo] = useState();

  useEffect(() => {
      if(localStorage.getItem('inovio-current-estate')) {
          setEstateInfo(JSON.parse(localStorage.getItem('inovio-current-estate')));
        } else {
          // getCurrentEstate().then(current => {
          //     setEstateInfo(current[0]);
          // });
        }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("inovio-current-estate") && localStorage.getItem("inovio-current-estate") !== "undefined") {
      setCurrentEstate(
        JSON.parse(localStorage.getItem("inovio-current-estate")).estates_id
      );
    } else {
      if (localStorage.getItem("inovio-current-user")) {
        getCurrentEstate().then((res) => {
          setEstates(res);
          setCurrentEstate(res[0].estates_id);
        });
      }
    }
    if (localStorage.getItem("inovio-estates")) {
      setEstates(JSON.parse(localStorage.getItem("inovio-estates")));
    }

    if(!isAdmin) {
      if(localStorage.getItem('inovio-user-info') && JSON.parse(localStorage.getItem('inovio-user-info'))) {
        const userInfo = JSON.parse(localStorage.getItem('inovio-user-info'));
        const currentUnitId = userInfo.units.ids[0];
        const current = userInfo.units[currentUnitId].unit[0].name;
        setCurrentUnit(current);
      } else {
        setTimeout(() => {
          if(localStorage.getItem('inovio-user-info') && JSON.parse(localStorage.getItem('inovio-user-info'))) {
            const userInfo = JSON.parse(localStorage.getItem('inovio-user-info'));
            if(userInfo.units) {
              const currentUnitId = userInfo.units.ids[0];
              const current = userInfo.units[currentUnitId].unit[0].name;
              setCurrentUnit(current);
            }
          }
        }, 2000)
      }

    }
  }, []);

  function handleSelectChange(e) {
    const currEstate = estates.find(
      (estate) => estate.estates_id === e.detail.value
    );
    console.log(currEstate);
    localStorage.setItem("inovio-current-estate", JSON.stringify(currEstate));
    setCurrentEstate(e.detail.value);
    getInfo().then((res) => {
      localStorage.setItem("inovio-estate-info", JSON.stringify(res));
      window.location.href = "/";
    });
  }

  function handleSelectUnitChange(e) {
    setCurrentUnit(e.detail.value);
  }

  return (
    <>
      {isLoggedIn && (
        <IonMenu side="start" type="overlay" contentId="main" id="menu">
          <div style={{ textAlign: "center" }}>
            <img
              src="/assets/inovio-logo.webp"
              alt="inovio logo"
              style={{ width: "60%", margin: "1em auto" }}
            />
          </div>
          <IonContent
            style={{
              position: "relative",
              "--background": "var(--ion-primary-color)",
            }}
          >
            {isAdmin && <IonSelect
              value={currentEstate}
              okText="Okay"
              cancelText="Dismiss"
              onIonChange={(e) => handleSelectChange(e)}
              className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
            >
              {estates &&
                estates.map((estate) => (
                  <IonSelectOption
                    value={estate.estates_id}
                    key={estate.estates_id}
                    className="bg-gray-100 text-gray-600 align-middle text-xs uppercase font-semibold text-left"
                  >
                    {estate.estates_name}
                  </IonSelectOption>
                ))}
            </IonSelect>}
            {!isAdmin && <IonSelect
              value={currentUnit}
              okText="Okay"
              cancelText="Dismiss"
              onIonChange={(e) => handleSelectUnitChange(e)}
              className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
            >
              <IonSelectOption value={currentUnit} key={currentUnit} className="bg-gray-100 text-gray-600 align-middle text-xs uppercase font-semibold text-left">
                UNIT {currentUnit ? currentUnit : 'loading...'}
              </IonSelectOption>
            </IonSelect>}
            <IonList style={{ width: "100%" }}>
              {isAdmin && (
                <>
                  <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink="/dashboard"
                  >
                    <IonIcon slot="start" icon={listCircleOutline}></IonIcon>
                    Dashboard
                  </IonItem>
                  <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink="/logs"
                  >
                    <IonIcon slot="start" icon={fileTrayFullOutline}></IonIcon>
                    Logs
                  </IonItem>
                  <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink="/users"
                  >
                    <IonIcon slot="start" icon={peopleOutline}></IonIcon>
                    Users
                  </IonItem>
                  <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink="/units"
                  >
                    <IonIcon slot="start" icon={homeOutline}></IonIcon>
                    Units
                  </IonItem>
                  <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink="/bookings/manage"
                  >
                    <IonIcon slot="start" icon={calendarOutline}></IonIcon>
                    Bookings
                  </IonItem>
                  {estateInfo && estateInfo.estates_address && <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink="/estate-info"
                  >
                    <IonIcon slot="start" icon={informationOutline}></IonIcon>
                    Estate Info
                  </IonItem>}
                </>
              )}
              {!isAdmin && (
                <>
                  <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink="/"
                  >
                    <IonIcon slot="start" icon={homeOutline}></IonIcon>
                    Home
                  </IonItem>
                  <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink="/my-users"
                  >
                    <IonIcon slot="start" icon={peopleOutline}></IonIcon>
                    Users
                  </IonItem>
                  <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink="/bookings"
                  >
                    <IonIcon slot="start" icon={calendarOutline}></IonIcon>
                    Bookings
                  </IonItem>
                  <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink={currentUnit ? `/logs/${currentUnit}` : `/logs/unit`}
                  >
                    <IonIcon slot="start" icon={fileTrayFullOutline}></IonIcon>
                    Logs
                  </IonItem>
                  {/* <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink="/my-units"
                  >
                    <IonIcon slot="start" icon={homeOutline}></IonIcon>
                    Units
                  </IonItem> */}
                  {/* <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink="/panic-management"
                  >
                    <IonIcon slot="start" icon={alertCircleOutline}></IonIcon>
                    Panics
                  </IonItem> */}
                  <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink="/new-visitor"
                  >
                    <IonIcon slot="start" icon={personAddOutline}></IonIcon>
                    New Visitor
                  </IonItem>
                </>
              )}
            </IonList>
            <InstallButton />
          </IonContent>
        </IonMenu>
      )}
    </>
  );
}

export default Menu;
