import React, { useRef, useState, useEffect } from 'react';
import { IonCard, IonText, IonButton, IonCardContent, IonItem, IonInput, IonLabel, IonGrid, IonRow, IonCol, IonAlert } from '@ionic/react';
import { useAuth } from '../../contexts/AuthContext';
import { getInfo, getCurrentEstate } from '../../utils';
import { Link } from 'react-router-dom'; 
import './Login.css';

export default function Login({ setChangingPage }) {
    const emailRef = useRef();
    const passwordRef = useRef();
    const { login } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    function alertDismiss() {
        setChangingPage(false);
        setError('');
    }

    useEffect(() => {
        localStorage.clear();
        document.addEventListener("keydown", e => {
            if(e.which === 13) {
                handleSubmit();
            }
        });
    }, []);

    useEffect(() => {
        setChangingPage(false);
    }, [setChangingPage]);

    async function handleSubmit(e) {
        if(e) {
            e.preventDefault();
        }
        setError('');

        try {
            const res = await login(emailRef.current.value, passwordRef.current.value);
            console.log(res);
            setLoading(true);
            // changed to href to fix menu display issue
            getCurrentEstate().then(res => {
                console.log(res);
                
                getInfo().then((res) => {
                    console.log(res.data);
                    // window.OneSignal.setEmail(emailRef.current.value);
                    window.location.href = "/";
                });
            });
            // history.push('/dashboard');
        } catch {
            setError('failed to sign in');
        }
        setLoading(false);
    }

    return (
        <div className="login">
            <IonGrid>
                <IonRow style={{boxShadow: 'none'}}>
                    <IonCol offset-md="3" size-md="6">
                        <IonCard className="shadow-xl">
                            <IonCardContent>
                                <div style={{ textAlign: "center", marginBottom: '1.5em' }}>
                                    <img
                                    src="/assets/inovio-logo.png"
                                    alt="inovio logo"
                                    style={{ width: "60%", margin: "1em auto" }}
                                    />
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <IonItem>
                                        <IonLabel position="floating">
                                            Email
                                        </IonLabel>
                                        <IonInput type="email" required ref={emailRef} autocomplete="off">
                                        </IonInput>
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel position="floating">
                                            Password
                                        </IonLabel>
                                        <IonInput type="password" required ref={passwordRef} autocomplete="off">
                                        </IonInput>
                                    </IonItem>
                                    <IonButton onClick={() => setChangingPage(true)} type="submit" expand="full" style={{marginTop: '5em'}} disabled={loading}>
                                        Login
                                    </IonButton>
                                </form>
                                <div className="forgot-password-div">
                                    <IonText>
                                        <Link to="/forgot-password">forgot password?</Link>
                                    </IonText>
                                </div>
                            </IonCardContent>                       
                        </IonCard>
                        {/* <br /> */}
                        {/* <div className="sign-up-div">
                            <IonText>
                                Need an account? <Link to="/signup">Sign Up</Link>
                            </IonText>
                        </div> */}
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonAlert
                isOpen={error !== ''}
                onDidDismiss={() => alertDismiss()}
                header={'Error'}
                message={error}
                buttons={['OK']}
            />
        </div>
    )
}
