import './MorphoSelectFingers.css';

import React, { useState, useEffect } from 'react';
import { IonGrid, IonCol, IonRow, IonButton } from '@ionic/react';
import { morphoFollowup, morphoPoll } from '../../utils';

export default function MorphoSelectFingers({reader, user, setIsEnrolling, setIsComplete, setError, getSetUser}) {
    const [leftHand, setLeftHand] = useState({
        index: 0,
        middle: 0,
        ring: 0,
        pinky: 0,
    });
    const [rightHand, setRightHand] = useState({
        index: 0,
        middle: 0,
        ring: 0,
        pinky: 0,
    });

    function handleChange(key, hand) {
        if(hand === 'left') {
            let temp = leftHand;

            switch(true) {
                case leftHand[key] === 0: { temp[key] = 1; break; };
                case leftHand[key] === 1: { temp[key] = 2; break; };
                case leftHand[key] === 2: { temp[key] = 0; break; };
            }
    
            setLeftHand({...temp});
        }

        if(hand === 'right') {
            let temp = rightHand;

            switch(true) {
                case rightHand[key] === 0: { temp[key] = 1; break; };
                case rightHand[key] === 1: { temp[key] = 2; break; };
                case rightHand[key] === 2: { temp[key] = 0; break; };
            }
    
            setRightHand({...temp});
        }

    }

    function onDoneClick() {
        // estateId, sessionId, firstName, lastName, morphoId, hands, userId, leftFingers, rightFingers

        const estateId = JSON.parse(localStorage.getItem("inovio-current-estate"))
        .estates_id;

        const sessionId = JSON.parse(localStorage.getItem("inovio-morpho-session"))
        .sessionId;

        const code = JSON.parse(localStorage.getItem("inovio-morpho-session"))
        .code;

        const body = {
            estateId,
            sessionId,
            firstName: user.firstname,
            lastName: user.lastname,
            morphoId: reader.id,
            code,
            hands: 2,
            userId: user.user_id,
            leftFingers: [leftHand.pinky, leftHand.ring, leftHand.middle, leftHand.index],
            rightFingers: [rightHand.index, rightHand.middle, rightHand.ring, rightHand.pinky]
        };

        morphoFollowup(body)
        .then(res => {
            console.log('------------>MORPHO FOLLOW UP', res);

            setIsEnrolling(true);
            const iId = setInterval(() => {
                morphoPoll(sessionId)
                .then(res => {
                    console.log(res);
                    if(res && res.status && res.status !== "started") {
                        // setIsEnrolling(false);
                        clearInterval(iId);

                        if(res.status === "Process succeeded") {
                            setIsComplete(true);
                            getSetUser();
                        } else {
                            setError(res.status);
                        }
                    }

                    console.log('----------> POLL RES', res);
                });
            }, 1000);

            // setTimeout(() => {
            //     clearInterval(iId);
            // }, 50000);
        })
        .catch(err => {
            console.log(err);
        });
    }

    return (
        <div>
            <IonGrid style={{paddingBottom: '1em'}}>
                <IonRow>
                    <IonCol>
                        <p>Click on a finger to mark it as amputated(red) or bandaged(yellow)</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol offset="1">
                    <div style={{width: '70%', height: 'auto'}}>
                        <svg
                            id="Layer_1"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 612 707.81"
                        >
                            <path
                                className="cls-1"
                                d="M530.29,444.77s-50.76,76.48-75.8,157-121.16,97.46-121.16,97.46-155.67,21.66-192.21-96.78-44-176-44-176L84.94,389.27s44.67,9.47,49.41-30.46c0,0,27.07,18.27,16.92-29.78,0,0,42.64,11.5,52.11-29.11,0,0,21.66,36.55,15.57-12.18,0,0,42,34.52,65.65-4.06,0,0,16.25,56.85,31.81,0,0,0,35.2,39.93,72.42,0l.68,86.63S397,491.47,470.73,400.1C470.73,400.1,474.79,454.92,530.29,444.77Z"
                            />
                            <path
                                className="cls-1"
                                d="M528.78,447.09l63.57-113.94s20.3-50.76-20.31-37.23-49.41,33.17-49.41,33.17l-53.47,73.1S471.25,457.24,528.78,447.09Z"
                            />
                            <path
                                onClick={_ => handleChange('index', 'left')}
                                className={`cls-1 green ${leftHand.index === 2 ? 'red' : leftHand.index === 1 ? 'yellow' : 'green'} pointer`}
                                d="M389,286.78V60.84a52.12,52.12,0,0,0-11.44-33c-8.06-9.89-19.82-16.07-33.35,2.32a59.19,59.19,0,0,0-11.13,30.72L315.84,285.72S351.09,327.05,389,286.78Z"
                            />
                            <path
                                onClick={_ => handleChange('middle', 'left')}
                                className={`cls-1 green ${leftHand.middle === 2 ? 'red' : leftHand.middle === 1 ? 'yellow' : 'green'} pointer`}
                                d="M285.31,286l-49-256.21s-9.14-26.39-30-20.3c0,0-22.34-1-24.37,28.93S218.9,288,218.9,288,260.44,325.88,285.31,286Z"
                            />
                            <path
                                onClick={_ => handleChange('ring', 'left')}
                                className={`cls-1 green ${leftHand.ring === 2 ? 'red' : leftHand.ring === 1 ? 'yellow' : 'green'} pointer`}
                                d="M206,305.61,108.29,93.67A30.16,30.16,0,0,0,98.89,82c-12-8.89-36.08-21-34.55,26.8,2,63.46,89.51,225.9,89.51,225.9S197.84,346.56,206,305.61Z"
                            />
                            <path
                                onClick={_ => handleChange('pinky', 'left')}
                                className={`cls-1 ${leftHand.pinky === 2 ? 'red' : leftHand.pinky === 1 ? 'yellow' : 'green'} pointer`}
                                d="M17.35,218.6S37,204.9,62.46,247.54s72.66,112.52,72.66,112.52S133.76,399,85.71,390.52L17.35,253.12S7.7,225.2,17.35,218.6Z"
                            />
                        </svg>
                    </div>
                    </IonCol>
                    <IonCol>
                    <div style={{width: '70%', height: 'auto'}}>
                            <svg
                                id="Layer_1"
                                data-name="Layer 1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 612 707.81"
                            >
                                <path
                                    className="cls-1"
                                    d="M80.19,444.77S131,521.25,156,601.79s121.15,97.46,121.15,97.46,155.67,21.66,192.22-96.78,44-176,44-176l12.19-37.22s-44.68,9.47-49.41-30.46c0,0-27.08,18.27-16.92-29.78,0,0-42.64,11.5-52.12-29.11,0,0-21.66,36.55-15.57-12.18,0,0-42,34.52-65.65-4.06,0,0-16.24,56.85-31.81,0,0,0-35.2,39.93-72.42,0L221,370.31s-7.44,121.16-81.22,29.79C139.75,400.1,135.69,454.92,80.19,444.77Z"
                                />
                                <path
                                    className="cls-1"
                                    d="M81.7,447.09,18.14,333.15s-20.31-50.76,20.3-37.23,49.41,33.17,49.41,33.17l53.47,73.1S139.23,457.24,81.7,447.09Z"
                                />
                                <path
                                    onClick={_ => handleChange('index', 'right')}
                                    className={`cls-1 ${rightHand.index === 2 ? 'red' : rightHand.index === 1 ? 'yellow' : 'green'} pointer`}
                                    d="M221.49,286.78V60.84a52.11,52.11,0,0,1,11.43-33C241,18,252.74,11.77,266.27,30.16a59.11,59.11,0,0,1,11.14,30.72l17.23,224.84S259.39,327.05,221.49,286.78Z"
                                />
                                <path
                                    onClick={_ => handleChange('middle', 'right')}
                                    className={`cls-1 ${rightHand.middle === 2 ? 'red' : rightHand.middle === 1 ? 'yellow' : 'green'} pointer`}
                                    d="M325.17,286l49-256.21s9.14-26.39,30-20.3c0,0,22.34-1,24.37,28.93S391.58,288,391.58,288,350.05,325.88,325.17,286Z"
                                />
                                <path
                                    onClick={_ => handleChange('ring', 'right')}
                                    className={`cls-1 ${rightHand.ring === 2 ? 'red' : rightHand.ring === 1 ? 'yellow' : 'green'} pointer`}
                                    d="M404.52,305.61,502.19,93.67A30.16,30.16,0,0,1,511.59,82c12-8.89,36.08-21,34.56,26.8-2,63.46-89.52,225.9-89.52,225.9S412.64,346.56,404.52,305.61Z"
                                />
                                <path
                                    onClick={_ => handleChange('pinky', 'right')}
                                    className={`cls-1 ${rightHand.pinky === 2 ? 'red' : rightHand.pinky === 1 ? 'yellow' : 'green'} pointer`}
                                    d="M593.14,218.6S573.48,204.9,548,247.54s-72.66,112.52-72.66,112.52,1.35,38.92,49.41,30.46l68.36-137.4S602.78,225.2,593.14,218.6Z"
                                />
                        </svg>
                    </div>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonGrid style={{width: '25%', textAlign: 'left', margin: 0, paddingLeft: '2em'}}>
                            <IonRow>
                                <IonCol size="2" style={{textAlign: 'left'}}>
                                    <div style={{width: 20, height: 20, backgroundColor: 'var(--ion-color-success)'}} />
                                </IonCol>
                                <IonCol style={{textAlign: 'left'}}>
                                    Enroll finger
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="2" style={{textAlign: 'left'}}>
                                    <div style={{width: 20, height: 20, backgroundColor: 'var(--ion-color-danger)'}} />
                                </IonCol>
                                <IonCol style={{textAlign: 'left'}}>
                                    Amputated
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="2" style={{textAlign: 'left'}}>
                                    <div style={{width: 20, height: 20, backgroundColor: 'var(--ion-color-warning)'}} />
                                </IonCol>
                                <IonCol style={{textAlign: 'left'}}>
                                    Bandaged
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonButton style={{width: 300}} onClick={onDoneClick}>Done</IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}
