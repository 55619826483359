import React from 'react';

import BaseTemplate from '../../components/base/BaseTemplate';

import EstateMap6 from './maps/6/EstateMap6';

import './EstateMap.css';

export default function EstateMap({onlyComponent}) {
    const estateId = JSON.parse(localStorage.getItem('inovio-current-estate')).estates_id;

    const EstateMaps = {
        1: <div />,
        2: <div />,
        3: <div />,
        4: <div />,
        5: <div />,
        6: <EstateMap6 />,
        7: <div />,
        8: <div />
    };

    if(onlyComponent) return EstateMaps[estateId];

    return (
        <BaseTemplate>
            {EstateMaps[estateId]}
        </BaseTemplate>
    )
}
