import React, { useState } from 'react';
import { IonItem, IonLabel, IonInput, IonButton, IonGrid, IonCol, } from '@ionic/react';

export default function UpdateEmailForm({email, setEmail, setShowModal}) {
    const [password, setPassword] = useState();

    function handleSubmit(e) {
        e.preventDefault();

        setShowModal(false);
    }

    return (
    <div className="px-10 py-2" style={{height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5em'}}>
    <h1
        style={{ textAlign: "center", margin: "0 auto 1em auto" }}
        className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold"
    >
        Update Email
    </h1>
    <IonGrid style={{width: "100%", height: '100%' }}>
        <IonCol size="8" offset="2">
            <form onSubmit={handleSubmit} style={{ marginTop: "1em" }}>
                    <IonItem>
                        <IonLabel position="floating">Email</IonLabel>
                        <IonInput
                            value={email}
                            onIonChange={e => setEmail(e.detail.value)}
                        ></IonInput>
                    </IonItem>
                    <IonButton
                        expand="block"
                        style={{ marginTop: "7em" }}
                        type="submit"
                    >Update</IonButton>
            </form>
        </IonCol>
    </IonGrid>
    </div>
    )
}
