import React, { useState, useEffect } from "react";
import MemberAccessOverLastWeekPieChart from "../../components/charts/MemberAccessOverLastWeekPieChart";
import RollCall from "../../components/RollCall/RollCall";
import BaseTemplate from "../../components/base/BaseTemplate";
import MemberAccessPerDayPerPeriodBarChart from "../../components/charts/MemberAccessPerDayPerPeriodBarChart";
import UserTypesBarChart from "../../components/charts/UserTypesBarChart";
import UserTypeCurrentlyIn from "../../components/userTypes/UserTypeCurrentlyIn";
import Modal from "../../components/Modal";
import Loading from "../../components/Loading";

import {IonIcon, IonButton} from '@ionic/react';
import {informationCircleOutline} from 'ionicons/icons';

import {Link} from 'react-router-dom';

import {
  getBusyDays,
  getBusyUserTypes,
  getUsersIn,
  getUsers,
  checkToken
} from "../../utils";

function Dashboard() {
  const [busyDays, setBusyDays] = useState();
  const [busyUserTypes, setBusyUserTypes] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalUsers, setModalUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [colors, setColors] = useState(["#ffdd78", "#dbc0fa", "#a8e0e0", "#D8D8A0", "#F8CEAF", "#61569C", "#545D69", "#ff8787"]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getUsersIn()
    .then((res) => {
      setData(res);
    });

    getBusyDays()
    .then((res) => {
      setBusyDays(res);
    });

    getBusyUserTypes()
    .then((res) => {
      setBusyUserTypes(res);
      setLoading(false);
    });

    getUsers()
    .then((users) => {
      const sortedUsers = users.sort(function(a, b) {
        var textA = a.firstname.toUpperCase();
        var textB = b.firstname.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      const usersWithExpiredField = sortedUsers.map((u, i) => {
        // if(i === 0) console.log(u);
        const today = new Date();
        const monthAhead = today.setDate(today.getDate() + 30);

        return {
          ...u,
          isExpired: u.expires_at && (new Date(u.expires_at) <= new Date()),
          upcomingExpired: u.expires_at && ((new Date(u.expires_at) < new Date(monthAhead)) && (new Date(u.expires_at) > new Date())),
        };
      });

      // console.log(usersWithExpiredField)

      const expiredUsers = getExpiredUsers(usersWithExpiredField);
      console.log(expiredUsers);

      localStorage.setItem("inovio-users", JSON.stringify([...usersWithExpiredField]));
      setUsers([...usersWithExpiredField]);
    })
    .catch(err => {
      checkToken(localStorage.getItem('inovio-access-token'));
    });

    window.OneSignal.push(function() {
      const { email } = JSON.parse(localStorage.getItem("inovio-current-user"));
      window.OneSignal.setEmail(email)          
          .then(function(emailId) {
              // Callback called when email have finished sending
              console.log("emailId: ", emailId);  
          }); 
    });
  }, []);
  
  function getExpiredUsers(usersArr) {
    if(!usersArr || (usersArr && !Array.isArray(usersArr)) || (usersArr && Array.isArray(usersArr) && usersArr.length === 0)) return [];

    return usersArr.filter(u => u.isExpired);
  }

  return (
    <BaseTemplate>
      <RollCall
        data={data}
        colors={colors}
        users={users}
        setShowModal={setShowModal}
        setModalTitle={setModalTitle}
        setModalUsers={setModalUsers}
      />
      {loading && <Loading loading={loading} />}
      <div
        className="px-4 md:px-10 mx-auto w-full"
        style={{
          marginTop: "0em",
          width: window.innerWidth > 990 ? "147%" : "100%",
        }}
      >
      <div className="flex flex-wrap">
          <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
            <div className="flex flex-col min-w-0 break-words w-full mb-6 shadow-xl rounded">
              <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    {users && Array.isArray(users) && !loading && (users.filter(u => u.upcomingExpired).length === 0) ? (
                      <div className="flex items-center my-5">
                        <IonIcon size="large" color="medium" icon={informationCircleOutline}></IonIcon>
                        {/* <h1 className="text-3xl text-gray-500 text-left ml-2">No epiries in the next 30 days.</h1> */}
                        <h2 className="text-gray-500 text-xl font-semibold ml-2">
                          No epiries in the next 30 days.
                        </h2>
                      </div>
                    ) : !loading ? (
                      <div className="flex items-center my-5">
                        {/* <IonIcon size="large" color="medium" icon={informationCircleOutline}></IonIcon> */}
                        <h2 className=" text-xl font-semibold">
                          Upcoming expiries (30 days)
                        </h2>
                      </div>
                    ) : ''}
                    {users && Array.isArray(users) && users.filter(u => u.upcomingExpired).map(u => (
                      <div className="w-full flex h-10 items-center">
                        {u.firstname} {u.lastname}
                        <Link 
                          to={`/users/edit/${u.user_id}`}
                          className="justify-self-end ml-auto"
                        >
                          <IonButton
                            // expand="full"
                            // onClick={resetFilters}
                            size="small"
                            tooltip="reset filters"
                          >
                            {/* <IonIcon icon={refreshOutline} /> */}
                            view
                          </IonButton>
                        </Link>
                      </div>
                    ))}
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

      {data && (
          <MemberAccessOverLastWeekPieChart
            data={data}
            colors={colors}
          />
        )}
      {busyDays && (
        <MemberAccessPerDayPerPeriodBarChart busyDays={busyDays} />
      )}
      {busyUserTypes['1'] > 0 && (
        <UserTypesBarChart busyUserTypes={busyUserTypes} />
      )}
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <UserTypeCurrentlyIn users={modalUsers} title={modalTitle} setUsers={setModalUsers}/>
      </Modal>
      <div style={{ height: 100 }}></div>
    </BaseTemplate>
  );
}

export default Dashboard;
