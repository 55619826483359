    import React, { useEffect, useState } from 'react';
    import {
        IonText,
        IonButton,
        IonItem,
        IonInput,
        IonLabel,
        IonFab,
        IonFabButton,
        IonIcon,
        IonDatetime,
        IonList,
        IonSelect,
        IonSelectOption
    } from "@ionic/react";
    import { arrowBackOutline, shareSocialOutline } from "ionicons/icons";
    import { Link } from "react-router-dom";
    import BaseTemplate from "../components/base/BaseTemplate";
    import { useHistory } from "react-router-dom";
    import { codeGet } from '../utils';

    export default function NewVisitor() {
        const todayStr = todaysDate();
        const nextWeekStr = nextWeek();
        const history = useHistory();
        const [firstname, setFirstname] = useState();
        const [surname, setSurname] = useState();
        const [eventAt, setEventAt] = useState(todayStr);
        const [code, setCode] = useState();
        const [loading, setLoading] = useState(false);
        const [isDone, setIsDone] = useState(false);
        const [userInfo, setUserInfo] = useState();


        useEffect(() => {
            if(localStorage.getItem('inovio-user-info')) {
                const user = JSON.parse(localStorage.getItem('inovio-user-info'));

                const supported = ('contacts' in navigator && 'ContactsManager' in window);
                console.log(supported);

                setUserInfo(user);
            }
        }, []);

        function todaysDate() {
            const today = new Date().toLocaleDateString();
            const todaySplit = today.split("/");
            const returnValue = `${todaySplit[2]}-${todaySplit[0]}-${todaySplit[1]}`;
            console.log(returnValue);

            return returnValue;
        }

        function nextWeek() {
            var date = new Date();
            date.setDate(date.getDate() + 6);
            const dateSplit = date.toLocaleDateString().split("/");
            const returnValue = `${dateSplit[2]}-${dateSplit[0]}-${dateSplit[1]}`;
            console.log(returnValue);

            return returnValue;
        }

        function handleRequestCodeClick() {
            setLoading(true);
            if(firstname && surname && eventAt && userInfo) {
                const body = {
                    firstName: firstname,
                    lastName: surname,
                    userId: userInfo.info[0].user_id,
                    unitId: userInfo.units.ids[0],
                    dayNum: new Date().getDay()
                };

                console.log(body, userInfo);
                codeGet(body).then(res => {
                    setIsDone(true);
                    console.log(res);
                    setCode(res[0]);
                    setLoading(false);
                });
            }
        }

        function getNextDay(dateStr) {
            var tomorrow = new Date(dateStr);
            tomorrow.setDate(tomorrow.getDate() + 1);

            return tomorrow.toLocaleString();
        }

        function share(codeStr) {
            if (navigator.share) {
                navigator.share({
                    text: `Temporary pin ${codeStr} valid for 1 Entry and Exit to visit ${userInfo.info[0].firstname} ${userInfo.info[0].lastname} at Gibbor MonaghanFarm valid until ${getNextDay(eventAt)}.
                    Present code and ID document to security `,
                })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
            }
        }

        return (
            <BaseTemplate>
            <div style={{ margin: "1em" }} className="shadow-xl px-10 py-2">
                <h1
                style={{ textAlign: "center", margin: "0 auto 1em auto" }}
                className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold"
                >
                    New Visitor
                </h1>
                {!isDone && <>
                    <IonItem>
                        <IonLabel>Set Date of Visit</IonLabel>
                        <IonDatetime
                            displayFormat="MMM DD, YYYY HH:mm"
                            placeholder="MMM DD, YYYY HH:mm"
                            min="2021-04-01" 
                            max="2021-05-31"
                            value={eventAt}
                            onIonChange={(e) => setEventAt(e.detail.value)}
                        ></IonDatetime>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">Visitors Firstname</IonLabel>
                        <IonInput
                            type="text"
                            required
                            value={firstname}
                            onIonChange={(e) => setFirstname(e.detail.value)}
                        ></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">Visitors Surname</IonLabel>
                        <IonInput
                            type="text"
                            required
                            value={surname}
                            onIonChange={(e) => setSurname(e.detail.value)}
                        ></IonInput>
                    </IonItem>
                    {!code && <IonButton
                        type="submit"
                        expand="block"
                        style={{ marginTop: "3em" }}
                        disabled={loading}
                        onClick={() => handleRequestCodeClick()}
                    >
                        Request Code
                    </IonButton>}
                </>}
                {
                    code && 
                    <h1
                        style={{ textAlign: "center", margin: "1em auto" }}
                        className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold"
                    >
                        {code}
                    </h1>
                }
                {isDone && <IonButton
                    type="submit"
                    expand="block"
                    style={{ marginTop: "2em" }}
                    disabled={loading}
                    onClick={() => share(code)}
                >
                    share
                    <IonIcon slot="end" icon={shareSocialOutline} />
                </IonButton>}

                <div
                style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "2em",
                }}
                >
                <IonText>
                    <Link to="/">Cancel</Link>
                </IonText>
                </div>
            </div>
            <IonFab
            vertical="top"
            horizontal="start"
            slot="fixed"
            style={{ marginBottom: "3em" }}
            >
                <IonFabButton onClick={() => history.goBack()}>
                    <IonIcon icon={arrowBackOutline}></IonIcon>
                </IonFabButton>
            </IonFab>
        </BaseTemplate>
        )
    }
