import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";
import { IonButton, IonIcon, IonGrid, IonCol, IonRow, IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { downloadOutline } from "ionicons/icons";
import { exportTableToExcel } from "../../utils";
import Search from "../Search/Search";
import Loading from "../Loading";

const PER_PAGE = 10;

export default function UserTypeCurrentlyIn({ users, title, setUsers }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [companyTypes, setCompanyTypes] = useState();
  const [allCompanyTypes, setAllCompanyTypes] = useState();
  const [allUsers, setAllUsers] = useState(users);

  useEffect(() => {
    const types = getCompanyTypes(users);
    setCompanyTypes(types);
    if(types.length > 1) {
      setAllCompanyTypes(types);
    }
    setLoading(false);
    const usersFuse = new Fuse(users, {
      keys: ["firstname", "lastname", "company"],
    });
    setSearchResults(usersFuse.search(searchQuery));
  }, [searchQuery, users]);

  const revisedUsers =
    searchResults.length > 0 ? searchResults.map((item) => item.item) : (users ? users : []);

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  const offset = currentPage * PER_PAGE;

  const currentPageData = revisedUsers.slice(offset, offset + PER_PAGE);

  const pageCount = Math.ceil(revisedUsers.length / PER_PAGE);

  function getCompanyTypes(users) {
    let uniqueTypes = [];
    users.forEach((user) => {
      if (uniqueTypes.indexOf(user.company) === -1) {
        uniqueTypes.push(user.company);
      }
    });
    return uniqueTypes;
  }

  function handleFilterByCompany(value) {
    if (value === "all") {
      setUsers(allUsers);
      return;
    }
    setUsers(allUsers.filter((item) => item.company === value));
  }

  return (
    <div>
      <IonGrid style={{ marginBottom: "2em" }}>
        <IonRow>
          <IonCol size="10" offset="1">
            <h1
              style={{ margin: "0 auto" }}
              className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold"
            >
              {title} Currently In
            </h1>
            <Search
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              style={{ textAlign: "left" }}
              placeholder={'Search Names'}
            />
          </IonCol>
        </IonRow>
        {title === 'Contractors' && <IonRow>
          <IonCol  size="10" offset="1">
          <IonItem className="shadow-xl">
                <IonLabel>Filter by Company</IonLabel>
                <IonSelect
                  okText="Okay"
                  cancelText="Dismiss"
                  onIonChange={(e) => handleFilterByCompany(e.detail.value)}
                  interface="popover"
                >
                  <IonSelectOption value="all">All</IonSelectOption>
                  {allCompanyTypes &&
                    allCompanyTypes.map((type, i) => (
                      <IonSelectOption key={i} value={type}>
                        {type}
                      </IonSelectOption>
                    ))}
                </IonSelect>
              </IonItem>
          </IonCol>
        </IonRow>}
        <IonRow style={{ boxShadow: "none" }}>
          <IonCol size="10" offset="1">
            {loading && <Loading loading={loading} />}
            {!loading && (
              <div style={{ height: "auto" }} className="table-container">
                <table
                  className="items-center w-full bg-transparent border-collapse"
                  id="contractors-in-table"
                >
                  <thead>
                    <tr>
                      <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                        Name
                      </th>
                      {title === 'Contractors' && <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                        Company
                      </th>}
                      <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                        Gate
                      </th>
                      <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                        Code
                      </th>
                      {/* <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                        Time In
                      </th> */}
                      <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                        <IonIcon
                          icon={downloadOutline}
                          style={{ cursor: "pointer", fontSize: 24 }}
                          className="text-gray-600 float-right"
                          onClick={() =>
                            exportTableToExcel(
                              "contractors-in-table",
                              "contractors-in-data"
                            )
                          }
                        ></IonIcon>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentPageData.map((user, i) => (
                      <tr key={i}>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left">
                          {user.user_id ? <Link
                            style={{ color: "black" }}
                            to={`/users/edit/${user.user_id}`}
                          >
                            {user.firstname} {user.lastname}
                          </Link> :
                          <>
                            {user.firstname} {user.lastname}
                          </>}
                        </th>
                        {title === "Contractors" && <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                          {user.company}
                        </td>}
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                          {user.acr_name}
                        </td>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                          {user.code}
                        </td>
                        {/* <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                          {new Date(user.event_at).toLocaleTimeString("en-gb")}
                        </td> */}
                        <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap px-1 py-4">
                          {user.user_id && <IonButton
                            expand="full"
                            color="warning"
                            routerLink={`/users/edit/${user.user_id}`}
                          >
                            Edit
                          </IonButton>}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div style={{ margin: "1em 1em" }}>
                  <ReactPaginate
                    previousLabel={"←"}
                    nextLabel={"→"}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                  />
                </div>
              </div>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
}
