import React, { useState, useEffect } from "react";
import {
  IonMenu,
  IonIcon,
  IonContent,
  IonList,
  IonItem,
  IonSelectOption,
  IonSelect,
} from "@ionic/react";
import {
  homeOutline,
  peopleOutline,
  fileTrayFullOutline,
  listCircleOutline,
  informationOutline,
  calendarOutline,
  personAddOutline
} from "ionicons/icons";
import InstallButton from "../InstallButton/InstallButton";
import "./MobileMenu.css";
import { close } from "ionicons/icons";
import { getCurrentEstate } from "../../utils";

function MobileMenu() {
  const [currentEstate, setCurrentEstate] = useState(null);
  const [currentUnit, setCurrentUnit] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [estateInfo, setEstateInfo] = useState();

  useEffect(() => {
      if(localStorage.getItem('inovio-current-estate')) {
          setEstateInfo(JSON.parse(localStorage.getItem('inovio-current-estate')));
        } else {
          getCurrentEstate().then(current => {
              setEstateInfo(current[0]);
          });
        }
  }, []);

  useEffect(() => {
    const isAdminLS = JSON.parse(localStorage.getItem("inovio-isAdmin"));
    setIsAdmin(isAdminLS);
    if (localStorage.getItem("inovio-current-estate")) {
      setCurrentEstate(JSON.parse(localStorage.getItem('inovio-current-estate')).estates_name);
    } else {
      // getCurrentEstate().then((current) => {
      //   setCurrentEstate(current[0].estates_name);
      // });
    }

    if(!isAdminLS) {
      if(localStorage.getItem('inovio-user-info') && JSON.parse(localStorage.getItem('inovio-user-info'))) {
        const userInfo = JSON.parse(localStorage.getItem('inovio-user-info'));
        const currentUnitId = userInfo.units.ids[0];
        const current = userInfo.units[currentUnitId].unit[0].name;
        setCurrentUnit(current);
      } else {
        setTimeout(() => {
          if(localStorage.getItem('inovio-user-info') && JSON.parse(localStorage.getItem('inovio-user-info'))) {
            const userInfo = JSON.parse(localStorage.getItem('inovio-user-info'));
            if(userInfo.units) {
              const currentUnitId = userInfo.units.ids[0];
              const current = userInfo.units[currentUnitId].unit[0].name;
              setCurrentUnit(current);
            }
          }
        }, 2000)
      }
    }
  }, []);

  function handleSelectChange(e) {
    setCurrentEstate(e.detail.value);
  }

  function handleSelectUnitChange(e) {
    setCurrentUnit(e.detail.value);
  }

  function closeMenu() {
    const menu = document.getElementById("mobile-menu");
    menu.close();
  }

  return (
    <IonMenu
      side="start"
      type="overlay"
      contentId="main-content"
      id="mobile-menu"
    >
      <div
        style={{
          width: "100%",
          position: "relative",
          height: "20px",
          paddingBottom: "2em",
          paddingTop: "1em",
        }}
      >
        <IonIcon
          onClick={() => closeMenu()}
          size="large"
          style={{ position: "absolute", right: "0.5em" }}
          icon={close}
        ></IonIcon>
      </div>
      <div style={{ textAlign: "center" }}>
        <img
          src="/assets/inovio-logo.webp"
          alt="inovio logo"
          style={{ width: "60%", margin: "1em auto" }}
        />
      </div>
      <IonContent style={{ position: "relative" }}>
        {isAdmin && <IonSelect
            value={currentEstate}
            okText="Okay"
            cancelText="Dismiss"
            onIonChange={(e) => handleSelectChange(e)}
            className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
          >
            <IonSelectOption value={currentEstate} key={currentEstate} className="bg-gray-100 text-gray-600 align-middle text-xs uppercase font-semibold text-left">
              {currentEstate}
            </IonSelectOption>
          </IonSelect>}
          {!isAdmin && <IonSelect
            value={currentUnit}
            okText="Okay"
            cancelText="Dismiss"
            onIonChange={(e) => handleSelectUnitChange(e)}
            className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
          >
            <IonSelectOption value={currentUnit} key={currentUnit} className="bg-gray-100 text-gray-600 align-middle text-xs uppercase font-semibold text-left">
              UNIT {currentUnit ? currentUnit : 'loading...'}
            </IonSelectOption>
          </IonSelect>}
            <IonList style={{ width: "100%" }}>
            {isAdmin && (
                <>
                  <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink="/dashboard"
                  >
                    <IonIcon slot="start" icon={listCircleOutline}></IonIcon>
                    Dashboard
                  </IonItem>
                  <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink="/logs"
                  >
                    <IonIcon slot="start" icon={fileTrayFullOutline}></IonIcon>
                    Logs
                  </IonItem>
                  <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink="/users"
                  >
                    <IonIcon slot="start" icon={peopleOutline}></IonIcon>
                    Users
                  </IonItem>
                  <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink="/units"
                  >
                    <IonIcon slot="start" icon={homeOutline}></IonIcon>
                    Units
                  </IonItem>
                  <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink="/bookings/manage"
                  >
                    <IonIcon slot="start" icon={calendarOutline}></IonIcon>
                    Bookings
                  </IonItem>
                  {estateInfo && estateInfo.estates_address && <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink="/estate-info"
                  >
                    <IonIcon slot="start" icon={informationOutline}></IonIcon>
                    Estate Info
                  </IonItem>}
                </>
              )}
              {!isAdmin && (
                <>
                  <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink="/"
                  >
                    <IonIcon slot="start" icon={homeOutline}></IonIcon>
                    Home
                  </IonItem>
                  <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink="/my-users"
                  >
                    <IonIcon slot="start" icon={peopleOutline}></IonIcon>
                    Users
                  </IonItem>
                  <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink="/bookings"
                  >
                    <IonIcon slot="start" icon={calendarOutline}></IonIcon>
                    Bookings
                  </IonItem>
                  <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink={currentUnit ? `/logs/${currentUnit}` : `/logs/unit`}
                  >
                    <IonIcon slot="start" icon={fileTrayFullOutline}></IonIcon>
                    Logs
                  </IonItem>
                  {/* <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink="/my-units"
                  >
                    <IonIcon slot="start" icon={homeOutline}></IonIcon>
                    Units
                  </IonItem> */}
                  {/* <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink="/panic-management"
                  >
                    <IonIcon slot="start" icon={alertCircleOutline}></IonIcon>
                    Panics
                  </IonItem> */}
                  <IonItem
                    className="bg-gray-100 text-gray-500 align-middle text-xs uppercase font-semibold text-left"
                    routerLink="/new-visitor"
                  >
                    <IonIcon slot="start" icon={personAddOutline}></IonIcon>
                    New Visitor
                  </IonItem>
                </>
              )}
            </IonList>
        <InstallButton />
      </IonContent>
    </IonMenu>
  );
}

export default MobileMenu;
