import React, { useState } from 'react';
import { IonCardContent, IonCard, IonGrid, IonCol, IonRow, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import BaseTemplate from '../../components/base/BaseTemplate';
import UnitView from './UnitView';
import { Link } from 'react-router-dom';
import './UserUnits.css';

const Units = ({units, setIsLoggedIn}) => {
  const [showUnit, setShowUnit] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState();

  function handleUserClick(unit) {
    setShowUnit(true);
    setSelectedUnit(unit);
  }

  return (
      <BaseTemplate setIsLoggedIn={setIsLoggedIn}>
        {!showUnit && <IonGrid>
          <IonRow>
            {units && units.map((unit, i) => (
              <IonCol key={i} size="5" offset="1">
                <IonCard onClick={() => handleUserClick(unit)}>
                  <IonCardContent>
                    {unit.name}
                  </IonCardContent>
                </IonCard>
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>}
        {showUnit && <UnitView unit={selectedUnit} setShowUnit={setShowUnit} />}
      </BaseTemplate>
  );
};

export default Units;
