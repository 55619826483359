import React, { useState, useEffect, useRef } from "react";
import BaseTemplate from "../../components/base/BaseTemplate";
import {
  IonDatetime,
  IonItem,
  IonGrid,
  IonCol,
  IonRow,
  IonLabel,
  IonAlert,
  IonIcon,
  IonSpinner,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonInput,
  IonPopover
} from "@ionic/react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Fuse from "fuse.js";
import { downloadOutline, refreshOutline } from "ionicons/icons";
import { getAccess, exportArrayToExcel, getAcrs, getUsers, getUnits, checkToken } from "../../utils";
import Loading from "../../components/Loading";
import Search from "../../components/Search/Search";
import Modal from "../../components/Modal";
import AddManualLogEntry from "../../components/AddManualLogEntry";

import DayPicker from 'react-day-picker';

import {format} from 'date-fns';

import "./EntryLogs.css";

const PER_PAGE = 15;

export default function EntryLogs() {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  const [selectedStartDate, setSelectedStartDate] = useState(
    yesterday.toISOString()
  );
  const [selectedEndDate, setSelectedEndDate] = useState(today.toISOString());
  const [showSelectedEndDate, setShowSelectedEndDate] = useState({ showPopover: false, event: undefined });
  const [showSelectedStartDate, setShowSelectedStartDate] = useState({ showPopover: false, event: undefined });

  const [prevSelectedStartDate, setPrevSeectedStartDate] = useState(yesterday);
  const [prevSelectedEndDate, setPrevSelectedEndDate] = useState(today);
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [entered, setEntered] = useState();
  const [exited, setExited] = useState();
  const [granted, setGranted] = useState();
  const [denied, setDenied] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [gateTypes, setGateTypes] = useState([]);
  const [mode, setMode] = useState("historic");
  const [intervals, setIntervals] = useState([]);
  const [filters, setFilters] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [acrList, setAcrList] = useState([]);

  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  const [units, setUnits] = useState([]);
  const [users, setUsers] = useState([]);

  const userTypes = JSON.parse(localStorage.getItem("inovio-estate-info"))
    .usertypes;
  const grantedRef = useRef();
  const directionRef = useRef();
  const typeRef = useRef();
  const gateRef = useRef();

  function calculateEntered(data) {
    setEntered(data.filter((d) => (d.direction === 1) && (d.granted === 1)).length);
  }

  function calculateExited(data) {
    setExited(data.filter((d) => d.direction === 2).length);
  }

  function calculateGranted(data) {
    setGranted(data.filter((d) => d.granted === 1).length);
  }

  function calculateDenied(data) {
    setDenied(data.filter((d) => d.granted === 0).length);
  }

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  const revisedData =
    searchResults.length > 0 ? searchResults.map((item) => item.item) : data;

  const offset = currentPage * PER_PAGE;

  const currentPageData = revisedData.slice(offset, offset + PER_PAGE);

  const pageCount = Math.ceil(revisedData.length / PER_PAGE);

  useEffect(() => {
    getUsers()
    .then((users) => {
      // console.log(users)
      // let sortedUsers = users.naturalSort();
      localStorage.setItem("inovio-users", JSON.stringify(users));
      
      setUsers(users);
      // setLoading(false);
    })
    .catch(err => {
      checkToken(localStorage.getItem('inovio-access-token'));
    });
    
    // if (localStorage.getItem("inovio-units")) {
    //   setUnits(JSON.parse(localStorage.getItem("inovio-units")));
    // } else {
      getUnits()
      .then((units) => {
        setUnits([...units]);
      })
      .catch(err => {
        checkToken(localStorage.getItem('inovio-access-token'));
      });
    // }
  }, []);

  useEffect(() => {
    if((users && Array.isArray(users) && users.length > 0) && (units && Array.isArray(units) && units.length > 0)) {
      // console.log(users)
      getAccess(selectedStartDate, selectedEndDate, 50000).then((res) => {
        setData(res);
        setAllData(res);
        setLoading(false);
        calculateEntered(res);
        calculateExited(res);
        calculateGranted(res);
        calculateDenied(res);
        const types = getGateTypes(res);
        setGateTypes(types);
      });
      
      getAcrs().then(res => {
        setAcrList(res);
      });
    }

  }, [users, units]);

  useEffect(() => {
    setCurrentPage(0);
    const dataFuse = new Fuse(data, {
      keys: ["firstname", "lastname"],
    });
    setSearchResults(dataFuse.search(searchQuery));
  }, [searchQuery, data]);

  useEffect(() => {
    // if((users && Array.isArray(users) && users.length > 0) && (units && Array.isArray(units) && units.length > 0)) {

    if (selectedStartDate && selectedEndDate && (users && Array.isArray(users) && users.length > 0) && (units && Array.isArray(units) && units.length > 0)) {
      const dateDifference =
        new Date(selectedEndDate) - new Date(selectedStartDate);
      if (new Date(selectedStartDate) > new Date(selectedEndDate)) {
        setSelectedStartDate(prevSelectedStartDate);
        setAlertMessage("start date must be before end date");
        setShowAlert(true);
      }
      if (new Date(selectedStartDate) < new Date(selectedEndDate)) {
        if (dateDifference > 2678400000) {
          setAlertMessage("dates must be within a month of each other");
          setShowAlert(true);
        } else {
          getAccess(selectedStartDate, selectedEndDate, 50000).then((res) => {
          // console.log(res)

            setCurrentPage(0);
            setPrevSeectedStartDate(selectedStartDate);
            setPrevSelectedEndDate(selectedEndDate);
            const tempData = handleFilters(filters, res);
            setData(tempData);
            setLoading(false);
            calculateEntered(res);
            calculateExited(res);
            calculateGranted(res);
            calculateDenied(res);
          });
        }
      }
    }
  }, [selectedStartDate, selectedEndDate, users, units]);

  useEffect(() => {
    let id = '';
    if(mode === 'historic') {
      //cancel interval
      intervals.map(interval => {
        clearInterval(interval);
      });
    } else {
      id = setInterval(() => {
        setSelectedEndDate(new Date().toISOString());
      }, 15000);
  
      setIntervals([...intervals, id]);
    }

    return function cleanup() {
      intervals.map(interval => {
        clearInterval(interval);
      });
    }
  }, [mode]);

  function handleDateChange(value, type) {
    if(value === null) return;
    setLoading(true);
    if (type === "start") {
      setSelectedStartDate(value);
    } else if (type === "end") {
      setSelectedEndDate(value);
    }
  }

  function handleFilterByGate(value) {
    if(value === "") return;
    let tempFilters = [...filters.filter(f => !f.includes("gate")), `gate:${value}`];
    setFilters(tempFilters);
    if (value === "all") {
      setData(allData);
      return;
    }
    const tempData = handleFilters(tempFilters, allData);
    setData(tempData.filter((item) => item.acr_name === value));
    setCurrentPage(0);
  }

  function handleFilterByType(value) {
    if(value === "") return;
    let tempFilters = [...filters.filter(f => !f.includes("type")), `type:${value}`];
    setFilters(tempFilters);
    if (value === "0") {
      setData(allData);
      return;
    }
    const tempData = handleFilters(tempFilters, allData);
    if(value === "v") {
      let visitors = [];
      for(let i = 0; i < tempData.length; i++) {
        if(tempData[i].user_id === null && data[i].firstname) {
          visitors.push(tempData[i]);
        }
      }
      setData(visitors);
      setCurrentPage(0);
      return;
    }
    setData(tempData.filter((item) => item.usertype_id === parseInt(value)));
    setCurrentPage(0);
  }

  function handleFilterByDirection(value) {
    if(value === "") return;
    let tempFilters = [...filters.filter(f => !f.includes("direction")), `direction:${value}`];
    setFilters(tempFilters);
    if (value === "all") {
      setData(allData);
      return;
    }
    const tempData = handleFilters(tempFilters, allData);
    setData(tempData.filter((item) => item.direction === parseInt(value)));
    setCurrentPage(0);
  }

  function handleFilterByGranted(value) {
    if(value === "") return;
    let tempFilters = [...filters.filter(f => !f.includes("granted")), `granted:${value}`];
    setFilters(tempFilters);
    
    if (value === "all") {
      setData(allData);
      return;
    }
    const tempData = handleFilters(tempFilters, allData);
    setData(tempData.filter((item) => item.granted === parseInt(value)));
    setCurrentPage(0);
  }

  function filterByGate(value, data) {
    return data.filter((item) => item.acr_name === value);
  }

  function filterByType(value, data) {
    if(value === "v") {
      let visitors = [];
      for(let i = 0; i < data.length; i++) {
        if(data[i].user_id === null && data[i].firstname) {
          visitors.push(data[i]);
        }
      }
      return visitors;
    }
    return data.filter((item) => item.usertype_id === parseInt(value));
  }

  function filterByDirection(value, data) {
    return data.filter((item) => item.direction === parseInt(value));
  }

  function filterByGranted(value, data) {
    return data.filter((item) => item.granted === parseInt(value));
  }

  function handleFilters(filters, data) {
    for(let i = 0; i < filters.length; i++) {
      const filterType = filters[i].split(":")[0];
      const filterValue = filters[i].split(":")[1];

      switch(filterType) {
        case "gate": {
          data = filterByGate(filterValue, data);
          break;
        }
        case "type": {
          data = filterByType(filterValue, data);
          break;
        }
        case "direction": {
          data = filterByDirection(filterValue, data);
          break;
        }
        case "granted": {
          data = filterByGranted(filterValue, data);
          break;
        }
      }
      
    }
    return data;
  }

  function getGateTypes(logs) {
    let uniqueTypes = [];
    logs.forEach((log) => {
      if (uniqueTypes.indexOf(log.acr_name) === -1) {
        uniqueTypes.push(log.acr_name);
      }
    });
    return uniqueTypes;
  }

  function resetFilters() {
    setCurrentPage(0);
    gateRef.current.value = "";
    directionRef.current.value = "";
    grantedRef.current.value = "";
    typeRef.current.value = "";
    setFilters([]);
    // setSelectedEndDate(new Date().toISOString());
    setData(allData);
  }

  function getUnitsNamesForUser(userId) {
    const usersUnits = [];
    const user = users.find((user) => user.user_id === userId);

    // console.log(users[0], units[0]);
    units.map((unit) => {
      if(user) {
        user.unit_id_arr.map((id) => {
          // console.log(id, unit.id)
          if (unit.id === id) {
            usersUnits.push(unit);
          }
        });
      }
    });

    // console.log(usersUnits);

    return usersUnits;
  }

  function onExportClick() {
    const exportArr = revisedData.map(u => {
      return {
        unit: (getUnitsNamesForUser(u.user_id)[0] && getUnitsNamesForUser(u.user_id)[0].name) ? getUnitsNamesForUser(u.user_id)[0].name : '',
        ...u,
      }
    });

    exportArrayToExcel(exportArr, "logs-data");
  }

  function setTime(event, type) {
    const timeSplit = event.detail.value.split(':');

    if(type === 'start') {
      let tempDateTime = selectedStartDate;

      tempDateTime = new Date(tempDateTime).setHours(Number(timeSplit[0]));
      tempDateTime = new Date(tempDateTime).setMinutes(Number(timeSplit[1]));

      setStartTime(event.target.value);
      setSelectedStartDate(tempDateTime);
    } else if(type === 'end') {

      let tempDateTime = selectedEndDate;

      tempDateTime = new Date(tempDateTime).setHours(Number(timeSplit[0]));
      tempDateTime = new Date(tempDateTime).setMinutes(Number(timeSplit[1]));

      setEndTime(event.target.value);
      setSelectedEndDate(tempDateTime);
    }
  }

  return (
    <BaseTemplate>
      <div className="entry-logs">
        <IonGrid>
          <IonRow style={{ boxShadow: "none" }}>
            <IonCol size-md="2" offset-md="0.5">
              <IonItem className="shadow-xl">
                <IonSelect
                  okText="Okay"
                  cancelText="Dismiss"
                  onIonChange={(e) => setMode(e.detail.value)}
                  value={mode}
                  interface="popover"
                >
                  <IonSelectOption value="historic">Historic</IonSelectOption>
                  <IonSelectOption value="real-time">Real Time</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
            {mode === 'historic' && <>
            <IonCol size="3">
              <IonItem className="shadow-xl">
                <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                  Start Date
                </IonLabel>
                <IonInput style={{textAlign: 'right'}} onClick={(e) => {e.persist(); setShowSelectedStartDate({ showPopover: true, event: e })}} placeholder="D MMM YYYY" value={selectedStartDate ? format(new Date(selectedStartDate), 'MM DD YYYY') : ''} />
              </IonItem>
            </IonCol>
            <IonCol size="1.5">
              <IonItem className="shadow-xl">
                <IonInput type="time" style={{textAlign: 'right'}} onIonChange={e => setTime(e, 'start')} placeholder="HH:mm" value={startTime} />
              </IonItem>
            </IonCol>
            <IonCol size="3">
              <IonItem className="shadow-xl">
                <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                  End Date
                </IonLabel>
                <IonInput style={{textAlign: 'right'}} onClick={(e) => {e.persist(); setShowSelectedEndDate({ showPopover: true, event: e })}} placeholder="D MMM YYYY" value={selectedEndDate ? format(new Date(selectedEndDate), 'MM DD YYYY') : ''} />
              </IonItem>
            </IonCol>
            <IonCol size="1.5">
              <IonItem className="shadow-xl">
                <IonInput type="time" style={{textAlign: 'right'}} onIonChange={e => setTime(e, 'end')} placeholder="HH:mm" value={endTime} />
              </IonItem>
            </IonCol>
          </>}
          </IonRow>
          <IonRow>
            <IonCol size="11" offset="0.5" style={{ textAlign: "left" }}>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                    <div className="flex-auto p-4">
                      <div className="flex flex-wrap">
                        <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                          <h5 className="text-gray-500 uppercase font-bold text-xs">
                            Entered
                          </h5>
                          <span className="font-semibold text-xl text-gray-800">
                            {entered === undefined ? <IonSpinner /> : entered}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                    <div className="flex-auto p-4">
                      <div className="flex flex-wrap">
                        <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                          <h5 className="text-gray-500 uppercase font-bold text-xs">
                            Exited
                          </h5>
                          <span className="font-semibold text-xl text-gray-800">
                            {exited === undefined ? <IonSpinner /> : exited}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                    <div className="flex-auto p-4">
                      <div className="flex flex-wrap">
                        <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                          <h5 className="text-gray-500 uppercase font-bold text-xs">
                            Granted
                          </h5>
                          <span className="font-semibold text-xl text-gray-800">
                            {granted === undefined ? <IonSpinner /> : granted}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                    <div className="flex-auto p-4">
                      <div className="flex flex-wrap">
                        <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                          <h5 className="text-gray-500 uppercase font-bold text-xs">
                            Denied
                          </h5>
                          <span className="font-semibold text-xl text-gray-800">
                            {denied === undefined ? <IonSpinner /> : denied}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </IonCol>
          </IonRow>
          {mode === "historic" && <>
          <IonRow>
            <Search
              style={{ marginTop: 0 }}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </IonRow>
          <IonRow>
            <IonCol size-md="2.5" offset-md="1">
              <IonItem className="shadow-xl">
                <IonLabel>User Type</IonLabel>
                <IonSelect
                  okText="Okay"
                  cancelText="Dismiss"
                  onIonChange={(e) => handleFilterByType(e.detail.value)}
                  ref={typeRef}
                  interface="popover"
                >
                  <IonSelectOption value="0">All</IonSelectOption>
                  {userTypes.map((type) => (
                    <IonSelectOption value={String(type.id)} key={type.id}>
                      {type.name}
                    </IonSelectOption>
                  ))}
                    <IonSelectOption value={"v"}>
                      Visitor
                    </IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
            <IonCol size-md="2.5">
              <IonItem className="shadow-xl">
                <IonLabel>User Gate</IonLabel>
                <IonSelect
                  okText="Okay"
                  cancelText="Dismiss"
                  onIonChange={(e) => handleFilterByGate(e.detail.value)}
                  ref={gateRef}
                  interface="popover"
                >
                  <IonSelectOption value="all">All</IonSelectOption>
                  {gateTypes &&
                    gateTypes.map((type, i) => (
                      <IonSelectOption key={i} value={type}>
                        {type}
                      </IonSelectOption>
                    ))}
                </IonSelect>
              </IonItem>
            </IonCol>
            <IonCol size-md="2.5">
              <IonItem className="shadow-xl">
                <IonLabel>User Direction</IonLabel>
                <IonSelect
                  okText="Okay"
                  cancelText="Dismiss"
                  onIonChange={(e) => handleFilterByDirection(e.detail.value)}
                  ref={directionRef}
                  interface="popover"
                >
                  <IonSelectOption value="1">In</IonSelectOption>
                  <IonSelectOption value="2">Out</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
            <IonCol size-md="2.5">
              <IonItem className="shadow-xl">
                <IonLabel>User Granted</IonLabel>
                <IonSelect
                  okText="Okay"
                  cancelText="Dismiss"
                  onIonChange={(e) => handleFilterByGranted(e.detail.value)}
                  ref={grantedRef}
                  interface="popover"
                >
                  <IonSelectOption value="1">Granted</IonSelectOption>
                  <IonSelectOption value="0">Denied</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size-md="1" offset-md="1">
              <IonButton
                expand="full"
                onClick={resetFilters}
                tooltip="reset filters"
              >
                <IonIcon icon={refreshOutline}></IonIcon>
              </IonButton>
            </IonCol>
            <IonCol size-md="1">
              <IonButton
                expand="full"
                onClick={onExportClick}
              >
                <IonIcon icon={downloadOutline}></IonIcon>
              </IonButton>
            </IonCol>
            <IonCol size-md="2">
              <IonButton
                expand="full"
                onClick={() => setShowModal(true)}
              >
                Add Manual Entry
              </IonButton>
            </IonCol>
          </IonRow>
          </>}
          <IonRow style={{ boxShadow: "none" }}>
            <IonCol size="11" offset="0.5" className="table-container">
              <table
                className="items-center w-full bg-transparent border-collapse"
                id="logs-table"
              >
                <thead>
                  <tr>
                    <th className="px-2 pl-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                      Name
                    </th>
                    <th className="px-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                      Unit
                    </th>
                    <th className="px-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                      Code
                    </th>
                    <th className="px-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                      Type
                    </th>
                    {/* <th className="px-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                      Unit
                    </th> */}
                    <th className="px-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                      Direction
                    </th>
                    <th className="px-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                      Gate
                    </th>
                    <th className="px-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                      Reader
                    </th>
                    <th className="px-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                      Date
                    </th>
                    <th className="px-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold">
                      Access Granted
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentPageData.map((l, i) => (
                    <tr key={i}>
                      <th className="border-t-0 px-2 pl-4 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left">
                        {
                          l.user_id ?
                          <Link
                            to={`/users/edit/${l.user_id}`}
                            style={{ color: "black" }}
                          >
                            {l.firstname !== null && l.firstname}
                            {" "}
                            {l.lastname !== null && l.lastname}
                          </Link>
                          :
                          <>
                            {l.firstname !== null && l.firstname}
                            {" "}
                            {l.lastname !== null && l.lastname}
                          </>
                        }
                      </th>
                      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                        {getUnitsNamesForUser(l.user_id)[0] && getUnitsNamesForUser(l.user_id)[0].name}
                      </td>
                      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                        {l.code !== null ? l.code : ""}
                      </td>
                      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                        {userTypes.map((type) =>
                          l.usertype_id === type.id ? type.name : ""
                        )}
                        {!l.user_id && l.firstname && "Visitor"}
                      </td>
                      {/* <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                        {l.users_unit_id !== null ? l.users_unit_id : ""}
                      </td> */}
                      <td
                        className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4"
                        style={{ color: l.direction === 1 ? "green" : "red" }}
                      >
                        {l.direction === 1 ? "In" : "Out"}
                      </td>
                      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                        {l.acr_name && l.acr_name}
                      </td>
                      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                        {l.acr_loc && l.acr_loc}
                      </td>
                      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                        {new Date(l.event_at).toLocaleDateString()}{" "}
                        {new Date(l.event_at).toLocaleTimeString("en-GB")}
                      </td>
                      <td
                        className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4"
                        style={{ color: l.granted === 1 ? "green" : "red" }}
                      >
                        {l.granted === 0 ? `false, ${l.status}` : "true"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Loading loading={loading} />
            </IonCol>
          </IonRow>
        </IonGrid>
        <div style={{ margin: "0 6em" }}>
          <ReactPaginate
            previousLabel={"←"}
            nextLabel={"→"}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        </div>
      </div>
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <AddManualLogEntry acrList={acrList} />
      </Modal>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        cssClass="my-custom-class"
        header={"Alert"}
        message={alertMessage}
        buttons={["OK"]}
      />
      <IonPopover
          cssClass="datetime-popover-picker"
          isOpen={showSelectedStartDate.showPopover}
          event={showSelectedStartDate.event}
          onDidDismiss={() => setShowSelectedStartDate({ showPopover: false, event: undefined })}
      >
          <DayPicker canChangeMonth={true} onDayClick={(day) => { setSelectedStartDate(day.toString()); setShowSelectedStartDate({ showPopover: false, event: undefined }); }} />
      </IonPopover>
      <IonPopover
          cssClass="datetime-popover-picker"
          isOpen={showSelectedEndDate.showPopover}
          event={showSelectedEndDate.event}
          onDidDismiss={() => setShowSelectedEndDate({ showPopover: false, event: undefined })}
      >
          <DayPicker canChangeMonth={true} onDayClick={(day) => { setSelectedEndDate(day.toString()); setShowSelectedEndDate({ showPopover: false, event: undefined }); }} />
      </IonPopover>
    </BaseTemplate>
  );
}
